import clsx from 'clsx';

import { BaseButton, BaseButtonProps } from './BaseButton';

export function OutlinedButton(props: BaseButtonProps) {
  const { className, children, ...rest } = props;

  const baseClassList = [
    'bg-white',
    'border-[1px]',
    'border-navy',
    'border-solid',
    'rounded-full',
    'font-size-[15px]',
    'font-weight-[600]',
    'px-[16px]',
    'text-navy',
    'disabled:text-[#a6a6a6]',
    'disabled:border-[#a6a6a6]'
  ];

  return (
    <BaseButton className={clsx(baseClassList, className)} {...rest}>
      {children}
    </BaseButton>
  );
}
