import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Dropdown } from './Dropdown';
import { DropdownArrow } from './DropdownArrow';

interface MenuItemsProps {
  items: {
    href?: string;
    label: string;
    submenu?: {
      href: string;
      label: string;
    }[];
  };
}

export function MenuItems({ items }: MenuItemsProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { submenu, label, href = '' } = items;
  const parentIsActive =
    href.toLocaleLowerCase() === pathname.toLocaleLowerCase();

  const childIsActive = !!submenu?.some(
    ({ href }) => href.toLocaleLowerCase() === pathname.toLocaleLowerCase()
  );

  const isActive = parentIsActive || childIsActive;

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (href && href.toLocaleLowerCase() !== pathname.toLocaleLowerCase()) {
      window.localStorage.removeItem(href);
      event.preventDefault();
      navigate(href);
    } else if (isActive) {
      // if href matches the path of the current page, then we need to
      // force a refresh of the page.
      window.location.reload();
    }
  };

  return (
    <li
      className={clsx('relative mx-[10px] flex h-full items-center', {
        'border-transparent': !isActive,
        'border-navy border-b-2': isActive
      })}
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      {submenu ? (
        <>
          <button
            className={clsx(
              'flex w-full cursor-pointer items-center px-0 py-[0.7rem] text-left',
              { 'text-navy': isActive }
            )}
          >
            <h4>{label}</h4>
            <DropdownArrow />
          </button>
          <Dropdown
            open={dropdownOpen}
            setOpen={setDropdownOpen}
            submenus={submenu}
          />
        </>
      ) : (
        <a
          href={href}
          className={clsx('block cursor-pointer px-0 py-[0.7rem] text-left', {
            'text-navy': isActive
          })}
          onClick={handleClick}
          rel="noopener noreferrer"
        >
          <h4>{label}</h4>
        </a>
      )}
    </li>
  );
}
