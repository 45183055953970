import clsx from 'clsx';
import { Tooltip } from 'react-tooltip';

export const tooltipClasses = 'dark max-w-80';

interface GridElementProps {
  selected: boolean;
  onClick: () => void;
  display_name: string;
  thumbnail_path?: string;
}
export default function GridElement({
  selected,
  onClick,
  display_name,
  thumbnail_path
}: GridElementProps) {
  return (
    <li
      className={clsx(
        'overflow-hidden rounded-md border-2 border-solid p-px',
        selected ? 'border-navy' : 'border-transparent'
      )}
    >
      <Tooltip
        id={`style-tooltip-${display_name}`}
        className={tooltipClasses}
      />
      <div
        data-tooltip-id={`style-tooltip-${display_name}`}
        data-tooltip-content={display_name}
        data-tooltip-place="top"
        className={clsx(
          'align-center box-border flex h-full overflow-hidden rounded-md border border-solid border-gray-400',
          { 'bg-gray-200': selected }
        )}
        onClick={() => onClick()}
      >
        <img
          src={thumbnail_path}
          className="box-content h-[60px] w-[60px] rounded object-scale-down object-center"
        />
        <div className="overflow-hidden px-1">
          <span
            lang="en"
            className="line-clamp-3 max-h-full w-full text-ellipsis text-xs text-black"
            style={{
              hyphens: 'auto'
            }}
          >
            {display_name}
          </span>
        </div>
      </div>
    </li>
  );
}
