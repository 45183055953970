export interface Product {
  product_id?: string;
  system_name: string;
  tag?: string;
  upsells: {
    product_id: string;
    sku: string;
  }[];
  style_ids: string[];
  only_upsell?: boolean;
  all_styles?: boolean;
  sku?: string;
  selected?: boolean;
}

export interface ProductErrors {
  system_name: boolean;
  label_slots: [{ options: LabelSlotErrors[] }];
  style_ids: boolean;
}

export interface LabelSlotErrors {
  quantity: boolean;
  material_id: boolean;
  coordinates: boolean;
}

export interface StyleCandidates {
  [key: string]: {
    system_name: string;
    selected: boolean;
    registered_entity_id: string;
  };
}

export interface LabelSlots {
  [key: string]: LabelSlotValues;
}

export enum LABEL_SLOT_FIELDS {
  system_name = 'system_name',
  label_id = 'label_id',
  quantity = 'quantity',
  material_ids = 'material_ids',
  showBorder = 'showBorder',
  coordinates = 'coordinates'
}

export interface LabelSlotValues {
  product_label_slot_id?: string;
  system_name: string;
  label_id: string;
  quantity?: number;
  material_ids?: string[];
  coordinates: Coordinates[];
}

export interface Coordinates {
  x: number;
  y: number;
  height: number;
  width: number;
  showBorder?: boolean;
}

export enum PRODUCT_FIELDS {
  system_name = 'system_name',
  tag = 'tag',
  product_ids_to_upsell = 'product_ids_to_upsell',
  style_ids = 'style_ids',
  only_upsell = 'only_upsell'
}

export enum COORDINATES {
  x = 'x',
  y = 'y',
  height = 'height',
  width = 'width'
}

export interface UpsellOptions {
  [key: string]: Product;
}

export interface ProductRegistrationCandidates {
  [key: string]: {
    system_name: string;
    selected: boolean;
    all_styles: boolean;
    registered_entity_id: string;
  };
}

export interface ProductTemplateSelections {
  product_template_selections_id: string;
  product_id: string;
  material_id: string;
  left_icon_id: string;
  left_print_key: string;
  left_text_id: string;
  middle_icon_id: string;
  middle_print_key: string;
  additional_text_id: string;
  footer_type_id: string;
  for_clothing: boolean;
}
