import { useCallback, useState } from 'react';

import { ContainedButton } from '../../../components/buttons';
import { ContainedButtonProps } from '../../../components/buttons/ContainedButton';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';

interface PdfGenerationButtonProps extends ContainedButtonProps {
  cartItemId?: string;
}

export function PdfGenerationButton({
  cartItemId,
  disabled,
  ...props
}: PdfGenerationButtonProps) {
  const { handleTRPCRequest } = useTRPCRequest();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generatePdfs = useCallback(async () => {
    if (!cartItemId) return;
    setIsLoading(true);

    async function run() {
      const { res } = await handleTRPCRequest({
        method: TRPCMethodEnum.generatePdfsByCartItemId,
        resourceType: TRPCResourceEnum.pdfs,
        requestBody: { cart_item_id: cartItemId }
      });

      res.forEach((url: string) => downloadPdf(url));
      setIsLoading(false);
    }

    run();
  }, [handleTRPCRequest, cartItemId]);

  return (
    <ContainedButton
      disabled={disabled || isLoading}
      onClick={generatePdfs}
      {...props}
    >
      {isLoading ? 'Generating PDFs...' : 'Generate PDFs'}
    </ContainedButton>
  );
}

async function downloadPdf(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = getFileName(url);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function getFileName(url: string) {
  const regex = /orders\/[^/]+\/(.*?)\.pdf/;
  const match = url.match(regex);
  return decodeURIComponent(match![1]) + '.pdf';
}
