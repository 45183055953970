import { SharedPreviewComponent } from './SharedPreview';
import { UpsellProduct } from '../../upsells/types';

interface UpsellPreviewProps {
  upsell: UpsellProduct;
}

export function UpsellPreview({ upsell }: UpsellPreviewProps) {
  return (
    <div className="flex w-[150px] items-center justify-center">
      <SharedPreviewComponent product={upsell} isUpsell={true} />
    </div>
  );
}
