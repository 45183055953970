import { OutlinedButton } from '../../../components/buttons';

export function ContinueShoppingButton() {
  return (
    <OutlinedButton
      size="large"
      onClick={() => {
        window.location.href = '/collections/all';
      }}
    >
      Continue Shopping
    </OutlinedButton>
  );
}
