import { StepAccordion } from './StepAccordion';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import StyleAndColor from '../stylesAndColors/StyleAndColor';
import TextAndFont from '../fonts/TextAndFont';
import { selectAccordion, setActiveStep } from './accordionSlice';
import ClothingType from '../clothingType/ClothingType';
import LabelShape from '../LabelShapes/LabelShape';
import { useCallback, useEffect, useMemo } from 'react';
import { useFontsAndFields } from '../fonts/TextAndFont.hooks';
import { useIconsAndLabels } from '../iconAndLabel/IconAndLabelHooks';
import { CartErrors } from '../../utils/getCartErrors';
import { useMaterials } from '../clothingType/Materials.hooks';
import IconAndLabel from '../iconAndLabel/IconAndLabel';
import { useStyleAndColor } from '../stylesAndColors/StyleAndColorHooks';
import { useLabelShape } from '../LabelShapes/LabelShapeHooks';
import { useGetProductQuery } from '../api';
import clsx from 'clsx';

export type StepsProps = {
  admin?: boolean;
  productTag: string;
  errors?: CartErrors;
  seeAll: boolean;
};

export function Steps({
  admin = false,
  productTag,
  errors,
  seeAll
}: StepsProps) {
  const { isSuccess } = useGetProductQuery(productTag);

  const showSeeAll = useRootSelector(selectAccordion);
  const dispatch = useConfiguratorDispatch();

  const { hideFontsAndFields, fonts, fields } = useFontsAndFields();

  const selected = showSeeAll.activeStep;
  const setSelected = useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (activeStep: string) => {
      dispatch(setActiveStep(activeStep));
    },
    [dispatch]
  );

  const { showStyleAndColors, palettes, styles } = useStyleAndColor();
  const { showLabels, showIcons, hideIconsAndLabels } = useIconsAndLabels();
  const { showLabelShapes } = useLabelShape();
  const { materials } = useMaterials();
  const accordionSteps = useMemo(() => {
    const stepLabels = [];

    if (showStyleAndColors) {
      stepLabels.push('styleAndColor');
    }
    if (!hideFontsAndFields) {
      stepLabels.push('textAndFont');
    }

    if (showLabelShapes) {
      stepLabels.push('labelShape');
    }
    if (!hideIconsAndLabels) {
      stepLabels.push('iconAndLabel');
    }
    if (materials?.length > 1) {
      stepLabels.push('clothingType');
    }

    return stepLabels.reduce(
      (acc, el, i) => {
        acc[el] = i + 1;
        return acc;
      },
      {} as { [key: string]: number }
    );
  }, [
    showStyleAndColors,
    hideFontsAndFields,
    materials.length,
    hideIconsAndLabels,
    showLabelShapes
  ]);

  const styleAndColorHeader = useMemo(() => {
    const header = [];
    if (styles.length > 1) header.push('Style');
    if (palettes.length > 1) header.push('Color');
    return header.join(' and ');
  }, [palettes, styles]);

  const iconAndLabelHeader = useMemo(() => {
    const header = [];
    if (showIcons) header.push('Icon');
    if (showLabels) header.push('Label Shape');
    return header.join(' and ');
  }, [showLabels, showIcons]);

  const textAndFontHeader = useMemo(() => {
    const header = [];
    if (fields.length) header.push('Text');
    if (fonts.length > 1) header.push('Font');
    return header.join(' and ');
  }, [fields, fonts]);

  useEffect(() => {
    const entry = Object.entries(accordionSteps).find(([_, val]) => val === 1);
    if (isSuccess && !selected && entry) {
      setSelected(entry[0]);
    }
  }, [accordionSteps, isSuccess, selected, setSelected]);

  return (
    <div
      className={clsx('flex w-full flex-col', {
        'invisible fixed opacity-0': seeAll
      })}
    >
      {accordionSteps.styleAndColor && (
        <StepAccordion
          header={`${accordionSteps.styleAndColor}. ${styleAndColorHeader}`}
          selected={selected == 'styleAndColor'}
          onChange={() => setSelected('styleAndColor')}
        >
          <StyleAndColor />
        </StepAccordion>
      )}
      {accordionSteps.styleAndColor && accordionSteps.textAndFont && (
        <hr className="border-y-1 m-0 w-full border-x-0 border-solid border-gray-300" />
      )}
      {accordionSteps.textAndFont && (
        <>
          <StepAccordion
            header={`${accordionSteps.textAndFont}. ${textAndFontHeader}`}
            selected={selected == 'textAndFont'}
            onChange={() => setSelected('textAndFont')}
            error={!!(errors?.fields || errors?.font)}
          >
            <TextAndFont admin={admin} errors={errors} />
          </StepAccordion>
        </>
      )}
      {accordionSteps.labelShape && (
        <>
          <hr className="border-y-1 m-0 w-full border-x-0 border-solid border-gray-300" />
          <StepAccordion
            header={`${accordionSteps.labelShape}. Label Shape`}
            selected={selected == 'labelShape'}
            onChange={() => setSelected('labelShape')}
          >
            <LabelShape />
          </StepAccordion>
        </>
      )}
      {accordionSteps.iconAndLabel && (
        <>
          <hr className="border-y-1 m-0 w-full border-x-0 border-solid border-gray-300" />
          <StepAccordion
            header={`${accordionSteps.iconAndLabel}. ${iconAndLabelHeader}`}
            selected={selected == 'iconAndLabel'}
            onChange={() => setSelected('iconAndLabel')}
            error={!!errors?.clothingType}
          >
            <IconAndLabel />
          </StepAccordion>
        </>
      )}
      {accordionSteps.clothingType && (
        <>
          <hr className="border-y-1 m-0 w-full border-x-0 border-solid border-gray-300" />
          <StepAccordion
            header={`${accordionSteps.clothingType}. Clothing Type`}
            selected={selected == 'clothingType'}
            onChange={() => setSelected('clothingType')}
            error={!!errors?.clothingType}
          >
            <ClothingType error={errors?.clothingType} />
          </StepAccordion>
        </>
      )}
    </div>
  );
}
