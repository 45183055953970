import { useCallback, useState } from 'react';

import { TextField } from '../../../../components/inputs';
import { ModalComponent } from '../../../../components/modals';
import { ContainedButton } from '../../../../components/buttons';

interface UpsellSkuModalProps {
  isOpen: boolean;
  sku: string;
  setSku: (sku: string) => void;
  onCancel: () => void;
  onSave: () => void;
}

export function UpsellSkuModalComponent({
  isOpen,
  sku,
  setSku,
  onCancel,
  onSave
}: UpsellSkuModalProps) {
  const [error, setError] = useState<boolean>(false);

  const handleSave = useCallback(() => {
    sku && sku.length ? onSave() : setError(true);
  }, [sku, onSave]);

  const SkuInput = (
    <TextField
      error={error}
      autoFocus={true}
      className="mt-5"
      placeholder="SKU"
      value={sku}
      onChange={event => {
        setError(false);
        const value = event.target.value.toLocaleUpperCase();
        setSku(value);
      }}
    />
  );

  const CancelButton = (
    <ContainedButton className="flex-1" color="secondary" onClick={onCancel}>
      Cancel
    </ContainedButton>
  );

  const SaveButton = (
    <ContainedButton className="flex-1" onClick={handleSave}>
      Save
    </ContainedButton>
  );

  const Controls = (
    <div className="flex gap-3 pt-5">
      {CancelButton}
      {SaveButton}
    </div>
  );

  return (
    <ModalComponent
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentLabel="Upsell Sku Modal"
    >
      <h1>Upsell Product SKU</h1>
      {SkuInput}
      {Controls}
    </ModalComponent>
  );
}
