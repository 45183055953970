import { useSession } from '@clerk/clerk-react';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';

import { UserRolesEnum } from '../../../core/src/features/fonts/types';

import { LabelDetails, Labels } from '../features/labels';
import { StylesList } from '../features/styles';
import { FontDetails, FontsList } from '../features/fonts';
import { MaterialDetails, MaterialList } from '../features/materials';
import { PalettesList, PaletteDetails } from '../features/palettes';
import { IconsList } from '../features/icons/routes/IconsList';
import { IconDetails } from '../features/icons/routes/IconDetails';
import { TextFieldsList, TextFieldDetails } from '../features/text-fields';
import {
  ColorMappingsList,
  ColorMappingDetails
} from '../features/color-mappings/routes';
import { GroupDetails, Groups } from '../features/groups';
import { Users } from '../features/users';
import { StyleDetails } from '../features/styles/routes';
import { ProductDetails, ProductsList } from '../features/products';
import { Reports } from '../features/reports/features/routes/Reports';
import { Order } from '../features/orders/routes/OrderDetail';
import { OrderList } from '../features/orders/routes/OrderList';
import OrderDetailEdit from '../features/orders/routes/OrderDetailEdit';
import { GeneratePdfsByCartItem } from '../features/pdfs/routes/GeneratePdfsByCartItem';
import { GeneratePdfsByOrder } from '../features/pdfs/routes/GeneratePdfsByOrder';
import Root from './layouts/Root';
import ComponentExamples from './routes/ComponentExamples';
import ErrorPage from './routes/Error';
import { Redirect } from './routes/Redirect';

const createPermissionedRouter = (role: string) => {
  const mainNavigation = [
    { label: 'Products', href: '/products' },
    { label: 'Labels', href: '/labels' },
    { label: 'Styles', href: '/styles' },
    {
      label: 'Assets',
      submenu: [
        { label: 'Fonts', href: '/fonts' },
        { label: 'Groups', href: '/groups' },
        { label: 'Icons', href: '/icons' },
        { label: 'Materials', href: '/materials' },
        { label: 'Palettes', href: '/palettes' },
        { label: 'Text Fields', href: '/text-fields' },
        { label: 'Color Mappings', href: '/color-mappings' }
      ]
    },
    { label: 'Reports', right: true, href: '/reports' }
  ];

  let routes: RouteObject[] = [
    { path: '/', element: <Redirect role={role} /> },
    { path: 'component-examples', element: <ComponentExamples /> }
  ];

  if (
    [
      UserRolesEnum.super_admin,
      UserRolesEnum.admin,
      UserRolesEnum.asset_manager
    ].includes(role as UserRolesEnum)
  ) {
    routes = [
      ...routes,
      { path: 'products', element: <ProductsList /> },
      { path: 'products/:product_id', element: <ProductDetails /> },
      { path: 'products/new', element: <ProductDetails create /> },

      { path: 'labels', element: <Labels /> },
      { path: 'labels/new', element: <LabelDetails /> },
      { path: 'labels/:label_id', element: <LabelDetails /> },
      { path: 'styles', element: <StylesList /> },
      { path: 'styles/new', element: <StyleDetails create /> },
      { path: 'styles/:style_id', element: <StyleDetails /> },

      { path: 'palettes', element: <PalettesList /> },
      { path: 'palettes/new', element: <PaletteDetails create /> },
      { path: 'palettes/:palette_id', element: <PaletteDetails /> },

      { path: 'icons', element: <IconsList /> },
      { path: 'icons/new', element: <IconDetails create /> },
      { path: 'icons/:icon_id', element: <IconDetails /> },

      { path: 'fonts', element: <FontsList /> },
      { path: 'fonts/new', element: <FontDetails create /> },
      { path: 'fonts/:font_id', element: <FontDetails /> },

      { path: 'groups', element: <Groups /> },
      { path: 'groups/new', element: <GroupDetails create /> },
      { path: 'groups/:group_id', element: <GroupDetails /> },

      { path: 'materials', element: <MaterialList /> },
      { path: 'materials/new', element: <MaterialDetails create /> },
      { path: 'materials/:material_id', element: <MaterialDetails /> },

      { path: 'text-fields', element: <TextFieldsList /> },
      { path: 'text-fields/new', element: <TextFieldDetails create /> },
      { path: 'text-fields/:text_field_id', element: <TextFieldDetails /> },

      { path: 'color-mappings', element: <ColorMappingsList /> },
      { path: 'color-mappings/new', element: <ColorMappingDetails create /> },
      {
        path: 'color-mappings/:color_mapping_id',
        element: <ColorMappingDetails />
      },
      {
        path: 'generate-pdfs/cart-item/:cart_item_id',
        element: <GeneratePdfsByCartItem />
      },
      {
        path: 'generate-pdfs/order/:order_id',
        element: <GeneratePdfsByOrder />
      }
    ];
  }

  if (
    [
      UserRolesEnum.super_admin,
      UserRolesEnum.admin,
      UserRolesEnum.customer_service,
      UserRolesEnum.order_processor,
      UserRolesEnum.asset_manager
    ].includes(role as UserRolesEnum)
  ) {
    routes = [...routes, { path: 'reports', element: <Reports /> }];
  }

  if (
    [
      UserRolesEnum.super_admin,
      UserRolesEnum.admin,
      UserRolesEnum.customer_service,
      UserRolesEnum.order_processor
    ].includes(role as UserRolesEnum)
  ) {
    routes = [
      ...routes,
      {
        path: 'orders',
        children: [
          {
            path: '',
            element: <OrderList />
          },
          {
            path: ':shopifyOrderNumber',
            children: [
              {
                path: '',
                element: <Order />
              },
              {
                path: 'edit/:cart_item_id',
                element: <OrderDetailEdit />
              }
            ]
          }
        ]
      }
    ];
    mainNavigation.push({ label: 'Orders', right: true, href: '/orders' });
  }

  routes = [
    ...routes,
    { path: 'users', element: <Users /> },
    { path: '404', element: <ErrorPage /> }
  ];

  return createBrowserRouter([
    {
      path: '/',
      element: <Root mainMenuItems={mainNavigation} />,
      errorElement: <ErrorPage />,
      children: routes
    }
  ]);
};

export const PermissionedRouter = () => {
  const session = useSession();

  const metaData = session.session?.user?.publicMetadata;

  return (
    <RouterProvider
      router={createPermissionedRouter((metaData?.role as string) || '')}
    />
  );
};
