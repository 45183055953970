import { useState, useEffect, useMemo, useCallback, useRef } from 'react';

import { ItemProperties, VariantTextField, Variant } from '../../../types';
import { useFonts } from './useFontsHook';
import { useRootSelector } from '../../../store';
import { useGetProductQuery } from '../../api';
import { selectProductTag, selectStyle } from '../../item/store/itemSlice';
import {
  PreparedText,
  layoutText,
  Font
} from '../../../../../text-layout/src/layout';
import { LoadFont } from '../../fonts/utils';

interface PreviewSvgProps {
  variant: Variant;
  itemProperties: ItemProperties;
  width: number;
  height: number;
  isUpsell?: boolean;
}

export function VariantPreviewComponent({
  variant,
  itemProperties,
  width,
  height,
  isUpsell = false
}: PreviewSvgProps) {
  const [compositeSvgContent, setCompositeSvgContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const svgCache = useRef<Record<string, string>>({});

  const viewBoxWidth = variant.width * 72;
  const viewBoxHeight = variant.height * 72;
  const { fetchFont } = useFonts();
  const productTag = useRootSelector(selectProductTag);

  const { data } = useGetProductQuery(productTag);
  const selectedStyle = useRootSelector(selectStyle);

  const fetchAndCacheSvg = useCallback(async (url: string) => {
    if (svgCache.current[url]) {
      return svgCache.current[url]; // Use cached SVG text if available
    }
    setIsLoading(true);
    const response = await fetch(url);
    const svgText = await response.text();
    svgCache.current[url] = svgText; // Update cache directly
    setIsLoading(false);
    return svgText;
  }, []);

  const fonts = useMemo(() => {
    if (!selectedStyle || !data?.fonts) {
      return [];
    }
    const fontsInStyle = data.fonts.filter(f =>
      selectedStyle.font_ids.includes(f.font_id)
    );
    fontsInStyle.sort((a, b) => a.display_name.localeCompare(b.display_name));
    return fontsInStyle;
  }, [selectedStyle, data?.fonts]);

  useEffect(() => {
    const doWork = async () => {
      const compositeSvg = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
      );

      if (itemProperties?.style) {
        const { layers } = variant;
        const children: HTMLElement[] = [];
        for (const layer of layers) {
          const print_key = layer.print_key;
          // If the print_key is static, i.e., `''`, don't set a color.
          // Use the svg default.
          let hex: string | undefined;
          if (print_key !== '') {
            hex = itemProperties.palette?.color_slots[print_key] ?? 'black';
          }
          const svgElement = createSvgElement(
            layer.svg_text || (await fetchAndCacheSvg(layer.svg_url))
          );
          for (const child of svgElement.querySelectorAll(
            'path,circle,rect,polygon,ellipse'
          )) {
            const id = child.getAttribute('id') || '';
            const style = child.getAttribute('style') || '';
            if (id?.startsWith('dyeCut_') || /stroke:\s?#ec008c/.test(style)) {
              child.setAttribute('visibility', 'hidden');
            }
            if (hex) {
              child.setAttribute('style', `fill: ${hex}`);
            }
          }
          svgElement.setAttribute('width', '100%');
          svgElement.setAttribute('height', '100%');
          children.push(svgElement);
        }
        for (const child of children) {
          compositeSvg.appendChild(child);
        }

        const elementsGroup = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'g'
        );

        elementsGroup.setAttribute(
          'transform',
          `translate(${viewBoxWidth / 2}, ${viewBoxHeight / 2})`
        );

        const { text_fields } = variant;
        let textGroup: SVGElement;

        for (const textField of text_fields) {
          if (textField.client_choice) {
            if (itemProperties.font == null) {
              console.error('Error - no font selected');
            } else {
              const fontLoad: LoadFont = {
                family: itemProperties.font.google_family,
                url: itemProperties.font.font_file_path,
                style: itemProperties.font.style,
                weight: itemProperties.font.weight,
                font_id: itemProperties.font.font_id
              };
              const fonty = await fetchFont(fontLoad);
              textGroup = createTextGroup(
                textField,
                itemProperties,
                fonty as Font,
                isUpsell
              );
              elementsGroup.appendChild(textGroup);
            }
          } else {
            const fontLoad: LoadFont = {
              family: textField.default_font.google_family,
              url: textField.default_font.font_file_path,
              style: textField.default_font.style,
              weight: textField.default_font.weight,
              font_id: textField.default_font.font_id
            };
            const fonty = await fetchFont(fontLoad);
            textGroup = createTextGroup(
              textField,
              itemProperties,
              fonty as Font,
              isUpsell
            );
            elementsGroup.appendChild(textGroup);
          }
        }

        const { icons } = variant;
        for (const icon of icons) {
          let { x, y, radius } = icon;
          x *= 72;
          y *= 72;
          radius *= 72;
          const { rotation } = icon;

          const selectedIcon = itemProperties.icon;

          const iconGroup = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'g'
          );

          const iconSvg = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'svg'
          );

          iconGroup.appendChild(iconSvg);

          iconSvg.setAttribute('width', `${radius * 2}`);
          iconSvg.setAttribute('height', `${radius * 2}`);
          iconSvg.setAttribute('x', `${-radius}`);
          iconSvg.setAttribute('y', `${-radius}`);

          for (const layer of selectedIcon!.layers) {
            const print_key = layer.print_key;
            let hex: string | undefined;
            if (print_key !== '') {
              hex = itemProperties.palette?.color_slots[print_key] ?? 'black';
            }

            const svgElement = createSvgElement(
              layer.svg_text || (await fetchAndCacheSvg(layer.svg_url))
            );
            svgElement.setAttribute('width', '100%');
            svgElement.setAttribute('height', '100%');
            for (const child of svgElement.querySelectorAll(
              'path,circle,rect,polygon,ellipse'
            )) {
              if (hex) {
                child.setAttribute('style', `fill: ${hex}`);
              }
            }
            iconSvg.appendChild(svgElement);
          }

          iconGroup.setAttribute(
            'transform',
            `translate(${x}, ${-y}) rotate(${rotation})`
          );

          elementsGroup.appendChild(iconGroup);
        }

        compositeSvg.appendChild(elementsGroup);

        setCompositeSvgContent(compositeSvg.innerHTML);
      }
    };
    try {
      doWork();
    } catch (e) {
      console.error(e);
    }
  }, [
    variant,
    itemProperties,
    viewBoxHeight,
    viewBoxWidth,
    fetchFont,
    fonts,
    isUpsell,
    fetchAndCacheSvg
  ]);

  if (isLoading) {
    const borderRadius = 0.1 * 72; // 0.1 inches rounded to pixels
    const fontSize = Math.round(viewBoxWidth * 0.15);

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        width={width}
        height={height}
      >
        <rect
          fill="#eee"
          width="100%"
          height="100%"
          rx={borderRadius}
          ry={borderRadius}
        />
        <text
          x="50%"
          y="50%"
          alignment-baseline="central"
          text-anchor="middle"
          fill="gray"
          style={{ fontSize: `${fontSize}px` }}
        >
          Loading...
        </text>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={width}
      height={height}
      dangerouslySetInnerHTML={{ __html: compositeSvgContent }}
    ></svg>
  );
}

function createTextGroup(
  textField: VariantTextField,
  itemProperties: ItemProperties,
  fontObj: Font,
  isUpsell: boolean = false
) {
  let { width, height } = textField;

  width *= 72;
  height *= 72;

  const {
    x,
    y,
    align_x,
    align_y,
    print_key,
    text_field_id,
    display_name,
    display_name_2,
    client_choice,
    default_font,
    rotation,
    curve,
    wrap
  } = textField;

  const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  group.setAttribute('transform', `translate(${x} ${-y}) rotate(${rotation})`);

  function createSubGroup(line: PreparedText) {
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

    const font = client_choice ? itemProperties.font : default_font;

    const hex = itemProperties.palette?.color_slots[print_key];
    text.setAttribute('fill', hex || 'black');

    text.setAttribute('font-family', font!.google_family + font!.font_id);
    text.setAttribute('font-weight', font!.weight);
    text.setAttribute('font-style', font!.style || 'normal');
    text.setAttribute('style', `font-size: ${line.size}px`);

    // Replace spaces with non-breaking spaces
    text.textContent = line.value.replace(/ /g, '\u00A0');

    group.setAttribute(
      'transform',
      `translate(${line.x} ${-line.y}) rotate(${line.rotate})`
    );

    group.appendChild(text);
    return group;
  }

  let lineText = display_name_2
    ? `${display_name}\n${display_name_2}`
    : display_name;

  const hasEnteredTextForAnyField = checkForAnyEnteredText(itemProperties);
  if (isUpsell || hasEnteredTextForAnyField) lineText = '';

  const suppliedContent = itemProperties.fields?.[text_field_id]?.value;
  const hasEnteredTextForThisField = suppliedContent && suppliedContent.length;
  if (hasEnteredTextForThisField) lineText = suppliedContent;

  if (fontObj) {
    const lines = layoutText({
      value: lineText,
      font: fontObj,
      width,
      height,
      align_x,
      align_y,
      wrap,
      curve
    });
    lines.forEach(line => {
      const elem = createSubGroup(line);
      if (!elem) return;
      group.appendChild(elem);
    });
  }

  return group;
}

function checkForAnyEnteredText(itemProperties: ItemProperties) {
  for (const textFieldId in itemProperties.fields) {
    const targetFieldValue = itemProperties.fields[textFieldId].value;
    if (targetFieldValue && targetFieldValue.length) return true;
  }
  return false;
}

let idIndex = 1;

function createSvgElement(svgString: string) {
  const id = `SVGID-${idIndex++}_`;
  const parser = new DOMParser();
  svgString = svgString.replace(/linear-gradient/g, `linear-gradient-${id}`);
  svgString = svgString.replace(/radial-gradient/g, `radial-gradient-${id}`);
  const doc = parser.parseFromString(svgString, 'image/svg+xml');
  return doc.documentElement;
}
