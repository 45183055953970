import { getHexFromTailwindConfig } from '../../utils';

interface Props {
  color: string;
}

export function ColorSample(props: Props) {
  const { color } = props;

  const hex = getHexFromTailwindConfig(color);

  return (
    <div className="text-center">
      <div
        className={`m-2 h-24 w-24 rounded-full drop-shadow-lg`}
        style={{ background: hex }}
      />
      <p className="body-2-bold">{color}</p>
      <p className="body-3">{hex}</p>
    </div>
  );
}
