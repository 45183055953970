import { useCallback } from 'react';
import { GoogleFont } from '../../../../../core/src/features/fonts/types';
import { loadFont } from './fonts';

interface FontVariantsProps {
  font: GoogleFont;
  variant: string | undefined;
  id?: string;
  onSelectVariant: (variant: string) => void; // eslint-disable-line no-unused-vars
}

export function FontVariants(props: FontVariantsProps) {
  const handleVariantSelect = useCallback(
    (variant: string) => {
      props.onSelectVariant(variant);
    },
    [props]
  );

  return (
    <>
      {!props.variant && (
        <div className="mb-[20px] flex gap-[20px]">
          <div className="flex flex-1 gap-[20px]">Please choose a variant</div>
        </div>
      )}
      <div className="h-[36px]">
        <h4 className="ml-[83px] w-[32%]">Variant</h4>
        <h4 className="w-[267px]">Sample</h4>
      </div>

      {props.font.variants.map(variant => (
        <FontVariant
          key={variant}
          variant={variant}
          family={props.font.family}
          url={props.font.files[variant]!}
          id={props.id}
          onSelectVariant={handleVariantSelect}
          isSelected={props.variant === variant}
        />
      ))}
    </>
  );
}

interface FontVariantProps {
  variant: string;
  family: string;
  url: string;
  isSelected: boolean;
  id?: string;
  onSelectVariant: (variant: string) => void; // eslint-disable-line no-unused-vars
}

function FontVariant(props: FontVariantProps) {
  const selectedStyle = props.isSelected
    ? 'border-[black]'
    : 'border-[transparent]';
  const parts = props.variant.match(/(\d+)?(\w+)?/) || ['...', ''];
  const fontStyle = parts[2] === 'italic' ? 'italic' : '';
  const weight = parts[1] || '400';
  const family = props.family + (props.id ?? '');
  loadFont({
    url: props.url,
    family: family,
    style: fontStyle,
    weight
  });

  return (
    <div
      key={props.variant}
      className={`card mb-[6px] flex h-[54px] cursor-pointer items-center border-[1px] align-middle ${selectedStyle} ${fontStyle}`}
      onClick={() => props.onSelectVariant(props.variant)}
      style={{
        fontFamily: family,
        fontWeight: weight
      }}
    >
      <h2 className="ml-[30px] w-[32%]">
        {parts[1]} {parts[2]}
      </h2>
      <div>The quick brown fox jumped over the lazy dog</div>
    </div>
  );
}
