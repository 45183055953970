import { Font } from '../features/fonts';

export interface Product {
  product_id: string;
  vdpcode: string | null;
  display_name: string;
  styles: Style[];
  palettes: Palette[];
  filters: Group[];
  fonts: Font[];
  labels: Label[];
  icons: Icon[];
  materials: Material[];
  label_slots: LabelSlot[];
}

export interface Style {
  style_id: string;
  vdpcode: string | null;
  display_name: string;
  thumbnail_path: string;
  palette_ids: string[];
  icon_ids: string[];
  font_ids: string[];
}

export interface Palette {
  palette_id: string;
  vdpcode: string | null;
  display_name: string;
  system_name: string;
  thumbnail_path: string;
  color_slots: { [key: string]: string | null };
}

export interface Icon {
  icon_id: string;
  vdpcode: string | null;
  display_name: string;
  system_name: string;
  thumbnail_path: string;
  icon_layers: IconLayer[];
  layers: Layer[];
}

export interface IconLayer {
  icon_layer_id: string;
  icon_id: string;
  index: number;
  main_file_path: string;
  print_key: string;
  svgText: string;
}

export interface ColorSlot {
  color_slot_id: string;
  palette_id: string;
  display_name: string;
  print_key: string;
  color_hex: string | null;
  index: number;
}

export interface Group {
  group_id: string;
  display_name: string;
  type: string;
  entity_ids: string[];
  style_ids: string[];
}

export interface Label {
  label_id: string;
  vdpcode: string | null;
  display_name: string;
  system_name: string;
  variants: Variant[];
  thumbnail_path: string;
  label_slots: LabelSlot[];
  material_ids: string[];
  iterative_style_ids: string[] | null;
  iterative_defaults?: Record<string, string> | undefined;
}

export interface LabelSlot {
  product_label_slot_id: string;
  product_id: string;
  slot_options: SlotOption[];
}

export interface SlotOptionCoordinates {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface SlotOption {
  product_label_slot_option_id: string;
  product_label_slot_id: string;
  label_id: string;
  coordinates: SlotOptionCoordinates[];
  material_ids: string[];
  quantity: number;
}

export interface VariantIcon {
  x: number;
  y: number;
  radius: number;
  rotation: number;
}

export interface Variant {
  label_variant_id: string;
  vdpcode: string | null;
  thumbnail_path?: string;
  height: number;
  width: number;
  system_name: string;
  layers: Layer[];
  style_id: string;
  text_fields: VariantTextField[];
  icons: VariantIcon[];
  slot_options: SlotOptions[];
}

export interface SlotOptions {
  label_id: string;
}

export interface Layer {
  layer_id: string;
  display_name: string;
  print_key: string;
  svg_text: string;
  svg_url: string;
}

export interface VariantTextField {
  text_field_id: string;
  label_text_field_id: string;
  align_x: number;
  align_y: number;
  curve: number;
  print_key: string;
  x: number;
  y: number;
  system_name: string;
  display_name: string;
  display_name_2?: string | null;
  max_characters: number;
  width: number;
  height: number;
  default_font_id?: string;
  default_font: Font;
  is_required: boolean;
  client_choice: boolean;
  rotation: number;
  wrap: boolean;
}

export interface Material {
  material_id: string;
  vdpcode: string | null;
  description: string;
  display_name: string;
  thumbnail_path: string;
  upsell_title: string;
}

export interface ItemSlot {
  labelId: string | undefined;
  variantId: string | undefined;
  materialId: string | undefined;
}

export interface ItemProperties {
  productId: string | null;
  productTag: string;
  productVdpCode: string | null;
  style: Style | null;
  palette: Palette | null;
  font: Font | null;
  fields: {
    [key: string]: {
      // key and field_id are the same.
      field_id: string;
      value: string;
      display_name: string | undefined;
      display_name_2?: string | null;
    };
  };
  icon: Icon | null;
  labelShape: Label | null;
  clothingType: Material | null;
  slots: Record<string, ItemSlot>;
}

export interface ShopifyProductVariant {
  id: number;
  name: string;
  shape?: string;
  color?: string;
}

export enum AppStates {
  CONFIGURING = 'configuring',
  SHOW_UPSELLS = 'showUpsells',
  ADDED_TO_CART = 'addedToCart'
}

export interface CartItem {
  [key: string]: string | undefined;
}

export interface SwatchSelections {
  color?: string;
  shape?: string;
}
