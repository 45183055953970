import { useCallback, useState } from 'react';

interface OrderItemInfoProps {
  title: string;
  properties: {
    name: string;
    value: string;
  }[];
}

export const OrderItemInfo = ({ title, properties }: OrderItemInfoProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const toggleShowAll = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setShowAll(!showAll);
  };

  const allProperties = useCallback(() => {
    return properties.map(property => {
      return (
        <div key={property.name}>{`${property.name}: ${property.value}`}</div>
      );
    });
  }, [properties]);

  return (
    <div>
      {
        <div>
          <div key={'title'}>{title}</div>
          {showAll ? allProperties() : []}
          <div className="text-navy hover:underline" onClick={toggleShowAll}>
            {showAll ? '- less info' : '+ more info'}
          </div>
        </div>
      }
    </div>
  );
};
