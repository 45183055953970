export const enum ProductClassifications {
  PACK,
  NON_PACK,
  UPSELL
}

export interface SelectionVdpCodes {
  product: string | null;
  label: string | null;
  style: string | null;
  palette: string | null;
  icon: string | null;
  font: string | null;
  labelVariant: string | null;
  material: string | null;
  shoe: string | null;
}
