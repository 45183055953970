interface UpsellDetailsProps {
  formattedPrice?: string;
  productName?: string;
  quantity?: number;
}

export function UpsellDetails({
  formattedPrice,
  productName,
  quantity
}: UpsellDetailsProps) {
  const isWaterproofSticker = productName
    ?.toLowerCase()
    .includes('waterproof sticker');

  const formattedQuantity = isWaterproofSticker
    ? '1 Sheet'
    : (quantity && `${quantity} ${quantity > 1 ? 'Labels' : 'Label'}`) || '';

  return (
    <div>
      <p className="font-semibold">{productName}</p>
      <p>{formattedQuantity}</p>
      <p>{formattedPrice}</p>
    </div>
  );
}
