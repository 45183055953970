import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { NumberInput } from '../../../components/inputs/NumberInput';
import { COORDINATES, LabelSlotValues, LabelSlots } from '../types';
import { SizeEnum } from '../../../types';
import { GraphPaperComponent } from './GraphPaperComponent';
import { EditableArea } from './EditibleArea';
import { XYCoord, useDrop } from 'react-dnd';

interface GraphComponentProps {
  selectedIndex: number;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  isOption: boolean;
  labelSlots: LabelSlots;
  onUpdateLabelList(updatedList: LabelSlotValues[]): void;
}

export function GraphComponent({
  selectedIndex,
  setSelectedIndex,
  isOption,
  labelSlots,
  onUpdateLabelList
}: GraphComponentProps) {
  const [currentValues, setCurrentValues] = useState<{
    id: string;
    coordsIndex: number;
    x: number | string;
    y: number | string;
    height: number | string;
    width: number | string;
  }>({
    id: '',
    coordsIndex: 0,
    x: 0,
    y: 0,
    height: 0,
    width: 0
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerXMiddle, setContainerXMiddle] = useState(0);
  const [containerYMiddle, setContainerYMiddle] = useState(0);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [labelSlotsList, setLabelSlotsList] = useState<LabelSlotValues[]>(
    Object.values(labelSlots)
  );
  const [xTranslation, setXTranslation] = useState(0);
  const [yTranslation, setYTranslation] = useState(0);
  const [minX, setMinX] = useState(0);
  const [minY, setMinY] = useState(0);

  const [, drop] = useDrop(
    () => ({
      accept: 'item',
      drop(
        item: { x: number; y: number; id: string; coordsIndex: number },
        monitor
      ) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.x + delta.x);
        const top = Math.round(item.y + delta.y);
        const { coordsIndex, id } = item;

        setLabelSlotsList(prevLabelSlotsList => {
          const updatedLabelSlotsList = [...prevLabelSlotsList];
          const matchingLabelIndex = updatedLabelSlotsList.findIndex(
            label => label.label_id === id
          );

          const existingCoords =
            updatedLabelSlotsList[matchingLabelIndex].coordinates;

          const newCoords = {
            x: left >= 0 ? left : 0,
            y: top >= 0 ? top : 0,
            height: existingCoords[coordsIndex].height,
            width: existingCoords[coordsIndex].width,
            showBorder: true
          };

          const newCoordinates = [...existingCoords];
          newCoordinates[coordsIndex] = newCoords;
          updatedLabelSlotsList[matchingLabelIndex] = {
            ...prevLabelSlotsList[matchingLabelIndex],
            coordinates: newCoordinates
          };
          onUpdateLabelList(updatedLabelSlotsList);
          return updatedLabelSlotsList;
        });
      }
    }),
    []
  );

  useEffect(() => {
    setLabelSlotsList(Object.values(labelSlots));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelSlots]);

  const updateSize = useCallback(() => {
    if (!containerRef.current) return;
    setContainerXMiddle(containerRef.current?.clientWidth / 2);
    setContainerYMiddle(containerRef.current?.clientHeight / 2);
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new ResizeObserver(updateSize);
    observer.observe(containerRef.current);
    return () => observer.disconnect();
  }, [updateSize]);

  useEffect(() => {
    let bboxMinX = Infinity;
    let bboxMaxX = -Infinity;
    let bboxMinY = Infinity;
    let bboxMaxY = -Infinity;

    //calculate the bounding box of all the labels
    labelSlotsList.forEach(label => {
      label.coordinates.forEach(({ x, y, width, height }) => {
        bboxMinX = Math.min(bboxMinX, x);
        bboxMaxX = Math.max(bboxMaxX, x + width);
        bboxMinY = Math.min(bboxMinY, y);
        bboxMaxY = Math.max(bboxMaxY, y + height);
      });
    });

    //get the width and height of the view box (graph paper)
    const containerWidth = containerXMiddle * 2;
    const containerHeight = containerYMiddle * 2;

    //get scale factor
    const scaleFactor = Math.min(
      containerWidth / (bboxMaxX - bboxMinX),
      containerHeight / (bboxMaxY - bboxMinY)
    );

    setScaleFactor(scaleFactor);

    setMinX(bboxMinX);
    setMinY(bboxMinY);

    //scale the bouding box and get the coordinates of the scaled box
    const scaledBBoxMaxX = (bboxMaxX - bboxMinX) * scaleFactor + bboxMinX;
    const scaledBBoxMaxY = (bboxMaxY - bboxMinY) * scaleFactor + bboxMinY;
    const scaledBBoxMinX = bboxMinX;
    const scaledBBoxMinY = bboxMinY;

    // get the vector between the center of the view box and
    // the center of the scaled bounding box
    // We need this to shift the scaled box back
    setXTranslation(
      containerWidth / 2 - ((scaledBBoxMaxX - scaledBBoxMinX) / 2 + bboxMinX)
    );
    setYTranslation(
      containerHeight / 2 - ((scaledBBoxMaxY - scaledBBoxMinY) / 2 + bboxMinY)
    );
  }, [containerXMiddle, containerYMiddle, labelSlotsList]);

  useEffect(() => {
    let foundItem: LabelSlotValues = {
      label_id: '',
      system_name: '',
      coordinates: []
    };
    let foundIndex = -1;

    labelSlotsList.some(item => {
      const index = item.coordinates.findIndex(
        coordinatesList => coordinatesList.showBorder === true
      );
      if (index !== -1) {
        foundItem = item;
        foundIndex = index;
        return true;
      }
    });

    if (foundIndex > -1) {
      setCurrentValues({
        id: foundItem.label_id,
        x: foundItem.coordinates[foundIndex].x,
        y: foundItem.coordinates[foundIndex].y,
        height: foundItem.coordinates[foundIndex].height,
        width: foundItem.coordinates[foundIndex].width,
        coordsIndex: foundIndex
      });
    }
  }, [labelSlotsList]);

  function handleUpdates(value: number, key: COORDINATES) {
    const currentLabel = labelSlotsList.find(
      obj => obj.label_id === currentValues.id
    );
    if (!currentLabel) return; // Ensure label is found
    const updatedCoords = { ...currentValues, [key]: value };
    setCurrentValues(updatedCoords);

    const index = labelSlotsList.findIndex(
      obj => obj.label_id === currentValues.id
    );
    if (index !== -1) {
      setLabelSlotsList(prevLabelSlotsList => {
        const updatedLabelSlotsList = [...prevLabelSlotsList];
        const existingCoords = updatedLabelSlotsList[index].coordinates;

        const newCoords = {
          x: typeof updatedCoords.x === 'string' ? 0 : updatedCoords.x,
          y: typeof updatedCoords.y === 'string' ? 0 : updatedCoords.y,
          height:
            typeof updatedCoords.height === 'string' ? 0 : updatedCoords.height,
          width:
            typeof updatedCoords.width === 'string' ? 0 : updatedCoords.width,
          showBorder: true
        };

        const newCoordinates = [...existingCoords];
        newCoordinates[currentValues.coordsIndex] = newCoords;
        updatedLabelSlotsList[index] = {
          ...prevLabelSlotsList[index],
          coordinates: newCoordinates
        };
        onUpdateLabelList(updatedLabelSlotsList);
        return updatedLabelSlotsList;
      });
    }
  }

  return (
    <div className="relative w-1/2">
      <div className="bg-light-blue z-10 flex justify-around gap-2 rounded-t-lg border border-b-0 p-2">
        <NumberInput
          disabled={labelSlotsList.every(item =>
            item.coordinates.every(
              coordinatesList => coordinatesList.showBorder === false
            )
          )}
          contentBefore="X:"
          size={SizeEnum.small}
          className="w-[23%]"
          value={currentValues.x}
          onChange={value => handleUpdates(value, COORDINATES.x)}
        />
        <NumberInput
          disabled={labelSlotsList.every(item =>
            item.coordinates.every(
              coordinatesList => coordinatesList.showBorder === false
            )
          )}
          contentBefore="Y:"
          size={SizeEnum.small}
          className="w-[23%]"
          value={currentValues.y}
          onChange={value => handleUpdates(value, COORDINATES.y)}
        />
        <NumberInput
          disabled={labelSlotsList.every(item =>
            item.coordinates.every(
              coordinatesList => coordinatesList.showBorder === false
            )
          )}
          contentBefore="W:"
          contentAfter="px"
          size={SizeEnum.small}
          className="w-[23%]"
          value={currentValues.width}
          onChange={value => handleUpdates(value, COORDINATES.width)}
        />
        <NumberInput
          disabled={labelSlotsList.every(item =>
            item.coordinates.every(
              coordinatesList => coordinatesList.showBorder === false
            )
          )}
          contentBefore="H:"
          contentAfter="px"
          size={SizeEnum.small}
          className="w-[23%]"
          value={currentValues.height}
          onChange={value => handleUpdates(value, COORDINATES.height)}
        />
      </div>

      <div className="relative h-[85%] w-full" ref={drop}>
        <GraphPaperComponent labelSlotsList={labelSlotsList}>
          <div ref={containerRef} className="h-full w-full">
            {isOption && labelSlotsList.length > 0
              ? labelSlotsList[selectedIndex]?.coordinates.map(
                  (coord, coordsIndex) => (
                    <EditableArea
                      key={coordsIndex}
                      coord={coord}
                      index={selectedIndex}
                      coordsIndex={coordsIndex}
                      label={labelSlotsList[selectedIndex]}
                      labelSlotsList={labelSlotsList}
                      isOption={isOption}
                      scaleFactor={scaleFactor}
                      xTranslation={xTranslation}
                      yTranslation={yTranslation}
                      bboxX={minX}
                      bboxY={minY}
                      setSelectedIndex={setSelectedIndex}
                      setLabelSlotsList={setLabelSlotsList}
                      labelSlots={labelSlots}
                      onUpdateLabelList={onUpdateLabelList}
                      setCurrentValues={setCurrentValues}
                    />
                  )
                )
              : labelSlotsList.map((label, index) => {
                  const { coordinates } = label;
                  return coordinates.map((coord, coordsIndex) => {
                    return (
                      <EditableArea
                        key={coordsIndex}
                        index={index}
                        coord={coord}
                        coordsIndex={coordsIndex}
                        label={label}
                        labelSlotsList={labelSlotsList}
                        isOption={isOption}
                        scaleFactor={scaleFactor}
                        xTranslation={xTranslation}
                        yTranslation={yTranslation}
                        bboxX={minX}
                        bboxY={minY}
                        setSelectedIndex={setSelectedIndex}
                        setLabelSlotsList={setLabelSlotsList}
                        labelSlots={labelSlots}
                        onUpdateLabelList={onUpdateLabelList}
                        setCurrentValues={setCurrentValues}
                      />
                    );
                  });
                })}
          </div>
        </GraphPaperComponent>
      </div>
    </div>
  );
}
