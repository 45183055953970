import { SearchIcon } from '../../components/icons/SearchIcon';
import { TextField } from '../../components/inputs/TextField';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Pager from '../../components/Pager';
import { closeSeeAll } from '../accordion/accordionSlice';
import {
  selectPalette,
  selectProductTag,
  selectStyle,
  setPalette
} from '../item/store/itemSlice';
import { useGetProductQuery } from '../api/store/apiSlice';
import { Group, Palette } from '../../types';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import SeeAllGrid from '../../components/grids/SeeAllGrid';
import GridElement from '../../components/grids/GridElement';

const DEFAULT_COLORS_TO_DISPLAY = 12;

const ColorSkeleton = (
  <ol
    role="status"
    className="grid animate-pulse grid-cols-3 grid-rows-[66px_66px_66px_66px] items-center gap-2.5 pb-2.5"
  >
    {Array.from({ length: DEFAULT_COLORS_TO_DISPLAY }).map((_, i) => (
      <li
        key={i}
        className="rounded-md border-2 border-solid border-transparent"
      >
        <div className="m-px flex rounded-md border border-solid border-gray-100 bg-gray-100">
          <div className="box-content h-[60px] w-[60px] rounded bg-gray-200 object-scale-down object-center" />
          <div></div>
        </div>
      </li>
    ))}
  </ol>
);

export default function AllColors() {
  const dispatch = useConfiguratorDispatch();

  const selectedColor = useRootSelector(selectPalette);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const outerSelectedPalette = useMemo(() => selectedColor, []);

  const productTag = useRootSelector(selectProductTag);
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductQuery(productTag);

  const selectedStyle = useRootSelector(selectStyle);
  const colors = useMemo(() => {
    return selectedStyle && data?.palettes
      ? data.palettes.filter(p =>
          selectedStyle.palette_ids.includes(p.palette_id)
        )
      : [];
  }, [selectedStyle, data?.palettes]);

  const [colorsPage, setColorsPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState('');
  const [colorSlice, setColorSlice] = useState<Palette[]>([]);

  const groups = useMemo(() => {
    let permittedGroups: Group[] = [];
    const colorIds = new Set(colors.map(c => c.palette_id));
    if (data && selectedStyle) {
      permittedGroups = data.filters?.filter(f => f.type === 'palettes');
      permittedGroups = permittedGroups.filter(
        group => group.entity_ids.filter(p => colorIds.has(p)).length
      );
    }
    if (permittedGroups?.length) {
      return [
        { display_name: 'All', group_id: 'all' },
        ...permittedGroups
      ] as Group[];
    } else {
      return null;
    }
  }, [selectedStyle, data, colors]);
  const [selectedGroup, setSelectedGroup] = useState<Group>();

  useEffect(() => {
    if (groups?.length) {
      setSelectedGroup(groups[0]);
    }
  }, [groups]);

  useEffect(() => {
    let groupedColors;
    if (selectedGroup?.entity_ids) {
      groupedColors = colors.filter(c =>
        selectedGroup.entity_ids.includes(c.palette_id)
      );
    } else {
      groupedColors = colors;
    }
    const filteredColors = groupedColors?.filter(c =>
      c.display_name.toLowerCase().includes(filter.toLowerCase())
    );

    const newTotalPages = Math.ceil(
      filteredColors?.length / DEFAULT_COLORS_TO_DISPLAY
    );
    setTotalPages(newTotalPages);
    const newColorsPages = Math.min(Math.max(0, newTotalPages - 1), colorsPage);
    setColorsPage(newColorsPages);

    const start = newColorsPages * DEFAULT_COLORS_TO_DISPLAY;
    const end = start + DEFAULT_COLORS_TO_DISPLAY;
    setColorSlice(filteredColors?.slice(start, end) ?? []);
  }, [colors, colorsPage, filter, selectedGroup]);

  return (
    <>
      <div className="my-2.5 flex flex-col gap-2.5 overflow-y-auto">
        <div className="px-2.5 py-1 text-center text-sm font-semibold">
          Select a Palette
        </div>
        <ul className="flex flex-wrap gap-1.5">
          {groups?.map(group => (
            <li
              key={group.group_id}
              onClick={() => setSelectedGroup(group)}
              className={clsx(
                'border-dark-gray rounded border border-solid px-2 py-1.5',
                {
                  'bg-navy text-white': selectedGroup == group
                }
              )}
            >
              {group.display_name}
            </li>
          ))}
        </ul>
        <TextField
          size="large"
          className="flex-1"
          placeholder="Search"
          contentAfter={<SearchIcon />}
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
        {isLoading && ColorSkeleton}
        {isError && (
          <>
            <h1>There was an error loading colors</h1> {console.log(error)}
          </>
        )}
        {isSuccess && (
          <SeeAllGrid>
            {colorSlice.map(color => (
              <GridElement
                key={color.palette_id}
                display_name={color.display_name}
                thumbnail_path={color.thumbnail_path}
                selected={color == selectedColor}
                onClick={() => dispatch(setPalette(color))}
              />
            ))}
          </SeeAllGrid>
        )}
        <Pager
          size="large"
          totalPages={totalPages}
          currentPage={colorsPage}
          onBack={() => setColorsPage(old => Math.max(0, old - 1))}
          onForward={() =>
            setColorsPage(old => Math.min(old + 1, totalPages - 1))
          }
        />
        <div className="flex justify-stretch gap-2">
          <button
            className="border-primary text-primary border-1 grow rounded-full border-solid px-2.5 py-4 font-semibold"
            onClick={() => {
              dispatch(setPalette(outerSelectedPalette));
              dispatch(closeSeeAll());
            }}
          >
            Back
          </button>
          <button
            onClick={() => dispatch(closeSeeAll())}
            className="bg-primary border-1 grow rounded-full border-solid px-2.5 py-4 font-semibold text-white"
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
}
