interface ConfiguratorHeaderProps {
  title: string;
  priceCents: number;
}

export function ConfiguratorHeader({
  title,
  priceCents
}: ConfiguratorHeaderProps) {
  const formattedPrice = priceCents
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(priceCents / 100)
    : '';

  return (
    <div id="configurator-header" className="flex gap-3 self-center py-4">
      <p className="text-xl font-semibold	">{title}</p>
      <p>{formattedPrice}</p>
    </div>
  );
}
