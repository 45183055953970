import { ItemProperties, Label, Product, Variant } from '../../../types';

import { VariantPreviewComponent } from './VariantPreview';
import { useMemo } from 'react';
import { UpsellProduct } from '../../upsells/types';
import { useRootSelector } from '../../../store';
import { selectItemProperties } from '../../item/store/itemSlice';

interface SharedPreviewProps {
  product?: Product | UpsellProduct;
  isUpsell?: boolean;
  mobile?: boolean;
}

export function SharedPreviewComponent({
  product,
  isUpsell,
  mobile = false
}: SharedPreviewProps) {
  const item = useRootSelector(selectItemProperties);

  const variantCoordinatePairs = useMemo(() => {
    if (!(product && item?.style)) {
      return [];
    }

    const { labels, label_slots } = product;
    const pairs = [];
    if (label_slots.length === 1 || isUpsell) {
      let variant: Variant | undefined;
      if (isUpsell || Object.keys(item.slots).length > 1) {
        const defaultVariant = labels[0].variants.find(
          variant =>
            variant.style_id === item.style?.style_id &&
            variant.layers.length > 0
        );
        if (labels[0].iterative_style_ids?.includes(item.style?.style_id)) {
          const iterativeVariantId =
            labels[0].iterative_defaults?.[item.style?.style_id];
          const iterativeVariant = labels[0].variants.find(
            variant => variant.label_variant_id === iterativeVariantId
          );
          variant = iterativeVariant || defaultVariant;
        } else {
          variant = defaultVariant;
        }
      } else {
        variant = getShapeVariant(item);
      }

      // The product should always support the style for upsells, but bail out if somehow it doesn't
      if (!variant) {
        return [];
      }
      const [slot] = label_slots;
      const slot_options = slot.slot_options;
      const slotOption = isUpsell
        ? slot_options[0]
        : slot_options.filter(slot => {
            const slotInfo = item.slots[slot.product_label_slot_id];
            return slotInfo && slot.label_id === slotInfo.labelId;
          })[0];

      if (variant && slotOption) {
        pairs.push({ variant, coordinates: slotOption.coordinates[0] });
      }
    } else {
      for (const slot of label_slots) {
        const [slotOption] = slot.slot_options;
        const coordinates = slotOption.coordinates;
        const { labelId, variantId } =
          item.slots[slotOption.product_label_slot_id];
        const variant = labels
          .find(label => label.label_id === labelId)
          ?.variants.find(variant => variant.label_variant_id === variantId);
        for (const coordsObj of coordinates) {
          if (coordinates)
            variant && pairs.push({ variant, coordinates: coordsObj });
        }
      }
    }
    return pairs;
  }, [isUpsell, item, product]);

  const allCoordinatePairs = useMemo(() => {
    if (!(product && item?.style)) {
      return [];
    }
    const { label_slots } = product;
    const slotOptions = label_slots.flatMap(slot => slot.slot_options);
    return slotOptions.flatMap(option => option.coordinates);
  }, [item?.style, product]);

  const single_slot = product?.label_slots.length === 1;

  const bounds = useMemo(() => {
    const coords = allCoordinatePairs;
    const nice = (num: number) => {
      return Math.min(Math.max(0, num || 0), 2000);
    };
    // Do not consider x/y if there is only one slot
    const s = single_slot ? 0 : 1;
    const minX = nice(Math.min(...coords.map(({ x }) => x * s)));
    const maxX = nice(Math.max(...coords.map(({ x, width }) => x * s + width)));
    const minY = nice(Math.min(...coords.map(({ y }) => y * s)));
    const maxY = nice(
      Math.max(...coords.map(({ y, height }) => y * s + height))
    );

    return {
      left: minX,
      right: maxX,
      top: minY,
      bottom: maxY,
      width: Math.max(maxX - minX, 0),
      height: Math.max(maxY - minY, 0)
    };
  }, [allCoordinatePairs, single_slot]);

  return (
    // `w-full` is necessary for safari/ios. safari requires a width
    // on all svgs.
    <svg
      id={product ? `product-preview-${product.product_id}` : undefined}
      className="drop-shadow-product h-full max-h-full w-full"
      preserveAspectRatio={mobile ? 'xMinYMid meet' : ''}
      viewBox={`${bounds.left} ${bounds.top} ${bounds.width} ${bounds.height}`}
    >
      {product
        ? variantCoordinatePairs.map(({ variant, coordinates }, i) => {
            const x = single_slot
              ? bounds.width / 2 - coordinates.width / 2
              : coordinates.x;
            const y = single_slot
              ? bounds.height / 2 - coordinates.height / 2
              : coordinates.y;
            return (
              <g transform={`translate(${x} ${y})`} key={'VariantPreview-' + i}>
                <VariantPreviewComponent
                  width={coordinates.width}
                  height={coordinates.height}
                  key={'VariantPreview-' + i}
                  variant={variant}
                  itemProperties={item}
                  isUpsell={isUpsell}
                />
              </g>
            );
          })
        : null}
    </svg>
  );
}

function getShapeVariant(item: ItemProperties) {
  const { labelShape } = item;
  const variant = getLabelVariant(labelShape!, item);
  return variant;
}

function getLabelVariant(label: Label, item: ItemProperties) {
  const { variants } = label!;

  // this must be a one-slot-product for this to make sense.
  if (Object.keys(item.slots).length > 1) {
    throw new Error(
      'Product may only include one slot when calling `getLabelVariant`'
    );
  }

  const variantId = Object.values(item.slots)[0].variantId;
  const variant = variants.find(
    variant => variant.label_variant_id === variantId
  );

  return variant;
}
