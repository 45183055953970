import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Pager from '../../components/Pager';
import {
  PAGE_TYPE,
  openSeeAll,
  selectStylePage,
  setPage
} from '../accordion/accordionSlice';
import { selectStyle, updateStyle } from '../item/store/itemSlice';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import { Style } from '../../types';
import { useStyleAndColor } from './StyleAndColorHooks';
import { tooltipClasses } from '../../components/grids/GridElement';

const DEFAULT_STYLES_TO_DISPLAY = 12;

const StyleSkeleton = (
  <ol
    role="status"
    className="grid animate-pulse grid-cols-6 justify-items-center gap-4 pb-2.5"
  >
    {Array.from({ length: DEFAULT_STYLES_TO_DISPLAY }).map((_, i) => (
      <li
        key={i}
        className={clsx('rounded-md border-2 border-solid border-transparent')}
      >
        <div className="m-px h-[60px] w-[60px] rounded bg-gray-300"></div>
      </li>
    ))}
  </ol>
);

export default function Styles() {
  const dispatch = useConfiguratorDispatch();
  const stylesPage = useRootSelector(selectStylePage);
  const { styles, isLoading, isSuccess, isError, error } = useStyleAndColor();

  const totalPages = Math.ceil(styles?.length / DEFAULT_STYLES_TO_DISPLAY) ?? 0;

  const selectedStyle = useRootSelector(selectStyle);

  const [styleSlice, setStyleSlice] = useState<Style[]>([]);

  useEffect(() => {
    const start = stylesPage * DEFAULT_STYLES_TO_DISPLAY;
    const end = start + DEFAULT_STYLES_TO_DISPLAY;
    setStyleSlice(styles?.slice(start, end) ?? []);
  }, [styles, stylesPage]);

  return (
    <>
      <div>
        {isLoading && StyleSkeleton}
        {isError && (
          <>
            <h1>There was a problem loading Styles!</h1> {console.log(error)}
          </>
        )}
        {isSuccess && styles.length > 1 && (
          <ol className="grid grid-cols-4 grid-rows-[66px] justify-items-center gap-4 pb-2.5 md:grid-cols-6">
            {styleSlice.map(style => (
              <li
                key={style?.style_id}
                className={clsx(
                  'box-border h-[66px] rounded-md border-2 border-solid',
                  style == selectedStyle ? 'border-navy ' : 'border-transparent'
                )}
              >
                <Tooltip id={`style-tooltip`} className={tooltipClasses} />
                <img
                  src={style.thumbnail_path}
                  className="m-px box-border h-[60px] w-[60px] rounded object-scale-down object-center"
                  data-tooltip-id="style-tooltip"
                  data-tooltip-content={style.display_name}
                  data-tooltip-place="top"
                  onClick={() => dispatch(updateStyle(style))}
                />
              </li>
            ))}
          </ol>
        )}
        <div className="grid min-h-10 grid-cols-4 items-center pb-2.5">
          <span className="text-dark-gray truncate text-sm font-semibold">
            {selectedStyle?.display_name ?? ''}
          </span>
          <Pager
            size="small"
            totalPages={totalPages}
            currentPage={stylesPage}
            onBack={() =>
              dispatch(
                setPage({
                  page: PAGE_TYPE.style,
                  pageNumber: Math.max(0, stylesPage - 1)
                })
              )
            }
            onForward={() =>
              dispatch(
                setPage({
                  page: PAGE_TYPE.style,
                  pageNumber: Math.min(stylesPage + 1, totalPages - 1)
                })
              )
            }
          />
          <button
            onClick={() => dispatch(openSeeAll('Styles'))}
            className="border-primary text-primary border-1 justify-self-end rounded-full border-solid px-2.5 py-1.5 font-semibold"
          >
            See all
          </button>
        </div>
      </div>
    </>
  );
}
