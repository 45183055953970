import Joi from 'joi';

// Note: the order of fields in the `Joi` object is the order any errors
// will be displayed on the page.
const createMaterialSchema = Joi.object({
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  upsell_title: Joi.string().label('Upsell Title').allow(''),
  description: Joi.string().label('Description').allow(''),
  thumbnail: Joi.string().label('Thumbnail').required(),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code')
});

const updateMaterialSchema = Joi.object({
  material_id: Joi.string().label('Material Id').required(),
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  description: Joi.string().label('Description').allow(''),
  upsell_title: Joi.string().label('Upsell Title').allow(''),
  thumbnail: Joi.string().allow(''),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code')
});

export default {
  createMaterialSchema,
  updateMaterialSchema
};
