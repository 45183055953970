import { API_URL, CUSTOMER_API_URL } from '../../../config';
import { CartItem, ItemProperties, ItemSlot, Product } from '../../../types';
import { formatCentsToDollarString } from '../../../utils';
import { CartItemState } from '../../app/Configurator';

import { CartLineItemProperties } from '../../item/types';
import { generateLegacyProductCode } from '../../productCode';
import {
  ProductClassifications,
  SelectionVdpCodes
} from '../../productCode/types';
import { ChosenUpsell, UpsellProduct } from '../../upsells/types';

import { ApiCartItem, MockLineItem, MockOrder } from '../types';

export function createMockOrder(subTotalCents: number, cartItem: CartItem) {
  const timestamp = new Date().toISOString();

  const mockOrder: MockOrder = {
    id: Date.now(),
    subtotal_price: formatCentsToDollarString(subTotalCents).replace('$', ''),
    created_at: timestamp,
    updated_at: timestamp,
    cancelled_at: null,
    line_items: []
  };

  const lineItem: MockLineItem = createMockPrimaryProduct(cartItem);
  mockOrder.line_items.push(lineItem);
  return mockOrder;
}

function createMockPrimaryProduct(cartItem: CartItem) {
  const lineItem: MockLineItem = {
    quantity: 1,
    sku: generateRandomSku(),
    properties: [{ name: '_order_system', value: 'NEW' }]
  };

  for (const name in cartItem) {
    const value = cartItem[name];
    if (value) {
      lineItem.properties.push({ name, value: `${value}` });
    }
  }
  return lineItem;
}

export function generateRandomSku(length = 15) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getChosenUpsell(
  chosenUpsellItems: ChosenUpsell[],
  upsellCartItem: {
    id: string | undefined;
    quantity: number;
    properties: CartLineItemProperties;
  }
) {
  return chosenUpsellItems.find(chosen => {
    return chosen.upsell.product_id === upsellCartItem.properties._product_id;
  });
}

export function getUpsellProductCode(
  chosenUpsell: ChosenUpsell | undefined,
  properties: ItemProperties
) {
  const upsellVdpCodes = getUpsellProductVdpCodes(chosenUpsell, properties);

  const upsellProductCode = generateLegacyProductCode(
    upsellVdpCodes,
    ProductClassifications.UPSELL
  );
  return upsellProductCode;
}

export function getProductCode(
  apiProduct: Product | undefined,
  properties: ItemProperties
) {
  const isPack = (apiProduct?.label_slots.length || 0) > 1;
  const classification = isPack
    ? ProductClassifications.PACK
    : ProductClassifications.NON_PACK;

  const vdpCodes: SelectionVdpCodes = getProductVdpCodes(
    apiProduct,
    properties
  );

  const productCode = generateLegacyProductCode(vdpCodes, classification);
  return productCode;
}

export function getUpsellProductVdpCodes(
  upsell: ChosenUpsell | undefined,
  properties: ItemProperties
): SelectionVdpCodes {
  const upsellProduct = upsell?.upsell;

  const [labelSlot] = upsellProduct?.label_slots ?? [null];
  const [slotOption] = labelSlot?.slot_options ?? [null];
  const label =
    upsellProduct?.labels.find(
      ({ label_id }) => label_id === slotOption?.label_id
    ) ?? null;

  return {
    product: upsellProduct?.vdpcode ?? null,
    label: label?.vdpcode ?? null,
    style: properties.style?.vdpcode ?? null,
    palette: properties.palette?.vdpcode ?? null,
    icon: properties.icon?.vdpcode ?? null,
    font: properties.font?.vdpcode ?? null,
    labelVariant: null,
    material: upsell?.material?.vdpcode ?? null,
    shoe: null
  };
}

export function getProductVdpCodes(
  apiProduct: Product | undefined,
  properties: ItemProperties
): SelectionVdpCodes {
  return {
    product: apiProduct?.vdpcode ?? null,
    label: getLabel(properties, apiProduct)?.vdpcode ?? null,
    style: properties.style?.vdpcode ?? null,
    palette: properties.palette?.vdpcode ?? null,
    icon: properties.icon?.vdpcode ?? null,
    font: properties.font?.vdpcode ?? null,
    labelVariant: getPackShape(properties, apiProduct)?.vdpcode ?? null,
    material: properties.clothingType?.vdpcode ?? null,
    shoe: getShoe(properties, apiProduct)?.vdpcode ?? null
  };
}

export function getShoe(
  properties: ItemProperties,
  apiProduct: Product | undefined
) {
  let shoe = null;
  for (const slotId in Object.keys(properties.slots)) {
    const slot = properties.slots[slotId];

    if (!slot) continue;

    const label = apiProduct?.labels.find(
      ({ label_id }) => label_id === slot.labelId
    );

    if (!label) continue;

    const lowerSystemName = label?.system_name?.toLowerCase();
    const isShoeLabel = lowerSystemName?.includes('shoe');
    if (isShoeLabel) {
      shoe =
        label?.variants.find(variant => {
          return variant.label_variant_id === slot.variantId;
        }) ?? null;
      break;
    }
  }
  return shoe;
}

export function getLabel(
  properties: ItemProperties,
  apiProduct: Product | undefined
) {
  const firstLabelId = Object.values(properties.slots)[0]?.labelId;
  const label = apiProduct?.labels.find(
    ({ label_id }) => label_id === firstLabelId
  );
  return label;
}

export function getPackShape(
  properties: ItemProperties,
  apiProduct: Product | undefined
) {
  let labelVariant = null;
  const slotIds = Object.keys(properties.slots);
  for (const slotId of slotIds) {
    const slot = properties.slots[slotId];

    if (!slot) continue;

    const label = apiProduct?.labels.find(
      ({ label_id }) => label_id === slot?.labelId
    );

    if (!label) continue;

    // if system name contains shoe
    // cannot be "pack shape"
    const lowerSystemName = label?.system_name?.toLowerCase();
    const isShoeLabel = lowerSystemName?.includes('shoe');
    if (isShoeLabel) continue;

    const styleId = properties?.style?.style_id;

    // if does not have more than one variant supporting chosen style
    // cannot be "pack shape"
    const validVariants = label?.variants.filter(
      variant => variant.style_id === styleId
    );
    const hasMultipleVariants = (validVariants?.length || 0) < 2;
    if (hasMultipleVariants) continue;

    // if is iterative
    // cannot be "pack shape"
    const isIterative =
      styleId && label?.iterative_style_ids?.includes(styleId);
    if (isIterative) continue;

    labelVariant = label?.variants.find(variant => {
      return variant.label_variant_id === slot.variantId;
    });
  }
  return labelVariant;
}

export function buildPrimaryApiCartItem(
  properties: ItemProperties,
  apiProduct: Product | undefined
) {
  const apiCartItemTextFields = Object.values(properties.fields).map(
    ({ field_id, value }) => {
      return { text_field_id: field_id, value };
    }
  );

  const apiCartItemLabelSlots = Object.entries(properties.slots).map(
    ([slotId, { labelId, variantId, materialId }]) => {
      return {
        slot_id: slotId,
        label_id: labelId,
        label_variant_id: variantId,
        material_id: materialId
      };
    }
  );

  const apiCartItemData = {
    product_id: apiProduct?.product_id,
    style_id: properties.style?.style_id || null,
    palette_id: properties.palette?.palette_id || null,
    font_id: properties.font?.font_id || null,
    icon_id: properties.icon?.icon_id || null,
    text_fields: apiCartItemTextFields,
    label_slots: apiCartItemLabelSlots,
    legacy_product_code: getProductCode(apiProduct, properties),
    previewSvg:
      document.getElementById(`product-preview-${apiProduct?.product_id}`)
        ?.outerHTML || ''
  };

  return apiCartItemData;
}

export async function getApiCartItem(
  cartItemId: string
): Promise<CartItemState> {
  const res = await fetch(CUSTOMER_API_URL + `/cart-items/${cartItemId}`);
  return await res.json();
}

export async function updateApiCartItem(
  cartItemId: string,
  apiCartItem: ApiCartItem
) {
  const cartItemResponse = await fetch(`${API_URL}/cart-items/${cartItemId}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(apiCartItem)
  });

  if (!cartItemResponse.ok) {
    console.error('error saving cart item configuration', apiCartItem);
    throw new Error('Error saving item configuration');
  }
  return cartItemId;
}

export async function deleteApiCartItem(cartItemId: string) {
  const cartItemResponse = await fetch(`${API_URL}/cart-items/${cartItemId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  });

  if (!cartItemResponse.ok) {
    console.error('Error deleting cart item', cartItemId);
    throw new Error('Error deleting cart item');
  }
  return cartItemId;
}

export async function sendApiCartItem(apiCartItem: ApiCartItem) {
  const cartItemResponse = await fetch(`${API_URL}/cart-items`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(apiCartItem)
  });

  if (!cartItemResponse.ok) {
    console.error('error saving cart item configuration', apiCartItem);
    throw new Error('Error saving item configuration');
  }

  const { cart_item_id } = await cartItemResponse.json();
  return cart_item_id;
}

export function buildUpsellApiCartItem(
  chosenUpsell: ChosenUpsell | undefined,
  properties: ItemProperties,
  primaryApiCartItem: ApiCartItem
) {
  const upsellApiCartItemLabelSlots =
    chosenUpsell?.upsell.label_slots.map(slot => {
      const { product_label_slot_id: slot_id } = slot;

      // assume there is only one slot option
      const [slotOption] = slot.slot_options;
      const { label_id } = slotOption;

      // get the first variant that supports the style
      const label = chosenUpsell.upsell.labels.find(
        label => label.label_id === label_id
      );
      const variant = label?.variants.find(
        variant => variant.style_id === properties.style?.style_id
      );

      // if the slot supports the material, use it, otherwise use the first material
      const slotSupportsMaterial = slotOption.material_ids.includes(
        chosenUpsell.material?.material_id
      );
      const material_id = slotSupportsMaterial
        ? chosenUpsell.material?.material_id
        : slotOption.material_ids[0];

      return {
        slot_id,
        label_id,
        label_variant_id: variant?.label_variant_id,
        material_id
      };
    }) || [];

  const upsellApiCartItemData = {
    ...primaryApiCartItem,
    product_id: chosenUpsell?.upsell.product_id,
    label_slots: upsellApiCartItemLabelSlots,
    legacy_product_code: getUpsellProductCode(chosenUpsell, properties),
    previewSvg:
      document.getElementById(
        `product-preview-${chosenUpsell?.upsell.product_id}`
      )?.outerHTML || ''
  };

  return upsellApiCartItemData;
}

export function getQuantityProperty(
  slots: Record<string, ItemSlot>,
  apiProduct?: Product | UpsellProduct
) {
  return Object.entries(slots).reduce((acc, [slotId, { labelId }]) => {
    const slot = apiProduct?.label_slots?.find(
      label_slot => label_slot.product_label_slot_id == slotId
    );

    const slotOption = slot?.slot_options?.find(
      slot_option => slot_option.label_id == labelId
    );

    return (acc += slotOption?.quantity ?? 0);
  }, 0);
}
