import Joi from 'joi';
import { isValidHexColor } from '../utils';

export const colorSlotFormSchema = Joi.object({
  palette_id: Joi.string().label('Color Slot Palette Id'),
  color_slot_id: Joi.string().label('Color Slot Id'),
  print_key: Joi.string().required().label('Color Slot Print Key'),
  display_name: Joi.string().required().label('Color Slot Display Name'),
  color_hex: Joi.string()
    .custom((value, helpers) => {
      if (value !== null && !isValidHexColor(value)) {
        return helpers.error('any.invalid', {
          message: `Color Hex must be a valid hex color or empty. Received: ${value}`
        });
      }
      return value;
    }, 'Custom hex color validation')
    .allow(null)
    .label('Color Slot Color Hex'),
  index: Joi.number().min(0).required().label('Color Slot Index')
});

export const colorSlotsArrayFormSchema = Joi.array()
  .min(12)
  .custom((value, helpers) => {
    const validHexCodes = value.filter(
      (colorSlot: { color_hex: string | null }) =>
        isValidHexColor(colorSlot.color_hex)
    );

    if (validHexCodes.length < 3) {
      return helpers.error('any.invalid', {
        message: 'There must be at least 1 valid hex code.'
      });
    }
  });

export const createPaletteDetailsSchema = Joi.object({
  palette_id: Joi.string().label('Palette Id'),
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  thumbnail: Joi.string().label('Thumbnail').required(),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code')
});

export const updatePaletteDetailsSchema = Joi.object({
  palette_id: Joi.string().label('Palette Id'),
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  thumbnail: Joi.string().allow(''),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code')
});
