import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

import { accordionSlice } from '../features/accordion/accordionSlice';
import { apiSlice } from '../features/api/store/apiSlice';
import { itemSlice } from '../features/item/store/itemSlice';
import { upsellsSlice } from '../features/upsells/store/upsellsSlice';

export const configuratorStore = configureStore({
  reducer: {
    accordion: accordionSlice.reducer,
    item: itemSlice.reducer,
    upsells: upsellsSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
});

export type RootState = ReturnType<typeof configuratorStore.getState>;

export type ConfiguratorDispatch = typeof configuratorStore.dispatch;

export const useConfiguratorDispatch: () => ConfiguratorDispatch = useDispatch;

export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector;
