import { useRootSelector } from '../../../store';

import { useGetProductQuery } from '../../api/store/apiSlice';

import { selectProductTag } from '../../item/store/itemSlice';

import { SharedPreviewComponent } from './SharedPreview';

interface ProductPreviewProps {
  mobile?: boolean;
  productTag?: string;
}

export function ProductPreviewComponent({
  mobile,
  productTag
}: ProductPreviewProps) {
  const shopifyProductTag: string =
    useRootSelector(selectProductTag) || productTag || '';
  const { data: product } = useGetProductQuery(shopifyProductTag);

  return <SharedPreviewComponent product={product} mobile={mobile} />;
}
