import { useMemo } from 'react';

import { useGetProductQuery } from '../api/store/apiSlice';
import { useRootSelector } from '../../store';

import {
  selectProductTag,
  selectSlots,
  selectStyle
} from '../item/store/itemSlice';
import { ItemSlot, Label } from '../../types';
import { groupBy, orderBy } from 'lodash';

export function getFields(
  labels: Label[],
  selectedSlots: Record<string, ItemSlot>,
  style_id: string
) {
  const activeSlots = Object.values(selectedSlots);

  const allVariants = new Map(
    labels.flatMap(
      label =>
        label.variants
          ?.filter(variant => variant.style_id === style_id)
          ?.map(variant => [variant.label_variant_id, variant]) ?? []
    )
  );

  const textFieldsWithDuplicates = activeSlots.flatMap(
    slot => allVariants.get(slot.variantId!)?.text_fields ?? []
  );
  const textFields = groupBy(textFieldsWithDuplicates, 'text_field_id');
  const textFieldsDeDuped = Object.values(textFields).map(tf => {
    if (tf.length === 1) return tf[0];
    return tf.find(t => t.client_choice) || tf[0];
  });
  return textFieldsDeDuped;
}

export function useFontsAndFields() {
  const productTag = useRootSelector(selectProductTag);
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductQuery(productTag);

  const selectedSlots = useRootSelector(selectSlots);
  const selectedStyle = useRootSelector(selectStyle);

  const fields = useMemo(() => {
    if (!data?.labels || !selectedStyle?.style_id) return [];
    const tempFields = getFields(
      data.labels,
      selectedSlots,
      selectedStyle?.style_id
    );

    return orderBy(
      tempFields,
      ['is_required', 'display_name'],
      ['desc', 'asc']
    );
  }, [data?.labels, selectedSlots, selectedStyle?.style_id]);

  const fonts = useMemo(() => {
    const fontChoice = fields.find(f => f.client_choice);
    if (!selectedStyle || !data?.fonts || !fontChoice) return [];
    const fontsInStyle = data.fonts.filter(f =>
      selectedStyle.font_ids.includes(f.font_id)
    );
    fontsInStyle.sort((a, b) => a.display_name.localeCompare(b.display_name));
    return fontsInStyle;
  }, [selectedStyle, data?.fonts, fields]);

  const hideFontsAndFields = useMemo(() => {
    return isSuccess && (fonts?.length ?? 0) <= 1 && !fields?.length;
  }, [isSuccess, fonts, fields]);

  return {
    fonts,
    fields,
    isLoading,
    isSuccess,
    isError,
    error,
    hideFontsAndFields
  };
}
