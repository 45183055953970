import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { UpsellProduct } from '../types';
import { selectChosenUpsellItems } from '../store/upsellsSlice';

import { UpsellDetails } from './UpsellDetails';
import { UpsellMaterialSelector } from './UpsellMaterialSelector';
import { UpsellPreview } from '../../preview/components/UpsellPreview';
import { AddUpsellButton } from './AddUpsellButton';
import { RemoveUpsellButton } from './RemoveUpsellButton';
import { formatCentsToDollarString } from '../../../utils';

interface UpsellSelectorProps {
  upsell: UpsellProduct;
}

export function UpsellSelector({ upsell }: UpsellSelectorProps) {
  const [selectedMaterial, setSelectedMaterial] = useState(upsell.materials[0]);
  const chosenUpsellItems = useSelector(selectChosenUpsellItems);

  const added = useMemo(
    () =>
      !!chosenUpsellItems.find(
        item => item.upsell.product_id === upsell.product_id
      ),
    [chosenUpsellItems, upsell.product_id]
  );

  useEffect(() => {
    const chosen = chosenUpsellItems.find(
      item => item.upsell.product_id === upsell.product_id
    );
    if (
      chosen?.material &&
      selectedMaterial &&
      chosen.material.material_id !== selectedMaterial.material_id
    ) {
      setSelectedMaterial(chosen.material);
    }
  }, [
    chosenUpsellItems,
    selectedMaterial,
    selectedMaterial?.material_id,
    upsell.product_id
  ]);

  return (
    <div
      className=" flex flex-col border-b border-solid py-2"
      style={{ borderColor: '#cccccc' }}
    >
      <div className="flex items-center gap-3 px-1">
        <UpsellPreview upsell={upsell} />

        <div className="flex flex-1 flex-col gap-3">
          <div className="flex gap-3">
            <UpsellDetails
              formattedPrice={formatCentsToDollarString(upsell.priceCents || 0)}
              productName={upsell.name}
              quantity={upsell.quantity}
            />

            {upsell.materials.length > 1 ? (
              <UpsellMaterialSelector
                added={added}
                selectedMaterial={selectedMaterial}
                setSelectedMaterial={setSelectedMaterial}
                upsell={upsell}
              />
            ) : null}
          </div>

          {added ? (
            <RemoveUpsellButton upsell={upsell} />
          ) : (
            <AddUpsellButton
              upsell={upsell}
              selectedMaterial={selectedMaterial}
            />
          )}
        </div>
      </div>
    </div>
  );
}
