import { useCallback, useEffect, useState } from 'react';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { toast } from 'react-toastify';
import { Label } from '../../labels/types';

export function useLabels(search?: string) {
  const { handleTRPCRequest } = useTRPCRequest();

  const [labels, setLabels] = useState<Label[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchLabels = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.labels,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: labels, error } = await handleTRPCRequest(trpcRequest);

      if (error) {
        toast.error('Error fetching labels');
      }
      setLabels(labels as Label[]);
      setIsLoading(false);
      return labels;
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchLabels(search ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return { labels, isLoading, refetch: fetchLabels };
}
