import clsx from 'clsx';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import { selectMaterial, updateMaterial } from '../item/store/itemSlice';
import { useMaterials } from './Materials.hooks';

type ClothingTypeProps = {
  error?: string;
};

export default function ClothingType({ error }: ClothingTypeProps) {
  const dispatch = useConfiguratorDispatch();
  const selected = useRootSelector(selectMaterial)?.material_id;

  const {
    materials: allMaterials,
    isError,
    isLoading,
    isSuccess
  } = useMaterials();

  return (
    <div
      className={clsx(
        'box-border w-full p-2 transition-colors',
        error && 'bg-error/10'
      )}
    >
      {isError && (
        <>
          <h1>There was an error loading materials</h1>{' '}
        </>
      )}
      {isLoading && <div>Loading Materials...</div>}
      {isSuccess && (
        <ol className="flex justify-stretch">
          {allMaterials.map((material, i) => (
            <li
              key={(material?.display_name ?? '') + i}
              className={clsx(
                'flex grow basis-1/2 items-stretch rounded-md border-2 border-solid',
                material?.material_id === selected
                  ? 'border-navy'
                  : 'border-transparent'
              )}
            >
              <div
                className={clsx(
                  'align-center m-px w-full rounded-md border border-solid border-gray-400 p-2.5',
                  {
                    'bg-gray-200': material?.material_id == selected
                  }
                )}
                onClick={() => {
                  dispatch(updateMaterial(material!));
                }}
              >
                <div className="align-center flex pb-1">
                  <img
                    src={material?.thumbnail_path}
                    className="box-content h-[60px] w-[60px] rounded object-scale-down object-center"
                  ></img>
                  <div className="px-2 text-sm font-semibold">
                    {material?.display_name}
                  </div>
                </div>
                <div className="text-xs">{material?.description}</div>
              </div>
            </li>
          ))}
        </ol>
      )}
      {error && <p className="text-error pl-2">{error}</p>}
    </div>
  );
}
