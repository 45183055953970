import { useMemo } from 'react';

import { useGetProductQuery } from '../api/store/apiSlice';
import { useRootSelector } from '../../store';

import {
  selectProductTag,
  selectSlots,
  selectStyle
} from '../item/store/itemSlice';

export function useIconsAndLabels() {
  const productTag = useRootSelector(selectProductTag);
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductQuery(productTag);

  const selectedStyle = useRootSelector(selectStyle);
  const slots = useRootSelector(selectSlots);

  /* Icons.
   * This step should only appear if one or more labels within the product has
   * an icon layer included
   */
  const showIcons = useMemo(() => {
    // labels on this product
    const productLabelIds = new Set(
      data?.label_slots?.flatMap(
        labelSlot =>
          labelSlot?.slot_options?.flatMap(slotOption => slotOption.label_id)
      )
    );

    // labels that have one of the above ids AND have A variant with an icon
    // layer - and that is in the style.
    const labels =
      data?.labels
        ?.filter(label => productLabelIds?.has(label.label_id))
        ?.filter(label =>
          label.variants.some(
            v => v.style_id === selectedStyle?.style_id && v.icons.length > 0
          )
        ) ?? [];

    const styleIcons = (selectedStyle?.icon_ids?.length ?? 0) > 1;

    return styleIcons && labels.length > 0;
  }, [data, selectedStyle]);

  /**
   * Depending on the style selected, there may be multiple label variants for
   * users to select from. If there is a label within the product that has
   * multiple label variants for the same style AND the style is not “Iterative”
   * then users will have a choice between the options at hand. If no labels
   * within the product meet this condition, the step should be hidden
   */

  const labelHasMultipleVariants = useMemo(() => {
    const labelVariantMap = {} as { [key: string]: boolean };
    if (slots && data) {
      data.label_slots?.forEach(labelSlot => {
        const labelId = slots?.[labelSlot.product_label_slot_id]?.labelId;

        const variants = data?.labels
          ?.find(label => label.label_id === labelId)
          ?.variants?.filter(
            variant => variant.style_id === selectedStyle?.style_id
          );

        labelVariantMap[labelId || ''] = (variants?.length ?? 0) > 1;
      });
    }
    return labelVariantMap;
  }, [slots, data, selectedStyle?.style_id]);

  const showLabels = useMemo(
    () =>
      data?.label_slots?.some(labelSlot => {
        const labelId = slots?.[labelSlot.product_label_slot_id]?.labelId;
        const label = data.labels.find(l => l.label_id === labelId);
        const isIterative = label?.iterative_style_ids?.includes(
          selectedStyle?.style_id ?? ''
        );
        const hasMultipleVariants = labelHasMultipleVariants[labelId || ''];
        return !(isIterative || !hasMultipleVariants);
      }),
    [data, labelHasMultipleVariants, slots, selectedStyle?.style_id]
  );

  const hideIconsAndLabels = useMemo(
    () => !showLabels && !showIcons,
    [showLabels, showIcons]
  );

  return {
    showIcons,
    showLabels,
    hideIconsAndLabels,
    labelHasMultipleVariants,
    isLoading,
    isSuccess,
    isError,
    error
  };
}
