import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import Pager from '../../components/Pager';
import {
  PAGE_TYPE,
  openSeeAll,
  selectIconsPage,
  setPage
} from '../accordion/accordionSlice';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { useGetProductQuery } from '../api/store/apiSlice';
import { useRootSelector } from '../../store';
import {
  selectIcon,
  selectProductTag,
  selectStyle,
  setIcon
} from '../item/store/itemSlice';
import { Icon } from '../../types';
import { tooltipClasses } from '../../components/grids/GridElement';

const DEFAULT_ICONS_TO_DISPLAY = 6;

const IconSkeleton = (
  <>
    <ol
      role="status"
      className="grid animate-pulse grid-cols-6 justify-items-center gap-4 pb-2.5"
    >
      {Array.from({ length: DEFAULT_ICONS_TO_DISPLAY }).map((_, i) => (
        <li
          key={i}
          className={clsx(
            'rounded-md border-2 border-solid border-transparent'
          )}
        >
          <div className="m-px h-[60px] w-[60px] rounded bg-gray-300"></div>
        </li>
      ))}
    </ol>
    <div className="grid grid-cols-3 items-center pb-2.5">
      <span className="h-4 w-24 animate-pulse rounded bg-gray-300 text-sm font-semibold"></span>
      <Pager
        size="small"
        totalPages={1}
        currentPage={0}
        onBack={() => {}}
        onForward={() => {}}
      />
      <button
        className="border-1 justify-self-end rounded-full border-solid border-gray-300 px-2.5 py-1.5 font-semibold text-gray-300"
        disabled={true}
      >
        See all
      </button>
    </div>
  </>
);

export default function Icons() {
  const dispatch = useDispatch();

  const productId = useRootSelector(selectProductTag);
  const iconsPage = useRootSelector(selectIconsPage);

  const selectedStyle = useRootSelector(selectStyle);
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductQuery(productId);

  const showIcons = data?.labels?.find(l =>
    l.variants.find(v => v.layers.length > 0)
  );

  const icons = useMemo(() => {
    return (
      data?.icons?.filter(i => selectedStyle?.icon_ids?.includes(i.icon_id)) ||
      []
    );
  }, [data?.icons, selectedStyle?.icon_ids]);
  const totalPages = Math.ceil(icons?.length / DEFAULT_ICONS_TO_DISPLAY) ?? 0;

  const selectedIcon = useRootSelector(selectIcon);

  const [iconSlice, setIconsSlice] = useState<Icon[]>([]);

  useEffect(() => {
    const start = iconsPage * DEFAULT_ICONS_TO_DISPLAY;
    const end = start + DEFAULT_ICONS_TO_DISPLAY;
    setIconsSlice(icons?.slice(start, end) ?? []);
  }, [icons, iconsPage]);

  return (
    <>
      {isLoading && IconSkeleton}
      {isError && (
        <>
          <h1>There was a problem loading Icons!</h1> {console.log(error)}
        </>
      )}
      {isSuccess && showIcons && (
        <>
          <ol className="grid grid-cols-6 items-center gap-2.5 pb-2.5">
            {iconSlice.map(icon => (
              <li
                key={icon.icon_id}
                className={clsx(
                  'box-border h-[66px] w-[66px] rounded-md border-2 border-solid',
                  icon == selectedIcon ? 'border-navy' : 'border-transparent'
                )}
              >
                <Tooltip id="icon-tooltip" className={tooltipClasses} />
                <img
                  src={icon.thumbnail_path}
                  className={clsx(
                    'm-px box-border h-[60px] w-[60px] rounded object-scale-down object-center'
                  )}
                  data-tooltip-id="icon-tooltip"
                  data-tooltip-content={icon?.display_name || ''}
                  data-tooltip-place="top"
                  onClick={() => dispatch(setIcon(icon))}
                />
              </li>
            ))}
          </ol>
          <div className="grid grid-cols-4 items-center pb-2.5">
            <span className="text-dark-gray truncate text-sm font-semibold">
              {selectedIcon?.display_name || ''}
            </span>
            <Pager
              size="small"
              totalPages={totalPages}
              currentPage={iconsPage}
              onBack={() =>
                dispatch(
                  setPage({
                    page: PAGE_TYPE.icon,
                    pageNumber: Math.max(0, iconsPage - 1)
                  })
                )
              }
              onForward={() =>
                dispatch(
                  setPage({
                    page: PAGE_TYPE.icon,
                    pageNumber: Math.min(iconsPage + 1, totalPages - 1)
                  })
                )
              }
            />
            <button
              onClick={() => dispatch(openSeeAll('Icons'))}
              className="border-primary text-primary border-1 justify-self-end rounded-full border-solid px-2.5 py-1.5 font-semibold"
            >
              See all
            </button>
          </div>
        </>
      )}
    </>
  );
}
