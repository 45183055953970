import { CSSProperties } from 'react';
import { XYCoord } from 'react-dnd';

export function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  xTranslation: number,
  yTranslation: number
): CSSProperties {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x - xTranslation}px, ${y - yTranslation}px)`;

  return {
    transform,
    WebkitTransform: transform
  };
}
