import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn
} from '@clerk/clerk-react';
import { ToastContainer } from 'react-toastify';

import { CLERK_PUBLIC_KEY } from '../config';

import 'react-toastify/dist/ReactToastify.min.css';
import { PermissionedRouter } from './PermissionedRouter';

export function App() {
  return (
    <ClerkProvider publishableKey={CLERK_PUBLIC_KEY}>
      <SignedIn>
        <div className="App flex h-full flex-col">
          <ToastContainer />
          <PermissionedRouter />
        </div>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </ClerkProvider>
  );
}
