import {
  useNavigate,
  useLocation,
  useParams,
  useBlocker
} from 'react-router-dom';
import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import { useCallback, useEffect, useState } from 'react';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { validateSchemaAndNotifyErrors } from '../../../utils';
import TextFieldValidation from '../validations';
import { TEXT_FIELD_FIELDS, TextField, TextFieldErrors } from '../types';
import {
  DisplayName,
  Select,
  SystemNameComponent
} from '../../../components/inputs';
import { useTRPCRequest } from '../../../hooks';
import { duplicateTextField } from '../utils';
import { LoadingDiv } from '../../../components/loading';
import { NumberInput } from '../../../components/inputs/NumberInput';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  LoadingModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';
import { Font } from '../../fonts/types';
import { toast } from 'react-toastify';
import { SizeEnum } from '../../../types';
import { Title } from '../../../components/Title';

interface TextFieldDetailsProps {
  create?: boolean;
}
export function TextFieldDetails({ create }: TextFieldDetailsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { text_field_id = '' } = useParams();
  const { handleTRPCRequest } = useTRPCRequest();
  const unsavedChangesBlocker = useBlocker(unsavedChanges);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});
  const [isDeleting, setIsDeleting] = useState(false);

  const [fonts, setFonts] = useState<Font[]>([
    { font_id: '', system_name: '' }
  ]);

  const [textField, setTextField] = useState<TextField>({
    text_field_id: '',
    display_name: '',
    display_name_2: null,
    system_name: '',
    default_font: 'client_choice',
    default_length: 0,
    required_by_default: false
  });

  const [formErrors, setFormErrors] = useState<TextFieldErrors>({
    system_name: false,
    display_name: false,
    display_name_2: false,
    default_length: false,
    default_font: false
  });

  const fetchTextField = useCallback(
    async (text_field_id: string) => {
      const { res: textField, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.textFields,
        requestBody: {
          text_field_id
        }
      });

      if (error) {
        return null;
      }

      return textField;
    },
    [handleTRPCRequest]
  );

  const fetchFonts = useCallback(async () => {
    setIsLoading(true);
    const trpcRequest = {
      method: TRPCMethodEnum.list,
      resourceType: TRPCResourceEnum.fonts,
      requestBody: {}
    };
    const { res: fonts } = await handleTRPCRequest(trpcRequest);

    const withClientChoice = [
      {
        font_id: 'client_choice',
        system_name: 'Client Choice'
      },
      ...fonts
    ];
    setFonts(withClientChoice);
    setIsLoading(false);
  }, [handleTRPCRequest]);

  const syncFromRouter = useCallback(async () => {
    const { state } = location;
    if (!state) {
      return;
    }

    const { textField, textFieldToCopy } = state;
    if (!textField && !textFieldToCopy) {
      return;
    }

    let textFieldToSync = textField;

    if (textFieldToCopy) {
      const textField = await fetchTextField(textFieldToCopy);
      if (textField) {
        textFieldToSync = duplicateTextField(textField);
      }
    }

    setTextField(textFieldToSync);
  }, [fetchTextField, location]);

  const syncFromApi = useCallback(async () => {
    if (params.text_field_id) {
      const textField = await fetchTextField(params.text_field_id);

      if (textField) {
        if (!textField.default_font) {
          textField.default_font = 'client_choice';
        }
        setTextField(textField);
      }
    }
  }, [params.text_field_id, fetchTextField]);

  const syncData = useCallback(async () => {
    setIsLoading(true);
    const textFieldData = create ? syncFromRouter() : syncFromApi();
    await textFieldData;
    setIsLoading(false);
  }, [create, syncFromApi, syncFromRouter]);

  useEffect(() => {
    syncData();
    fetchFonts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const handleSaveTextField = useCallback(async () => {
    setIsSaving(true);

    const toSave = { ...textField };
    if (create) {
      delete toSave.text_field_id;
    }

    const { errors: validationErrors } = validateSchemaAndNotifyErrors(
      TextFieldValidation.textFieldFormSchema,
      toSave
    );

    if (validationErrors) {
      setFormErrors(validationErrors as unknown as TextFieldErrors);
    } else {
      const { res, error } = await handleTRPCRequest({
        method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
        resourceType: TRPCResourceEnum.textFields,
        requestBody: toSave
      });

      if (!error) {
        navigate(`/text-fields/${res?.text_field_id}`, { replace: true });
      }
    }
    setUnsavedChanges(false);
    setIsSaving(false);
  }, [create, handleTRPCRequest, navigate, textField]);

  const handleConfirmedDeleteTextField = useCallback(async () => {
    setUnsavedChanges(false);
    setConfirmDelete(false);

    setUnsavedChanges(false);
    setConfirmDelete(false);
    setIsDeleting(true);
    if (textField.text_field_id) {
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.textFields,
        requestBody: { text_field_id }
      });

      if (res?.success === false) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      } else if (!error) {
        toast.success('Text Field deleted successfully!');
        navigate('/text-fields', { replace: true });
      }
      setIsDeleting(false);
    }
  }, [handleTRPCRequest, navigate, textField.text_field_id, text_field_id]);

  const handleDuplicateTextField = useCallback(() => {
    navigate('/text-fields/new', {
      state: { textFieldToCopy: text_field_id },
      replace: true
    });
    setUnsavedChanges(true);
  }, [navigate, text_field_id]);

  const handleUpdateTextField = useCallback(
    (key: string) =>
      (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | number
      ) => {
        let updatedValue;

        if (typeof event === 'number') {
          updatedValue = event;
        } else {
          switch (key) {
            case 'required_by_default':
              updatedValue = !textField[key];
              break;
            case TEXT_FIELD_FIELDS.display_name_2:
              // ensures that blank display_name_2 values are stored as null rather than an empty string. This is what the customer configurator expects.
              if (event.target.value === '') {
                updatedValue = null;
              } else {
                updatedValue = event.target.value;
              }
              break;
            default:
              updatedValue = event.target.value;
              break;
          }
        }

        setTextField({ ...textField, [key]: updatedValue });
        setFormErrors({ ...formErrors, [key]: null });
        setUnsavedChanges(true);
      },
    [formErrors, textField]
  );

  if (isLoading) {
    return <LoadingDiv />;
  }

  if (isDeleting) {
    return <LoadingModalComponent isOpen={isDeleting} message="Deleting..." />;
  }

  return (
    <>
      <div className="flex h-full flex-col justify-between">
        {create ? (
          <Title title="Create Text Field" />
        ) : (
          <Title title="Existing Text Field" />
        )}
        <SystemNameComponent
          create={create}
          systemName={textField.system_name}
          errors={{ system_name: formErrors.system_name }}
          requiredLabel="Required by default?"
          required={textField.required_by_default}
          handleRequiredChange={handleUpdateTextField(
            TEXT_FIELD_FIELDS.required_by_default
          )}
          onSystemNameChange={handleUpdateTextField(
            TEXT_FIELD_FIELDS.system_name
          )}
          onDelete={() => setConfirmDelete(true)}
          onDuplicate={handleDuplicateTextField}
        />
        <div className="flex gap-3">
          <div className="w-full flex-col">
            <DisplayName
              displayName={textField.display_name}
              displayNameError={formErrors.display_name}
              onDisplayNameChange={handleUpdateTextField(
                TEXT_FIELD_FIELDS.display_name
              )}
            />
            <DisplayName
              fieldName="Display Name 2 (optional)"
              displayName={textField.display_name_2 || ''}
              displayNameError={formErrors.display_name_2}
              onDisplayNameChange={handleUpdateTextField(
                TEXT_FIELD_FIELDS.display_name_2
              )}
            />
          </div>

          <div className="w-full">
            <NumberInput
              error={formErrors.default_length}
              contentBefore="Default Character Limit:"
              size={SizeEnum.small}
              min={0}
              step={1}
              onChange={event => {
                handleUpdateTextField(TEXT_FIELD_FIELDS.default_length)(event);
              }}
              className="mb-5 text-base font-semibold"
              value={textField.default_length}
              data-testid="default-length"
            />
          </div>

          <div className="w-full">
            <Select
              options={fonts.map(font => ({
                key: font.font_id,
                value: font.font_id,
                label: font.system_name
              }))}
              error={formErrors.default_font}
              value={textField.default_font || 'client_choice'}
              onChange={handleUpdateTextField(TEXT_FIELD_FIELDS.default_font)}
              size={SizeEnum.small}
              contentBefore="Default Font:"
            />
          </div>
        </div>
        <div className="grow" />
        <div className="flex gap-3">
          <BackButtonComponent destination="/text-fields" />
          <SaveButtonComponent
            isSaving={isSaving}
            onSave={handleSaveTextField}
          />
        </div>
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={textField.system_name}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={handleConfirmedDeleteTextField}
      />
      <CannotDeleteModalComponent
        entityName={textField?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
}
