interface ChevronProps {
  width?: string;
  height?: string;
  direction?: 'left' | 'right' | 'up' | 'down';
}
export function ChevronIcon({
  width = '1em',
  height = '1em',
  direction = 'right'
}: ChevronProps) {
  const style = {
    left: {
      transform: 'scaleX(-1)'
    },
    right: {},
    up: {
      transform: 'rotate(-90deg)'
    },
    down: {
      transform: 'rotate(90deg)'
    }
  }[direction];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 12.5L8.14537 21.5L6.5 19.917L14.2093 12.5L6.5 5.08299L8.14537 3.5L17.5 12.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
