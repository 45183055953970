import Colors from './Colors';
import { useStyleAndColor } from './StyleAndColorHooks';
import Styles from './Styles';

export default function StyleAndColor() {
  const { styles, palettes } = useStyleAndColor();

  return (
    <>
      {styles.length > 1 && <Styles />}
      {palettes.length > 1 && (
        <>
          <hr className="m-0 w-full border border-solid border-gray-300" />
          <Colors />
        </>
      )}
    </>
  );
}
