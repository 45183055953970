import { SearchIcon } from '../../components/icons/SearchIcon';
import { TextField } from '../../components/inputs/TextField';
import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Pager from '../../components/Pager';
import { closeSeeAll, selectLabelSlotId } from '../accordion/accordionSlice';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import {
  selectProductTag,
  selectSlots,
  selectStyle,
  setSlotField
} from '../item/store/itemSlice';
import { Group, Variant } from '../../types';
import { useGetProductQuery } from '../api/store/apiSlice';
import { orderBy } from 'lodash';
import SeeAllGrid from '../../components/grids/SeeAllGrid';
import GridElement from '../../components/grids/GridElement';

const DEFAULT_ICONS_TO_DISPLAY = 12;

export default function AllLabelVariants() {
  const dispatch = useConfiguratorDispatch();

  const productTag = useRootSelector(selectProductTag);
  const slots = useRootSelector(selectSlots);
  const labelSlotId = useRootSelector(selectLabelSlotId);

  const thisSlot = slots[labelSlotId];
  const selectedStyle = useRootSelector(selectStyle);
  const { data: product } = useGetProductQuery(productTag);

  const [variantsPage, setVariantsPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [totalPages, setTotalPages] = useState(0);

  const [variantSlice, setVariantSlice] = useState<Variant[]>([]);

  const { data, isSuccess, isError, error } = useGetProductQuery(productTag);

  const label = useMemo(() => {
    const labelId = thisSlot.labelId;
    return product?.labels?.find(l => l.label_id === labelId);
  }, [product?.labels, thisSlot.labelId]);

  const variants = useMemo(() => {
    const unsortedVariants =
      label?.variants?.filter(v => v.style_id === selectedStyle?.style_id) ??
      [];
    return orderBy(unsortedVariants, v => v.system_name.toLocaleLowerCase());
  }, [label?.variants, selectedStyle?.style_id]);

  const selected = variants.find(v => v.label_variant_id == thisSlot.variantId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const outerSelectedIcon = useMemo(() => selected, []);

  const groups = useMemo(() => {
    let permittedGroups: Group[] = [];
    const variantIds = new Set(variants.map(c => c.label_variant_id));
    if (data && selectedStyle) {
      permittedGroups = data?.filters?.filter(f => f.type === 'labelVariants');
      permittedGroups = permittedGroups.filter(
        group => group.entity_ids.filter(p => variantIds.has(p)).length
      );
    }
    if (permittedGroups?.length) {
      return [
        { display_name: 'All', group_id: 'all' },
        ...permittedGroups
      ] as Group[];
    } else {
      return null;
    }
  }, [selectedStyle, data, variants]);

  const [selectedGroup, setSelectedGroup] = useState<Group>();

  useEffect(() => {
    if (groups?.length) {
      setSelectedGroup(groups[0]);
    }
  }, [groups]);

  useEffect(() => {
    let groupedVariants;
    if (selectedGroup?.entity_ids) {
      groupedVariants = variants.filter(s =>
        selectedGroup.entity_ids.includes(s.label_variant_id)
      );
    } else {
      groupedVariants = variants;
    }
    const filteredVariants = groupedVariants.filter(s =>
      s.system_name.toLowerCase().includes(filter.toLowerCase())
    );

    const newTotalPages = Math.ceil(
      filteredVariants?.length / DEFAULT_ICONS_TO_DISPLAY
    );
    setTotalPages(newTotalPages);
    const newVariantsPages =
      Math.min(Math.max(0, newTotalPages - 1), variantsPage) || 0;
    setVariantsPage(newVariantsPages);

    const start = newVariantsPages * DEFAULT_ICONS_TO_DISPLAY;
    const end = start + DEFAULT_ICONS_TO_DISPLAY;
    setVariantSlice(filteredVariants?.slice(start, end) ?? []);
  }, [
    variants,
    variantsPage,
    filter,
    selectedGroup?.entity_ids,
    selectedStyle
  ]);

  const handleClick = (variant: Variant) => {
    dispatch(
      setSlotField({
        slotId: labelSlotId,
        field: 'variantId',
        value: variant.label_variant_id
      })
    );
  };

  return (
    <>
      <div className="my-2.5 flex flex-col gap-2.5 overflow-y-auto">
        <div className="px-2.5 py-1 text-center text-sm font-semibold">
          Select a Label Shape
        </div>
        <ul className="flex flex-wrap gap-1.5">
          {groups?.map(group => (
            <li
              key={group.group_id}
              onClick={() => setSelectedGroup(group)}
              className={clsx(
                'border-dark-gray rounded border border-solid px-2 py-1.5',
                {
                  'bg-navy text-white': selectedGroup == group
                }
              )}
            >
              {group.display_name}
            </li>
          ))}
        </ul>
        <TextField
          size="large"
          className="flex-1"
          placeholder="Search"
          contentAfter={<SearchIcon />}
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
        {isError && (
          <>
            <h1>There was an error loading Label Shapes</h1>{' '}
            {console.log(error)}
          </>
        )}
        {isSuccess && (
          <SeeAllGrid>
            {variantSlice.map(variant => (
              <GridElement
                key={variant.label_variant_id}
                display_name={variant.system_name}
                thumbnail_path={variant.thumbnail_path}
                selected={variant == selected}
                onClick={() => handleClick(variant)}
              />
            ))}
          </SeeAllGrid>
        )}
        <Pager
          size="large"
          totalPages={totalPages}
          currentPage={variantsPage}
          onBack={() => setVariantsPage(old => Math.max(0, old - 1))}
          onForward={() =>
            setVariantsPage(old => Math.min(old + 1, totalPages - 1))
          }
        />
        <div className="flex justify-stretch gap-2">
          <button
            className="border-primary text-primary border-1 grow rounded-full border-solid px-2.5 py-4 font-semibold"
            onClick={() => {
              outerSelectedIcon && handleClick(outerSelectedIcon);
              dispatch(closeSeeAll());
            }}
          >
            Back
          </button>
          <button
            className="bg-primary border-1 grow rounded-full border-solid px-2.5 py-4 font-semibold text-white"
            onClick={() => dispatch(closeSeeAll())}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
}
