import { useCallback, useMemo, useState } from 'react';

import { SearchBar } from '../../../../components/search-bar';
import { CheckmarkIcon, PlusIcon } from '../../../../components/icons';
import { ContainedButton, IconButton } from '../../../../components/buttons';
import { getHexFromTailwindConfig } from '../../../../utils';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { LoadingDiv } from '../../../../components/loading';

import { Product, UpsellOptions } from '../../types';
import { useProducts } from '../../hooks/useProducts';

import { UpsellSkuModalComponent } from './UpsellSkuModalComponent';

interface SearchUpsellProductsProps {
  currentProductId?: string;
  upsellProducts: UpsellOptions;
  onUpdateUpsells(updatedUpsellOptions: UpsellOptions): void;
}

export const SearchUpsellProducts = ({
  currentProductId,
  upsellProducts,
  onUpdateUpsells
}: SearchUpsellProductsProps) => {
  const [productCandidates, setProductCandidates] = useState<UpsellOptions>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { products, refetch } = useProducts();

  const [selectedCandidateId, setSelectedCandidateId] = useState<string>('');
  const [skuModalOpen, setSkuModalOpen] = useState<boolean>(false);
  const [newSku, setNewSku] = useState<string>('');

  const fetchCandidates = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      refetch(search);

      if (products) {
        const productCandidateState = products.reduce(
          (candidates: UpsellOptions, result: Product) => {
            const productId = result.product_id;

            if (productId && productId !== currentProductId) {
              const currentSelected = upsellProducts[productId];

              const currentCandidateState = productCandidates[productId] || {};
              const isSelectedPendingSave = currentCandidateState?.selected;

              const selected = !!(currentSelected || isSelectedPendingSave);

              candidates[productId] = {
                ...result,
                ...currentSelected,
                selected
              };
            }

            return candidates;
          },
          {} as UpsellOptions
        );

        setProductCandidates(productCandidateState);
      }
      setIsLoading(false);
    },
    [refetch, products, currentProductId, productCandidates, upsellProducts]
  );

  const openSkuModal = useCallback(
    (candidateId: string) => {
      setSelectedCandidateId(candidateId);
      setSkuModalOpen(true);
    },
    [setSelectedCandidateId, setSkuModalOpen]
  );

  const deselectCandidate = useCallback(
    (candidateId: string) => {
      const currentCandidateState = productCandidates[candidateId];
      setProductCandidates({
        ...productCandidates,
        [candidateId]: {
          ...currentCandidateState,
          selected: false,
          sku: ''
        }
      });
    },
    [productCandidates, setProductCandidates]
  );

  const selectCandidate = useCallback(() => {
    const currentCandidateState = productCandidates[selectedCandidateId];
    setProductCandidates({
      ...productCandidates,
      [selectedCandidateId]: {
        ...currentCandidateState,
        selected: true,
        sku: newSku
      }
    });
    setNewSku('');
  }, [productCandidates, setProductCandidates, selectedCandidateId, newSku]);

  const handleToggleCandidate = useCallback(
    (candidateId: string) => () => {
      setIsEditing(true);
      const currentCandidateState = productCandidates[candidateId];

      const beingSelected = !currentCandidateState.selected;

      beingSelected
        ? openSkuModal(candidateId)
        : deselectCandidate(candidateId);
    },
    [productCandidates, setIsEditing, deselectCandidate, openSkuModal]
  );

  const handleUpdateUpsellProduct = useCallback(() => {
    const updatedUpsells = Object.values(upsellProducts).reduce(
      (newProductUpsells: UpsellOptions, product: Product) => {
        const productId: string = product.product_id || '';
        const incomingCandidate = productCandidates[productId];

        // if the group registration was not modified or if it has been
        // explicitly selected again we keep it
        if (
          !incomingCandidate ||
          (incomingCandidate && incomingCandidate.selected)
        ) {
          newProductUpsells[productId] = product;
        }

        return newProductUpsells;
      },
      {} as UpsellOptions
    );

    // add all newly selected group members
    Object.keys(productCandidates).forEach((candidateId: string) => {
      const candidate = productCandidates[candidateId];
      if (candidate.selected && !updatedUpsells[candidateId]) {
        updatedUpsells[candidateId] = candidate;
      }
    });

    onUpdateUpsells(updatedUpsells);
    setProductCandidates({});
  }, [upsellProducts, productCandidates, onUpdateUpsells]);

  const handleCancel = useCallback(() => {
    setProductCandidates({});
  }, [setProductCandidates]);

  const productCandidateIds = useMemo(
    () => Object.keys(productCandidates),
    [productCandidates]
  );

  const ref = useOutsideClick(() => {
    if (!skuModalOpen) {
      handleCancel();
    }
  });

  const showMultiselect = useMemo(() => {
    return !skuModalOpen && (productCandidateIds.length || isLoading);
  }, [skuModalOpen, productCandidateIds, isLoading]);

  return (
    <div className="relative" ref={ref}>
      <SearchBar
        placeHolder="Upsell Products"
        classNames={['mb-0']}
        searchTerm={''}
        searchOnChange={true}
        onSearch={search => fetchCandidates(search)}
        overrideIcon={
          <div className="text-aqua">
            <PlusIcon />
          </div>
        }
      />

      {showMultiselect ? (
        <div className="absolute z-10 flex w-full flex-col rounded bg-white shadow">
          <div className="flex max-h-60 flex-col overflow-y-scroll">
            {productCandidateIds.length ? (
              productCandidateIds.map(candidateId => {
                const candidate = productCandidates[candidateId];

                return (
                  <div
                    key={candidateId}
                    className="align-center flex cursor-pointer flex-row justify-between p-2 shadow"
                    style={{
                      backgroundColor: candidate.selected
                        ? getHexFromTailwindConfig('Light Blue')
                        : ''
                    }}
                    onClick={handleToggleCandidate(candidateId)}
                  >
                    <div>
                      {candidate.system_name}
                      {candidate.selected ? `: ${candidate.sku}` : ''}
                    </div>
                    <IconButton
                      className="text-navy text-[24px]"
                      icon={
                        candidate.selected ? <CheckmarkIcon /> : <PlusIcon />
                      }
                      data-testid="search-result-button"
                    />
                  </div>
                );
              })
            ) : (
              <div className="m-2">
                <LoadingDiv />
              </div>
            )}
          </div>

          <div className="flex-row justify-center">
            <ContainedButton
              onClick={handleCancel}
              className="m-5"
              color="secondary"
            >
              Cancel
            </ContainedButton>
            {isEditing && (
              <ContainedButton
                className="m-5"
                onClick={handleUpdateUpsellProduct}
              >
                Save
              </ContainedButton>
            )}
          </div>
        </div>
      ) : null}
      <UpsellSkuModalComponent
        isOpen={skuModalOpen}
        sku={newSku}
        setSku={setNewSku}
        onCancel={() => {
          setNewSku('');
          setSkuModalOpen(false);
        }}
        onSave={() => {
          selectCandidate();
          setSkuModalOpen(false);
        }}
      />
    </div>
  );
};
