import { useCallback, useState } from 'react';
import { Font, FontCollection, create } from 'fontkit';
import { LoadFont, loadFont } from '../../fonts/utils';

const fonts: Map<string, Font | FontCollection> = new Map();

export function useFonts() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchFont = useCallback(async (font: LoadFont) => {
    const { url: fontFilePath } = font;
    try {
      if (fontFilePath) {
        setIsLoading(true);
        if (fonts.has(fontFilePath)) {
          return fonts.get(fontFilePath);
        }
        loadFont(font);
        const fontFile = await fetch(fontFilePath, {
          cache: 'no-store' // ios?!
        });
        const fontArrayBuffer = await fontFile.arrayBuffer();
        const fontObj = create(new Uint8Array(fontArrayBuffer) as Buffer);
        fonts.set(fontFilePath, fontObj);
        setIsLoading(false);
        return fontObj;
      }
    } catch (error) {
      console.error('Error fetching font');
    }
  }, []);

  return { isLoading, fetchFont };
}
