import { useCallback, useEffect, useState } from 'react';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { useTRPCRequest } from '../../../hooks';
import { useParams } from 'react-router-dom';
import Configurator from '../../../../../configurator-ui/src/features/app/Configurator';
import { configuratorStore } from '../../../../../configurator-ui/src/store';
import { Provider } from 'react-redux';
import { CartErrors } from '../../../../../configurator-ui/src/utils/getCartErrors';

type LabelSlot = {
  label_id: string;
  label_variant_id: string;
  material_id: string;
  slot_id: string;
};

type TextField = {
  text_field_id: string;
  value: string;
};

type CartItem = {
  cart_item_id: string;
  palette_id: string;
  product_id: string;
  font_id: string;
  icon_id: string;
  style_id: string;
  label_slots: LabelSlot[];
  text_fields: TextField[];
};

interface ItemProps {
  updateErrors?: (errors: CartErrors | undefined) => void;
  updateModified?: (mod: boolean) => void;
}
export const Item = ({ updateErrors, updateModified }: ItemProps) => {
  const { cart_item_id } = useParams();
  const { handleTRPCRequest } = useTRPCRequest();
  const [tag, setTag] = useState<string>();
  const [cartItem, setCartItem] = useState<CartItem>();

  const fetchOrder = useCallback(async () => {
    const { res: cartItem } = await handleTRPCRequest({
      method: TRPCMethodEnum.get,
      resourceType: TRPCResourceEnum.cartItems,
      requestBody: {
        cart_item_id: cart_item_id
      }
    });

    const { res: product } = await handleTRPCRequest({
      method: TRPCMethodEnum.get,
      resourceType: TRPCResourceEnum.products,
      requestBody: {
        product_id: cartItem.product_id
      }
    });
    setTag(product.tag);
    setCartItem(cartItem);
  }, [cart_item_id, handleTRPCRequest]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  if (!tag || !cartItem) {
    return <></>;
  }
  return (
    <Provider store={configuratorStore}>
      <Configurator
        admin={true}
        product_tag={tag}
        show={true}
        addToCartButton={false}
        state={{
          style_id: cartItem.style_id,
          palette_id: cartItem.palette_id,
          font_id: cartItem.font_id,
          icon_id: cartItem.icon_id,
          text_fields: cartItem.text_fields,
          label_slots: cartItem.label_slots
        }}
        updateErrors={updateErrors}
        updateModified={updateModified}
      />
    </Provider>
  );
};
