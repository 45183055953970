import { ChangeEvent, useCallback } from 'react';
import clsx from 'clsx';

import { Select } from '../../../components/inputs';
import { SizeEnum } from '../../../types';

import { Material } from '../../materials/types';

import { ProductTemplateSelections } from '../types';

const LEFT_ICON_OPTIONS = [
  { value: undefined, label: undefined },
  { value: 'iron', label: 'Iron' },
  { value: 'tag', label: 'Tagged' },
  { value: 'tagless', label: 'Tagless' },
  { value: 'water', label: 'Water' }
];

const COLOR_OPTIONS = [
  { value: undefined, label: undefined },
  { value: 'turquoise', label: 'Turquoise' },
  { value: 'coral', label: 'Coral' },
  { value: 'lemon', label: 'Lemon' }
];

const LEFT_TEXT_OPTIONS = [
  { value: undefined, label: undefined },
  { value: 'iron-on', label: 'Iron On' },
  { value: 'laundry-safe', label: 'Laundry Safe' },
  { value: 'waterproof', label: 'Waterproof' },
  { value: 'none', label: 'None' }
];

const MIDDLE_ICON_OPTIONS = [
  { value: undefined, label: undefined },
  { value: 'iron', label: 'Iron' },
  { value: 'tent', label: 'Tent' },
  { value: 'backpack', label: 'Backpack' },
  { value: 'house', label: 'House' },
  { value: 'sippy-cup', label: 'Sippy Cup' },
  { value: 'bottle', label: 'Bottle' },
  { value: 'shoe', label: 'Shoe' },
  { value: 'lunchbox', label: 'Lunchbox' },
  { value: 'tag', label: 'Tagged' },
  { value: 'tagless', label: 'Tagless' }
];

const ADDITIONAL_TEXT_OPTIONS = [
  { value: undefined, label: undefined },
  { value: 'unlaminated', label: 'Apply to clothing tag or size imprint' },
  { value: 'laminated', label: 'Apply to clothing tag' },
  { value: 'none', label: 'None' }
];

const FOOTER_OPTIONS = [
  { value: undefined, label: undefined },
  { value: 'iron-on', label: 'Do not Cut. Peel label off sheet...' },
  { value: 'clean-dry', label: 'Place labels onto a clean, dry surface...' },
  {
    value: 'clothing-care',
    label: 'Press and stick labels to a clothing care tag...'
  },
  { value: 'size-imprint', label: 'Place labels onto size imprint...' }
];

const INPUT_SIZE = SizeEnum.medium;
const INPUT_WIDTH = 'w-[350px]';
const INPUT_CLASSES = clsx(INPUT_WIDTH, 'truncate');

interface EditTemplateSelectionsProps {
  templateSelections: Partial<ProductTemplateSelections>;
  templateSelectionsList: Partial<ProductTemplateSelections>[];
  setTemplateSelectionsList: (
    templateSelectionsList: Partial<ProductTemplateSelections>[]
  ) => void;
  material: Material;
}

export function EditTemplateSelections({
  templateSelections,
  templateSelectionsList,
  setTemplateSelectionsList,
  material
}: EditTemplateSelectionsProps) {
  const handleSelectionChange = useCallback(
    (
      event: ChangeEvent<HTMLSelectElement>,
      key: keyof ProductTemplateSelections
    ) => {
      const { value } = event.target;
      const { material_id, for_clothing } = templateSelections;
      const updatedTemplateSelectionsList = templateSelectionsList.map(
        templateSelectionsItem => {
          const isMaterialMatch =
            templateSelectionsItem.material_id === material_id;

          const isClothingMatch =
            templateSelectionsItem.for_clothing === for_clothing;

          const isMatch = isMaterialMatch && isClothingMatch;

          return isMatch
            ? { ...templateSelectionsItem, [key]: value }
            : templateSelectionsItem;
        }
      );

      setTemplateSelectionsList(updatedTemplateSelectionsList);
    },
    [templateSelectionsList, setTemplateSelectionsList, templateSelections]
  );

  const handleLeftIconChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleSelectionChange(event, 'left_icon_id');
    },
    [handleSelectionChange]
  );

  const handleLeftColorChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleSelectionChange(event, 'left_print_key');
    },
    [handleSelectionChange]
  );

  const handleLeftTextChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleSelectionChange(event, 'left_text_id');
    },
    [handleSelectionChange]
  );

  const handleMiddleIconChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleSelectionChange(event, 'middle_icon_id');
    },
    [handleSelectionChange]
  );

  const handleMiddleColorChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleSelectionChange(event, 'middle_print_key');
    },
    [handleSelectionChange]
  );

  const handleAdditionalTextChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleSelectionChange(event, 'additional_text_id');
    },
    [handleSelectionChange]
  );

  const handleFooterChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      handleSelectionChange(event, 'footer_type_id');
    },
    [handleSelectionChange]
  );

  const title = templateSelections.for_clothing
    ? `${material.system_name} (For Clothing)`
    : material.system_name;

  const {
    left_icon_id,
    left_print_key,
    left_text_id,
    middle_icon_id,
    middle_print_key,
    additional_text_id,
    footer_type_id
  } = templateSelections;

  return (
    <div className="flex flex-col gap-2 pb-3">
      <h2>{title}</h2>
      <Select
        className={INPUT_CLASSES}
        size={INPUT_SIZE}
        contentBefore="Left Icon:"
        required
        options={LEFT_ICON_OPTIONS}
        value={left_icon_id}
        onChange={handleLeftIconChange}
      />

      <Select
        className={INPUT_CLASSES}
        size={INPUT_SIZE}
        contentBefore="Left Color:"
        required
        options={COLOR_OPTIONS}
        value={left_print_key}
        onChange={handleLeftColorChange}
      />

      <Select
        className={INPUT_CLASSES}
        size={INPUT_SIZE}
        contentBefore="Left Text:"
        required
        options={LEFT_TEXT_OPTIONS}
        value={left_text_id}
        onChange={handleLeftTextChange}
      />

      <Select
        className={INPUT_CLASSES}
        size={INPUT_SIZE}
        contentBefore="Middle Icon:"
        required
        options={MIDDLE_ICON_OPTIONS}
        value={middle_icon_id}
        onChange={handleMiddleIconChange}
      />

      <Select
        className={INPUT_CLASSES}
        size={INPUT_SIZE}
        contentBefore="Middle Color:"
        required
        options={COLOR_OPTIONS}
        value={middle_print_key}
        onChange={handleMiddleColorChange}
      />

      <Select
        className={INPUT_CLASSES}
        size={INPUT_SIZE}
        contentBefore="Additional Text:"
        required
        options={ADDITIONAL_TEXT_OPTIONS}
        value={additional_text_id}
        onChange={handleAdditionalTextChange}
      />

      <Select
        className={INPUT_CLASSES}
        size={INPUT_SIZE}
        contentBefore="Footer:"
        required
        options={FOOTER_OPTIONS}
        value={footer_type_id}
        onChange={handleFooterChange}
      />
    </div>
  );
}
