import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';

export function GeneratePdfsByOrder() {
  const params = useParams();
  const { handleTRPCRequest } = useTRPCRequest();

  const generatePdf = useCallback(async () => {
    await handleTRPCRequest({
      method: TRPCMethodEnum.generatePdfsByOrderId,
      resourceType: TRPCResourceEnum.pdfs,
      requestBody: {
        order_id: params.order_id
      }
    });
  }, [params.order_id, handleTRPCRequest]);

  useEffect(() => {
    generatePdf();
  }, [generatePdf]);

  return null;
}
