import { CSSProperties, ReactNode } from 'react';
import { useDragLayer } from 'react-dnd';
import { getItemStyles } from '.';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%'
};

/**
 * We need this because the labels are scaled and we need to scale the drag preview
 */
export const CustomDragLayer = ({ children }: { children: ReactNode }) => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(
          initialOffset,
          currentOffset,
          item.xTrans,
          item.yTrans
        )}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};
