import { Dispatch, SetStateAction } from 'react';

import { OutlinedButton } from '../../../components/buttons';
import { AppStates } from '../../../types';

interface BackToConfiguratorButtonProps {
  style?: React.CSSProperties;
  setAppState: Dispatch<SetStateAction<AppStates>>;
}

export function BackToConfiguratorButton({
  style,
  setAppState
}: BackToConfiguratorButtonProps) {
  const { CONFIGURING } = AppStates;
  return (
    <OutlinedButton
      style={style}
      className="mt-5 flex-1"
      onClick={() => setAppState(CONFIGURING)}
    >
      Back
    </OutlinedButton>
  );
}
