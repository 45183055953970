import { useCallback, useState } from 'react';
import { SystemNameComponent, TextField } from '../../../components/inputs';
import { useEffect } from 'react';
import { MATERIAL_FIELDS, Material, MaterialErrors } from '../types';
import MaterialValidation from '../validations';
import { validateSchemaAndNotifyErrors } from '../../../utils/validationUtils';
import { useTRPCRequest } from '../../../hooks/useTRPCRequest';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  LoadingModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';
import { useRawImageData } from '../../../hooks';
import { toast } from 'react-toastify';
import { Title } from '../../../components/Title';

const inputStyles = 'mb-5 grow text-base font-semibold';

interface MaterialDetailsProps {
  create?: boolean;
}

export function MaterialDetails({ create = false }: MaterialDetailsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { handleTRPCRequest } = useTRPCRequest();
  const { getRawImageData } = useRawImageData();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const unsavedChangesBlocker = useBlocker(unsavedChanges);

  const [material, setMaterial] = useState<Material>({
    display_name: '',
    system_name: '',
    description: '',
    upsell_title: '',
    thumbnail: ''
  });
  const [formErrors, setFormErrors] = useState<MaterialErrors>({
    display_name: false,
    system_name: false,
    description: false,
    upsell_title: false
  });

  const getThumbnailPath = useCallback(
    async (thumbnail_path: string) => {
      return await getRawImageData(thumbnail_path);
    },
    [getRawImageData]
  );

  const syncFromRouter = useCallback(async () => {
    setIsLoading(true);
    const materialToDuplicate = location?.state?.material;
    if (materialToDuplicate) {
      const rawImage = await getThumbnailPath(
        materialToDuplicate.thumbnail_path
      );
      const copiedMaterial = {
        ...materialToDuplicate,
        system_name: `${materialToDuplicate.system_name} - Copy`,
        thumbnail: rawImage
      };
      setMaterial(copiedMaterial);
    }

    setIsLoading(false);
  }, [getThumbnailPath, location?.state?.material]);

  const syncFromApi = useCallback(async () => {
    setIsLoading(true);
    if (params.material_id) {
      const { res: copiedMaterial, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.materials,
        requestBody: {
          material_id: params.material_id
        }
      });

      const rawImage = await getThumbnailPath(copiedMaterial.thumbnail_path);

      if (!error) {
        setMaterial({ ...copiedMaterial, thumbnail: rawImage });
      }
    }

    setIsLoading(false);
  }, [params.material_id, handleTRPCRequest, getThumbnailPath]);

  useEffect(() => {
    if (create) {
      syncFromRouter();
    } else {
      syncFromApi();
    }
    setUnsavedChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleUpdateMaterial = useCallback(
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setMaterial(oldMaterial => {
        const newMaterial = {
          ...oldMaterial,
          [key]: event.target.value
        };
        return newMaterial;
      });
      setFormErrors({ ...formErrors, [key]: null });
      setUnsavedChanges(true);
    },
    [formErrors, setMaterial, setFormErrors]
  );

  const handleSaveMaterial = useCallback(async () => {
    setIsSaving(true);

    const materialToSave = { ...material };
    delete materialToSave.thumbnail_path;
    if (create) {
      delete materialToSave.material_id;
    }

    const { errors: validationErrors, validatedSchema } =
      validateSchemaAndNotifyErrors(
        create
          ? MaterialValidation.createMaterialSchema
          : MaterialValidation.updateMaterialSchema,
        materialToSave
      );

    if (validationErrors) {
      setFormErrors(validationErrors as unknown as MaterialErrors);
    } else {
      const { res, error } = await handleTRPCRequest({
        method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
        resourceType: TRPCResourceEnum.materials,
        requestBody: validatedSchema
      });

      if (!error) {
        navigate(`/materials/${res?.material_id}`, { replace: true });
      }
      setUnsavedChanges(false);
    }
    setIsSaving(false);
  }, [create, material, setFormErrors, handleTRPCRequest, navigate]);

  const handleDeleteMaterial = useCallback(async () => {
    setUnsavedChanges(false);
    setConfirmDelete(false);
    setIsDeleting(true);
    if (material.material_id) {
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.materials,
        requestBody: { material_id: material.material_id }
      });
      if (res?.success === false) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      } else if (!error) {
        toast.success('Material deleted successfully!');
        navigate('/materials', { replace: true });
      }

      setIsDeleting(false);
    }
  }, [material.material_id, navigate, handleTRPCRequest]);

  const handleDuplicateMaterial = useCallback(() => {
    navigate(`/materials/new`, {
      state: { material },
      replace: true
    });
  }, [material, navigate]);

  if (isSaving) {
    return <LoadingModalComponent isOpen={isSaving} message="Saving..." />;
  }

  if (isDeleting) {
    return <LoadingModalComponent isOpen={isDeleting} message="Deleting..." />;
  }

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }

  return (
    <div className="flex h-full flex-col">
      {create ? (
        <Title title="Create Material" />
      ) : (
        <Title title="Existing Material" />
      )}
      <SystemNameComponent
        create={create}
        systemName={material.system_name}
        uploadButtonText={
          material.thumbnail_path?.length || material.thumbnail?.length
            ? 'Update'
            : 'Upload Thumbnail'
        }
        errors={formErrors}
        onSystemNameChange={handleUpdateMaterial(MATERIAL_FIELDS.system_name)}
        onThumbnailUpload={handleUpdateMaterial(MATERIAL_FIELDS.thumbnail)}
        thumbnailPath={material.thumbnail_path}
        onDuplicate={handleDuplicateMaterial}
        onDelete={() => setConfirmDelete(true)}
      />
      <div className="flex w-full space-x-2.5">
        <TextField
          error={formErrors.display_name}
          contentBefore="Display Name:"
          size="small"
          className={inputStyles}
          value={material.display_name}
          onChange={handleUpdateMaterial(MATERIAL_FIELDS.display_name)}
          data-testid={MATERIAL_FIELDS.display_name}
        />
        <TextField
          error={formErrors.upsell_title}
          contentBefore="Upsell Title:"
          size="small"
          className={inputStyles}
          value={material.upsell_title}
          onChange={handleUpdateMaterial(MATERIAL_FIELDS.upsell_title)}
          data-testid={MATERIAL_FIELDS.upsell_title}
        />
        <TextField
          error={formErrors.description}
          contentBefore="Description:"
          size="small"
          className={inputStyles}
          value={material.description}
          onChange={handleUpdateMaterial(MATERIAL_FIELDS.description)}
          data-testid={MATERIAL_FIELDS.description}
        />
      </div>

      <div className="grow" />

      <div className="mt-10 flex gap-3">
        <BackButtonComponent destination="/materials" />
        <SaveButtonComponent isSaving={isSaving} onSave={handleSaveMaterial} />
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={material.system_name}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={handleDeleteMaterial}
      />
      <CannotDeleteModalComponent
        entityName={material?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </div>
  );
}
