export interface Icon {
  icon_id?: string;
  display_name: string;
  system_name: string;
  thumbnail?: string;
  thumbnail_path?: string;
}

export interface IconErrors {
  display_name: boolean;
  system_name: boolean;
  icon_layers: boolean;
}

export enum ICON_FIELDS {
  display_name = 'display_name',
  system_name = 'system_name',
  icon_layers = 'icon_layers'
}

export interface IconLayer {
  icon_layer_id?: string;
  icon_id?: string;
  index: number;
  main_file_path?: string;
  print_key: string;
  svgText: string;
  created?: string | undefined;
  updated?: string | undefined;
}

export type IconType = Icon;
