const HIDDEN_CHARS = [
  '\u202A', // Left-to-Right Embedding
  '\u202B', // Right-to-Left Embedding
  '\u202C', // Pop Directional Formatting
  '\u202D', // Left-to-Right Override
  '\u202E', // Right-to-Left Override
  '\u200B', // Zero Width Space
  '\u200C', // Zero Width Non-Joiner
  '\u200D', // Zero Width Joiner
  '\u200E', // Left-to-Right Mark
  '\u200F', // Right-to-Left Mark
  '\uFEFF', // Zero Width No-Break Space
  '\u00AD', // Soft Hyphen
  '\u2002', // En Space
  '\u2003', // Em Space
  '\u2009', // Thin Space
  '\u2060', // Word Joiner
  '\u2063', // Invisible Separator
  '\uFFFC', // Object Replacement Character
  '\uFFFD', // Replacement Character
  '\u2028', // Line Separator
  '\u2029' // Paragraph Separator
];

export function removeHiddenChars(text: string): string {
  return HIDDEN_CHARS.reduce((str, char) => str.split(char).join(''), text);
}
