import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { isString } from 'lodash';

const initialState = {
  seeAll: false,
  activeStep: undefined,
  entity: undefined,
  stylePage: 0,
  colorPage: 0,
  fontPage: 0,
  fields: {} as { [key: string]: string },
  iconPage: 0,
  labelShapePage: 0,
  shoeShapePage: 0,
  labelSlotId: 0
};

export enum PAGE_TYPE {
  style,
  color,
  font,
  icon,
  labelShape,
  shoeShape
}
export const accordionSlice = createSlice({
  name: 'accordion',
  initialState,
  reducers: {
    openSeeAll: (state, action) => {
      state.seeAll = true;
      if (isString(action.payload)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state.entity = action.payload as any; // ?!
      } else {
        state.entity = action.payload.entity;
        state.labelSlotId = action.payload.labelSlotId;
      }
    },
    closeSeeAll: state => {
      state.seeAll = false;
      state.entity = undefined;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setPage: (state, action) => {
      const { page, pageNumber } = action.payload;
      if (page === PAGE_TYPE.style) {
        state.stylePage = pageNumber;
      } else if (page === PAGE_TYPE.color) {
        state.colorPage = pageNumber;
      } else if (page === PAGE_TYPE.font) {
        state.fontPage = pageNumber;
      } else if (page === PAGE_TYPE.icon) {
        state.iconPage = pageNumber;
      } else if (page === PAGE_TYPE.labelShape) {
        state.labelShapePage = pageNumber;
      } else if (page === PAGE_TYPE.shoeShape) {
        state.shoeShapePage = pageNumber;
      }
    },

    resetPages: state => {
      state.colorPage = 0;
      state.fontPage = 0;
      state.iconPage = 0;
      state.labelShapePage = 0;
      state.shoeShapePage = 0;
    },

    setTextField: (state, action) => {
      const { fieldId, text } = action.payload;
      state.fields[fieldId] = text;
    }
  }
});

export const {
  closeSeeAll,
  openSeeAll,
  setActiveStep,
  setPage,
  resetPages,
  setTextField
} = accordionSlice.actions;

export default accordionSlice.reducer;

export const selectAccordion = (state: RootState) => state.accordion;

export const selectStylePage = (state: RootState) => state.accordion.stylePage;

export const selectColorsPage = (state: RootState) => state.accordion.colorPage;

export const selectFontsPage = (state: RootState) => state.accordion.fontPage;

export const selectIconsPage = (state: RootState) => state.accordion.iconPage;

export const selectLabelShapePage = (state: RootState) =>
  state.accordion.labelShapePage;

export const selectEntity = (state: RootState) => state.accordion.entity;
export const selectLabelSlotId = (state: RootState) =>
  state.accordion.labelSlotId;

export const selectSeeAll = (state: RootState) => state.accordion.seeAll;
