import { useRootSelector } from '../../store';
import { useGetProductQuery } from '../api/store/apiSlice';
import {
  selectProductTag,
  selectSlots,
  selectStyle
} from '../item/store/itemSlice';
import Icons from './Icons';
import LabelVariants from './LabelVariants';
import React from 'react';
import { useIconsAndLabels } from './IconAndLabelHooks';

export default function IconAndLabel() {
  const productId = useRootSelector(selectProductTag);
  const selectedStyle = useRootSelector(selectStyle);
  const { data: product } = useGetProductQuery(productId);
  const slots = useRootSelector(selectSlots);

  const { showIcons, labelHasMultipleVariants } = useIconsAndLabels();

  return (
    <>
      {showIcons && <Icons />}

      {product?.label_slots?.map((labelSlot, index, labelSlots) => {
        const labelId = slots?.[labelSlot.product_label_slot_id]?.labelId;

        const label = product.labels.find(l => l.label_id === labelId);
        const isIterative = label?.iterative_style_ids?.includes(
          selectedStyle?.style_id ?? ''
        );
        // TODO: Not sure if we are supposed to only show slots with multiple variants
        const hasMultipleVariants = labelHasMultipleVariants[labelId || ''];

        if (hasMultipleVariants && !isIterative) {
          return (
            <React.Fragment key={index}>
              {(showIcons || index > 0) && (
                <hr className="m-0 w-full border border-solid border-gray-300" />
              )}
              <LabelVariants
                labelSlotId={labelSlot.product_label_slot_id}
                showLabelName={labelSlots.length > 1}
              />
            </React.Fragment>
          );
        }
      })}
    </>
  );
}
