import { Material } from '../../../types';

interface UpsellMaterialInputProps {
  added: boolean;
  material: Material;
  productId: string;
  setSelectedMaterial: (material: Material) => void;
  selectedMaterial: Material;
}

export function UpsellMaterialInput({
  added,
  material,
  productId,
  selectedMaterial,
  setSelectedMaterial
}: UpsellMaterialInputProps) {
  const radioGroupId = `material-${productId}`;
  const radioInputId = `${radioGroupId}-${material.material_id}`;
  return (
    <div key={material.material_id} className="flex items-center justify-end">
      <label htmlFor={radioInputId} className="mr-2">
        {material.upsell_title}
      </label>
      <input
        disabled={added}
        type="radio"
        id={radioInputId}
        name={radioGroupId}
        value={material.material_id}
        checked={selectedMaterial.material_id === material.material_id}
        onChange={() => setSelectedMaterial(material)}
      />
    </div>
  );
}
