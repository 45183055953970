import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';

export default function SeeAllGrid(props: PropsWithChildren) {
  const isMobileView = useMediaQuery({ maxWidth: 800 });

  return (
    <ol
      className={clsx('grid items-stretch gap-2.5', {
        'grid-cols-3 grid-rows-[66px_66px_66px_66px] ': !isMobileView,
        'auto-rows-[minmax(0,66px)] grid-cols-2 ': isMobileView
      })}
    >
      {props.children}
    </ol>
  );
}
