import { ContainedButton } from '../../../components/buttons';

export function ViewCartButton() {
  return (
    <ContainedButton
      size="large"
      onClick={() => {
        window.location.href = '/cart';
      }}
    >
      View Cart & Checkout
    </ContainedButton>
  );
}
