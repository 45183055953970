import { MouseEvent, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { useTRPCRequest } from '../../../hooks';
import { Title } from '../../../components/Title';
import { LoadingModalComponent } from '../../../components/modals';

import { OrderDetails, OrderItem } from '../types';
import DetailRow from '../components/DetailRow';

export const Order = () => {
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState<OrderDetails | undefined>();
  const [orderItems, setOrderItems] = useState<OrderItem[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const params = useParams();
  const { handleTRPCRequest } = useTRPCRequest();

  const fetchOrder = useCallback(async () => {
    setIsLoading(true);

    const response = await handleTRPCRequest({
      method: TRPCMethodEnum.get,
      resourceType: TRPCResourceEnum.orders,
      requestBody: {
        shopifyOrderNumber: params.shopifyOrderNumber
      }
    });
    if (response.error) {
      setIsLoading(false);
    }
    const { lineItems, order } = response.res;

    const orderDetails = {
      clientFullName: [order.customer.first_name, order.customer.last_name]
        .filter(a => a)
        .join(' '),
      createdAt: order.created_at,
      clientEmail: order.customer.email,
      shopifyOrderName: ''
    };

    setOrderDetails(orderDetails);
    setOrderItems(lineItems);
    setIsLoading(false);
  }, [params.shopifyOrderNumber, handleTRPCRequest]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  const handlePdfPreview = useCallback((pdfPaths: string[]) => {
    return (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      pdfPaths.forEach(pdfPath => downloadPdf(pdfPath));
    };
  }, []);

  const handleEditOrderItem = useCallback(
    (cart_item_id: string) => {
      return () => {
        navigate(
          `/orders/${encodeURIComponent(
            params.shopifyOrderNumber ?? ''
          )}/edit/${cart_item_id}`
        );
      };
    },
    [navigate, params.shopifyOrderNumber]
  );

  const printDate = () => {
    if (orderDetails?.createdAt) {
      const date = new Date(orderDetails?.createdAt);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const time = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric'
      });

      return `${month}/${day}/${year} ${time}`;
    }
  };

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }

  return (
    <>
      {orderDetails && (
        <div className="flex justify-between">
          <Title title={`${orderDetails.clientFullName} ${printDate()}`} />
          {orderDetails?.clientEmail ? (
            <div className="border-navy text-navy flex items-center rounded-full border-2 border-solid p-2">
              {orderDetails?.clientEmail}
            </div>
          ) : null}
        </div>
      )}

      {orderItems && (
        <DetailRow
          orderItems={orderItems}
          onEditClick={handleEditOrderItem}
          onPdfClick={handlePdfPreview}
        />
      )}
    </>
  );
};

async function downloadPdf(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const downloadUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = getFileName(url);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function getFileName(url: string) {
  const regex = /orders\/[^/]+\/(.*?)\.pdf/;
  const match = url.match(regex);
  return decodeURIComponent(match![1]) + '.pdf';
}
