import { useCallback } from 'react';

import { useConfiguratorDispatch } from '../../../store';
import { OutlinedButton } from '../../../components/buttons';

import { UpsellProduct } from '../types';
import { removeUpsell } from '../store/upsellsSlice';

interface RemoveUpsellButtonProps {
  upsell: UpsellProduct;
}

export function RemoveUpsellButton({ upsell }: RemoveUpsellButtonProps) {
  const dispatch = useConfiguratorDispatch();
  const handleClick = useCallback(() => {
    dispatch(removeUpsell(upsell));
  }, [dispatch, upsell]);

  return <OutlinedButton onClick={handleClick}>Remove</OutlinedButton>;
}
