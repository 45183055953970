import { IconButton } from '../../../../components/buttons';
import { Chip } from '../../../../components/chips';
import { CloseOutlineIcon } from '../../../../components/icons';

import { UpsellOptions } from '../../types';

interface UpsellChipsComponentProps {
  upsellProducts: UpsellOptions;
  onUpdateUpsells(updatedUpsells: UpsellOptions): void;
}

export const UpsellChipsComponent = ({
  upsellProducts,
  onUpdateUpsells
}: UpsellChipsComponentProps) => {
  const handleRemoveProductUpsells = (id?: string) => () => {
    if (!id) return;
    const updatedUpsellProducts = { ...upsellProducts };
    delete updatedUpsellProducts[id];
    onUpdateUpsells(updatedUpsellProducts);
  };

  const Chips = Object.values(upsellProducts).map(product => {
    const displayString = product.sku
      ? `${product.system_name}: ${product.sku}`
      : `${product.system_name}`;

    const RemoveUpsellButton = (
      <IconButton
        onClick={handleRemoveProductUpsells(product.product_id)}
        icon={<CloseOutlineIcon />}
      />
    );

    return (
      <Chip key={product.product_id} classes={['m-2']}>
        {displayString}
        {RemoveUpsellButton}
      </Chip>
    );
  });

  return <div>{Chips}</div>;
};
