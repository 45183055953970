import { SearchIcon } from '../../components/icons/SearchIcon';
import { TextField } from '../../components/inputs/TextField';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import Pager from '../../components/Pager';
import { closeSeeAll } from '../accordion/accordionSlice';
import { useRootSelector } from '../../store';
import {
  selectIcon,
  selectProductTag,
  selectStyle,
  setIcon
} from '../item/store/itemSlice';
import { Group, Icon } from '../../types';
import { useGetProductQuery } from '../api/store/apiSlice';
import SeeAllGrid from '../../components/grids/SeeAllGrid';
import GridElement from '../../components/grids/GridElement';

const DEFAULT_ICONS_TO_DISPLAY = 12;

const IconsSkeleton = (
  <ol
    role="status"
    className="grid animate-pulse grid-cols-3 grid-rows-[66px_66px_66px_66px] items-center gap-2.5 pb-2.5"
  >
    {Array.from({ length: DEFAULT_ICONS_TO_DISPLAY }).map((_, i) => (
      <li
        key={i}
        className="rounded-md border-2 border-solid border-transparent"
      >
        <div className="m-px flex rounded-md border border-solid border-gray-100 bg-gray-100">
          <div className="box-content h-[60px] w-[60px] rounded bg-gray-200 object-scale-down object-center" />
          <div></div>
        </div>
      </li>
    ))}
  </ol>
);

export default function AllIcons() {
  const productId = useRootSelector(selectProductTag);
  const selectedStyle = useRootSelector(selectStyle);

  const [iconsPage, setIconsPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const selected = useRootSelector(selectIcon);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const outerSelectedIcon = useMemo(() => selected, []);

  const [iconSlice, setIconSlice] = useState<Icon[]>([]);

  const { data, isLoading, isSuccess, isError, error } =
    useGetProductQuery(productId);

  const dispatch = useDispatch();

  const icons = useMemo(() => {
    return selectedStyle && data?.icons
      ? data?.icons.filter(i => selectedStyle.icon_ids.includes(i.icon_id))
      : [];
  }, [data?.icons, selectedStyle]);

  const groups = useMemo(() => {
    let permittedGroups: Group[] = [];
    const iconIds = new Set(icons.map(c => c.icon_id));
    if (data && selectedStyle) {
      permittedGroups = data?.filters?.filter(f => f.type === 'icons');
      permittedGroups = permittedGroups.filter(
        group => group.entity_ids.filter(p => iconIds.has(p)).length
      );
    }
    if (permittedGroups?.length) {
      return [
        { display_name: 'All', group_id: 'all' },
        ...permittedGroups
      ] as Group[];
    } else {
      return null;
    }
  }, [selectedStyle, data, icons]);
  const [selectedGroup, setSelectedGroup] = useState<Group>();

  useEffect(() => {
    if (groups?.length) {
      setSelectedGroup(groups[0]);
    }
  }, [groups]);

  useEffect(() => {
    let groupedIcons;
    if (selectedGroup?.entity_ids) {
      groupedIcons = icons.filter(s =>
        selectedGroup.entity_ids.includes(s.icon_id)
      );
    } else {
      groupedIcons = icons;
    }
    const filteredIcons = groupedIcons.filter(s =>
      s.display_name.toLowerCase().includes(filter.toLowerCase())
    );

    const newTotalPages = Math.ceil(
      filteredIcons?.length / DEFAULT_ICONS_TO_DISPLAY
    );
    setTotalPages(newTotalPages);
    const newIconsPage = Math.min(Math.max(0, newTotalPages - 1), iconsPage);
    setIconsPage(newIconsPage);

    setTotalPages(Math.ceil(filteredIcons?.length / DEFAULT_ICONS_TO_DISPLAY));
    const start = newIconsPage * DEFAULT_ICONS_TO_DISPLAY;
    const end = start + DEFAULT_ICONS_TO_DISPLAY;
    setIconSlice(filteredIcons?.slice(start, end) ?? []);
  }, [icons, iconsPage, filter, selectedGroup?.entity_ids, selectedStyle]);

  return (
    <>
      <div className="my-2.5 flex flex-col gap-2.5 overflow-y-auto">
        <div className="px-2.5 py-1 text-center text-sm font-semibold">
          Select an Icon
        </div>
        <ul className="flex flex-wrap gap-1.5">
          {groups?.map(group => (
            <li
              key={group.group_id}
              onClick={() => setSelectedGroup(group)}
              className={clsx(
                'border-dark-gray rounded border border-solid px-2 py-1.5',
                {
                  'bg-navy text-white': selectedGroup == group
                }
              )}
            >
              {group.display_name}
            </li>
          ))}
        </ul>
        <TextField
          size="large"
          className="flex-1"
          placeholder="Search"
          contentAfter={<SearchIcon />}
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
        {isLoading && IconsSkeleton}
        {isError && (
          <>
            <h1>There was an error loading colors</h1> {console.log(error)}
          </>
        )}
        {isSuccess && (
          <SeeAllGrid>
            {iconSlice.map(icon => (
              <GridElement
                key={icon.icon_id}
                display_name={icon.display_name}
                thumbnail_path={icon.thumbnail_path}
                selected={icon == selected}
                onClick={() => dispatch(setIcon(icon))}
              />
            ))}
          </SeeAllGrid>
        )}
        <Pager
          size="large"
          totalPages={totalPages}
          currentPage={iconsPage}
          onBack={() => setIconsPage(old => Math.max(0, old - 1))}
          onForward={() =>
            setIconsPage(old => Math.min(old + 1, totalPages - 1))
          }
        />
        <div className="flex justify-stretch gap-2">
          <button
            className="border-primary text-primary border-1 grow rounded-full border-solid px-2.5 py-4 font-semibold"
            onClick={() => {
              dispatch(setIcon(outerSelectedIcon));
              dispatch(closeSeeAll());
            }}
          >
            Back
          </button>
          <button
            className="bg-primary border-1 grow rounded-full border-solid px-2.5 py-4 font-semibold text-white"
            onClick={() => dispatch(closeSeeAll())}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
}
