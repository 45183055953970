import clsx from 'clsx';
import { UpsellOptions } from '../../types';
import { SearchUpsellProducts } from './SearchUpsellProducts';
import { UpsellChipsComponent } from './UpsellChipsComponent';

interface ManageUpsellProductsProps {
  currentProductId?: string;
  error: boolean;
  upsellOptions: UpsellOptions;
  onChange: (upsellOptions: UpsellOptions) => void;
}

export default function ManageUpsellProductsComponent({
  currentProductId,
  error,
  upsellOptions,
  onChange
}: ManageUpsellProductsProps) {
  const dynamicClasses = {
    'bg-light-blue': !error,
    'bg-blush': error
  };

  return (
    <div className={clsx(dynamicClasses, 'p-2')}>
      <SearchUpsellProducts
        currentProductId={currentProductId}
        upsellProducts={upsellOptions}
        onUpdateUpsells={onChange}
      />
      <UpsellChipsComponent
        upsellProducts={upsellOptions}
        onUpdateUpsells={onChange}
      />
    </div>
  );
}
