import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useBlocker
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import {
  EntityDependencies,
  RequestBody,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';

import { ManageIconLayersComponent } from '../components/ManageIconLayersComponent';
import IconValidation from '../validations';
import { formatIconRequestBody, duplicateIcon } from '../utils';
import { ICON_FIELDS, Icon, IconErrors, IconLayer } from '../types';
import { DisplayName, SystemNameComponent } from '../../../components/inputs';
import { validateSchemaAndNotifyErrors } from '../../../utils';
import { LoadingDiv } from '../../../components/loading';
import { useTRPCRequest } from '../../../hooks';
import { Title } from '../../../components/Title';
import { prepThumbnailForSave } from '../../../utils/createSvgElement';

interface IconDetailsProps {
  create?: boolean;
}

export const IconDetails = ({ create }: IconDetailsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const [icon, setIcon] = useState<Icon>({
    display_name: '',
    system_name: ''
  });

  const [iconLayers, setIconLayers] = useState<IconLayer[]>([]);

  const [formErrors, setFormErrors] = useState<IconErrors>({
    display_name: false,
    system_name: false,
    icon_layers: false
  });

  const [colorSlotsCount, setColorSlotsCount] = useState<number>(10);
  const [confirmIconDelete, setConfirmIconDelete] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const unsavedChangesBlocker = useBlocker(unsavedChanges);

  // used to create the thumbnail
  const svgRef = useRef<HTMLDivElement>(null);

  const { handleTRPCRequest } = useTRPCRequest();

  const syncColorSlotsCount = useCallback(async () => {
    const { res: blankPalette, error } = await handleTRPCRequest({
      method: TRPCMethodEnum.blank,
      resourceType: TRPCResourceEnum.palettes,
      requestBody: {},
      options: {
        disableSuccessNotification: true,
        customErrorMessage: 'An error occurred fetching color slots data'
      }
    });

    if (error) {
      return;
    }

    setColorSlotsCount(blankPalette.color_slots.length - 2);
  }, [handleTRPCRequest]);

  const fetchIcon = useCallback(
    async (icon_id: string) => {
      const { res: icon, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.icons,
        requestBody: {
          icon_id
        }
      });

      if (error) {
        navigate('/404');
        return {
          icon: null,
          icon_layers: null
        };
      }
      const { icon_layers, ...rest } = icon;

      return {
        icon: rest,
        icon_layers
      };
    },
    [navigate, handleTRPCRequest]
  );

  const syncFromRouter = useCallback(async () => {
    const { state } = location;
    if (!state) {
      return;
    }

    const { icon, iconToCopyId } = state;
    if (!icon && !iconToCopyId) {
      return;
    }

    let iconToSync = icon;

    if (iconToCopyId) {
      const { icon, icon_layers } = await fetchIcon(iconToCopyId);
      if (icon && icon_layers) {
        iconToSync = duplicateIcon(icon, icon_layers);
        toast.success('Icon copied successfully!');
      }
    }

    const { iconLayers, ...rest } = iconToSync;

    setIcon(rest);
    setIconLayers(iconLayers);
    setUnsavedChanges(true);
  }, [location, fetchIcon]);

  const syncFromApi = useCallback(async () => {
    if (params.icon_id) {
      const { icon, icon_layers } = await fetchIcon(params.icon_id);

      if (icon && icon_layers) {
        setIcon(icon);
        setIconLayers(icon_layers);
      }
    }
  }, [params.icon_id, fetchIcon]);

  const syncData = useCallback(async () => {
    setIsLoading(true);
    const colorSlotsData = syncColorSlotsCount();
    const iconData = create ? syncFromRouter() : syncFromApi();
    await Promise.all([colorSlotsData, iconData]);
    setIsLoading(false);
  }, [create, syncColorSlotsCount, syncFromRouter, syncFromApi]);

  useEffect(() => {
    syncData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const handleUpdateIcon = useCallback(
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setIcon({ ...icon, [key]: event.target.value });
      setFormErrors({ ...formErrors, [key]: null });
      setUnsavedChanges(true);
    },
    [icon, formErrors, setIcon, setFormErrors]
  );

  const handleDeleteIcon = useCallback(() => {
    setConfirmIconDelete(true);
  }, []);

  const handleConfirmedDeleteIcon = useCallback(async () => {
    if (icon.icon_id) {
      setConfirmIconDelete(false);
      const { res, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.delete,
        resourceType: TRPCResourceEnum.icons,
        requestBody: { icon_id: icon.icon_id }
      });

      if (!error && res.success) {
        toast.success('Icon deleted successfully!');
        setUnsavedChanges(false);
        navigate('/icons');
      }

      if (!res?.success) {
        setShowCannotDeleteModal(true);
        setDependencies(res.relatedEntities);
      }
    }
  }, [icon.icon_id, navigate, handleTRPCRequest]);

  const handleDuplicateIcon = useCallback(() => {
    setUnsavedChanges(true);
    navigate(`/icons/new`, { state: { iconToCopyId: icon.icon_id } });
  }, [icon.icon_id, navigate]);

  const handleUpdateIconLayers = useCallback(
    (updatedIconLayers: IconLayer[]) => {
      setIconLayers(updatedIconLayers);
      setFormErrors(oldFormErrors => ({
        ...oldFormErrors,
        icon_layers: false
      }));
      setUnsavedChanges(true);
    },
    [setIconLayers, setFormErrors]
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSaveIcon = useCallback(async () => {
    setIsSaving(true);

    const { errors: validationErrors } = validateSchemaAndNotifyErrors(
      IconValidation.iconFormSchema,
      {
        ...icon,
        icon_layers: iconLayers.map(layer => {
          delete layer.created;
          delete layer.updated;
          return layer;
        })
      }
    );

    if (validationErrors) {
      setFormErrors(validationErrors as unknown as IconErrors);
    } else {
      const thumbnail = prepThumbnailForSave(svgRef);

      const { res, error } = await handleTRPCRequest({
        method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
        resourceType: TRPCResourceEnum.icons,
        requestBody: {
          ...(formatIconRequestBody(
            Boolean(create),
            icon,
            iconLayers
          ) as RequestBody),
          thumbnail
        }
      });

      if (!error) {
        setUnsavedChanges(false);
        navigate(`/icons/${res?.icon_id}`, { replace: true });
      }
    }

    setIsSaving(false);
  }, [create, icon, iconLayers, setFormErrors, handleTRPCRequest, navigate]);

  if (isLoading) {
    return <LoadingDiv />;
  }

  return (
    <>
      {create ? <Title title="Create Icon" /> : <Title title="Existing Icon" />}
      <div>
        <SystemNameComponent
          create={create}
          systemName={icon.system_name}
          errors={formErrors}
          onSystemNameChange={handleUpdateIcon(ICON_FIELDS.system_name)}
          onDuplicate={handleDuplicateIcon}
          onDelete={handleDeleteIcon}
        />
        <div className="flex">
          <div className="flex-1 p-4">
            <DisplayName
              displayName={icon.display_name}
              displayNameError={formErrors.display_name}
              onDisplayNameChange={handleUpdateIcon(ICON_FIELDS.display_name)}
            />
          </div>

          <div className="flex-1 p-4">
            <ManageIconLayersComponent
              error={formErrors.icon_layers}
              svgRef={svgRef}
              iconLayers={iconLayers}
              colorSlotsCount={colorSlotsCount}
              onUpdateIconLayers={handleUpdateIconLayers}
            />
          </div>
        </div>

        <div className="mt-10 flex gap-3">
          <BackButtonComponent destination="/icons" />
          <SaveButtonComponent isSaving={isSaving} onSave={handleSaveIcon} />
        </div>
      </div>
      <UnsavedChangesModalComponent
        unsavedChanges={unsavedChanges}
        unsavedChangesBlocker={unsavedChangesBlocker}
      />
      <ConfirmDeleteModalComponent
        isOpen={confirmIconDelete}
        onConfirm={handleConfirmedDeleteIcon}
        onCancel={() => setConfirmIconDelete(false)}
        entityName={icon.system_name}
      />
      <CannotDeleteModalComponent
        entityName={icon?.system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
    </>
  );
};
