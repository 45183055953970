import { UpsellMaterialInput } from './UpsellMaterialInput';
import { UpsellProduct } from '../types';
import { Material } from '../../../types';

interface UpsellMaterialSelectorProps {
  added: boolean;
  upsell: UpsellProduct;
  selectedMaterial: Material;
  setSelectedMaterial: (material: Material) => void;
}

export function UpsellMaterialSelector({
  added,
  upsell,
  selectedMaterial,
  setSelectedMaterial
}: UpsellMaterialSelectorProps) {
  if (upsell.materials.length === 1) {
    return null;
  }
  return (
    <div className="flex-1">
      {upsell.materials.map(material => (
        <UpsellMaterialInput
          added={added}
          key={material.material_id}
          selectedMaterial={selectedMaterial}
          setSelectedMaterial={setSelectedMaterial}
          material={material}
          productId={upsell.product_id}
        />
      ))}
    </div>
  );
}
