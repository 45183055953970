import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { PlusIcon } from '../../../components/icons';
import { ContainedButton } from '../../../components/buttons';

import { Style } from '../../styles/types';
import { DropdownOption } from '../../labels/components/settings/DropdownOption';
import { useStyles } from '../../styles/routes/hooks';

interface MultiSelectProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  allStyles?: boolean;
  onStylesListUpdate(styleIDList: string[]): void;
  error: boolean;
  value: string[];
}

export function MultiSelect({
  isOpen,
  setIsOpen,
  allStyles,
  onStylesListUpdate,
  error,
  value
}: MultiSelectProps) {
  const { styles: fetchedStyles } = useStyles();
  const [selectedList, setSelectedList] = useState<Style[]>([]);
  const styles = useMemo(
    () => [
      {
        style_id: 'all',
        system_name: 'All',
        display_name: 'All'
      },
      ...fetchedStyles
    ],
    [fetchedStyles]
  );

  useEffect(() => {
    let filteredStyles = styles.filter(style => {
      return style.style_id && value.includes(style.style_id);
    });
    if (allStyles) {
      filteredStyles = [
        {
          style_id: 'all',
          system_name: 'All',
          display_name: 'All'
        }
      ];
    }
    setSelectedList(filteredStyles);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedStyles]);

  const styleNames = useMemo(
    () => new Map(styles.map(s => [s.style_id, s.display_name])),
    [styles]
  );
  useEffect(() => {
    if (fetchedStyles.length) {
      onStylesListUpdate(selectedList.map(style => style.style_id as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  return (
    <div className="w-1/3">
      <button
        className={clsx(
          'text-input-shadow flex h-[2.5rem] w-full items-center justify-between gap-4 overflow-hidden rounded-lg p-3',
          error && 'border-error border-2'
        )}
        onClick={() => {
          if (!styles?.length) {
            return;
          }
          setIsOpen(true);
        }}
      >
        {selectedList?.length
          ? selectedList.some(style => style.style_id == 'all')
            ? 'All Styles'
            : selectedList.length
          : 'Select'}
        {selectedList?.length === 0 ||
        selectedList.some(style => style.style_id == 'all')
          ? ''
          : selectedList?.length && selectedList?.length > 1
          ? ' Styles'
          : ' Style'}
        <div className="text-aqua">
          <PlusIcon />
        </div>
      </button>
      {isOpen && (
        <div className="relative w-full">
          <div className="text-input-shadow absolute top-2 z-10 flex w-full flex-col rounded-lg bg-white">
            <div className="flex max-h-[40vh] w-full flex-col overflow-y-scroll">
              {styles.map(style => {
                const styleName = styleNames.get(style.style_id) ?? 'Unknown';
                const id = style.style_id ?? '';
                return (
                  <DropdownOption
                    key={id}
                    selected={selectedList.some(style => style.style_id === id)}
                    onClick={() => {
                      if (
                        !selectedList.some(s => s.style_id === style.style_id)
                      ) {
                        if (style.style_id === 'all') {
                          setSelectedList([
                            {
                              style_id: 'all',
                              system_name: 'All',
                              display_name: 'All'
                            }
                          ]);
                        } else {
                          setSelectedList(prevSelectedList => [
                            ...prevSelectedList.filter(
                              s => s.style_id !== 'all'
                            ),
                            style
                          ]);
                        }
                      } else {
                        if (style.style_id === 'all') {
                          setSelectedList([]);
                        } else {
                          setSelectedList(prevSelectedList =>
                            prevSelectedList.filter(
                              s => s.style_id !== style.style_id
                            )
                          );
                        }
                      }
                    }}
                  >
                    {styleName}
                  </DropdownOption>
                );
              })}
            </div>

            <div className="flex justify-around p-3">
              <ContainedButton
                className="w-[45%]"
                color="secondary"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </ContainedButton>
              <ContainedButton
                className="w-[45%]"
                onClick={() => setIsOpen(false)}
              >
                Done
              </ContainedButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
