import { useCallback, useEffect, useState } from 'react';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { toast } from 'react-toastify';
import { Product } from '../types';

export function useProducts(search?: string) {
  const { handleTRPCRequest } = useTRPCRequest();

  const [products, setProducts] = useState<Product[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchProducts = useCallback(
    async (search?: string) => {
      setIsLoading(true);
      const trpcRequest = {
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.products,
        requestBody: {}
      };
      if (search) {
        trpcRequest.requestBody = { keyword: search };
      }
      const { res: products, error } = await handleTRPCRequest(trpcRequest);

      if (error) {
        toast.error('Error fetching products');
      }
      setProducts(products as Product[]);
      setIsLoading(false);
    },
    [handleTRPCRequest]
  );

  useEffect(() => {
    fetchProducts(search ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return { products, isLoading, refetch: fetchProducts };
}
