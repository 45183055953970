import { ProductTemplateSelections } from '../types';
import { useMaterials } from '../../materials/routes/hooks';

import { EditTemplateSelections } from './EditTemplateSelections';

interface TemplateSelectionsProps {
  templateSelectionsList: Partial<ProductTemplateSelections>[];
  setTemplateSelectionsList: (
    templateSelectionsList: Partial<ProductTemplateSelections>[]
  ) => void;
}

export function TemplateSelections({
  templateSelectionsList,
  setTemplateSelectionsList
}: TemplateSelectionsProps) {
  const { materials } = useMaterials(undefined, true);

  return (
    <div>
      <h1 className="pb-3 pt-3">PDF Elements</h1>
      <div className="flex flex-wrap gap-5">
        {templateSelectionsList.map(templateSelections => {
          const material = materials.find(
            material => material.material_id === templateSelections.material_id
          );

          if (!material) return null;

          const key = templateSelections.for_clothing
            ? `${templateSelections.material_id}-clothing`
            : templateSelections.material_id;

          return (
            <EditTemplateSelections
              key={key}
              templateSelections={templateSelections}
              templateSelectionsList={templateSelectionsList}
              setTemplateSelectionsList={setTemplateSelectionsList}
              material={material}
            />
          );
        })}
      </div>
    </div>
  );
}
