import { getFields } from '../features/fonts/TextAndFont.hooks';
import { ItemProperties, Product } from '../types';

export type CartErrors = {
  product?: string;
  style?: string;
  palette?: string;
  font?: string;
  icon?: string;
  clothingType?: string;
  fields?: Record<string, string>;
};
export const getCartErrors = (
  item: ItemProperties,
  product: Product
): CartErrors | undefined => {
  const errors: CartErrors = {};

  if (!item.productId) {
    errors.product = 'Product ID required';
  }
  if (!item.style?.style_id) {
    errors.style = 'Style required';
  }
  if (!item.palette?.palette_id) {
    errors.palette = 'Palette required';
  }
  if (!item.font?.font_id) {
    errors.font = 'Font required';
  }
  if (!item.icon?.icon_id) {
    errors.icon = 'Icon required';
  }

  const fields = getFields(
    product.labels,
    item.slots,
    item.style?.style_id || ''
  );
  const requiredFields = new Map(
    fields
      .filter(field => field.is_required)
      .map(field => {
        let display_name = field.display_name;

        if (field.display_name_2) {
          display_name = `${display_name} or ${field.display_name_2}`;
        }

        return [field.text_field_id, display_name];
      })
  );
  for (const [fieldId, fieldName] of requiredFields.entries()) {
    let fieldValue: string | undefined = item.fields[fieldId]?.value;

    // handle text fields with 2 display names
    if (fieldValue?.includes('\n')) {
      fieldValue = fieldValue
        .trimEnd()
        .split('\n')
        .find(line => line.trim());
    }

    if (!fieldValue || !fieldValue.length) {
      if (!errors.fields) {
        errors.fields = {};
      }
      errors.fields[fieldId] = `${fieldName} is required`;
    }
  }
  if (!Object.keys(errors).length) {
    return;
  }

  return errors;
};
