import { Dispatch, SetStateAction, useCallback } from 'react';

import { ContainedButton } from '../../../components/buttons';
import { AppStates } from '../../../types';

interface ShowUpsellsButtonProps {
  isReadyForCart: boolean;
  buttonText?: string;
  setAppState: Dispatch<SetStateAction<AppStates>>;
  onClick?: () => void;
}

export function ShowUpsellsButton({
  isReadyForCart,
  setAppState,
  onClick,
  buttonText = 'Add To Cart'
}: ShowUpsellsButtonProps) {
  const handleClick = useCallback(() => {
    onClick?.();
    if (isReadyForCart) {
      setAppState(AppStates.SHOW_UPSELLS);
    }
  }, [setAppState, isReadyForCart, onClick]);

  return (
    <ContainedButton size="large" className="mt-5" onClick={handleClick}>
      {buttonText}
    </ContainedButton>
  );
}
