import { ReactNode, useEffect, useRef, useState } from 'react';

import { Minus, Plus } from '../../components/icons';
import clsx from 'clsx';

export interface StepAccordionProps {
  item?: ReactNode;
  header: string;
  error?: boolean;
  children?: ReactNode;
  selected: boolean;
  onChange: () => void;
}

export function StepAccordion({
  item,
  header,
  error,
  children,
  selected,
  onChange
}: StepAccordionProps) {
  const id = header;
  const button = useRef<HTMLInputElement>(null);
  const panel = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(panel.current?.scrollHeight);
  const open = selected;

  useEffect(() => {
    const elem = panel.current;
    // `elem` is populated before the `useEffect` runs https://stackoverflow.com/a/75088534
    if (!elem) {
      return;
    }
    setHeight(elem.scrollHeight);
    const observer = new ResizeObserver(() => {
      setHeight(elem.scrollHeight);
    });
    observer.observe(elem);
    return () => observer.disconnect();
  }, []);

  return (
    <label
      className={clsx('h-full w-full p-0', !open && 'cursor-pointer')}
      htmlFor={id}
    >
      <input
        type="radio"
        ref={button}
        name={'step_accordion'}
        checked={open}
        className="hidden"
        id={id}
        onChange={onChange}
      />
      <div
        className={clsx(
          'flex min-h-12 items-center justify-between pr-3 font-semibold transition-colors',
          error && 'bg-error/10'
        )}
      >
        <div className={clsx('flex items-center', open && 'text-primary')}>
          {item ?? (
            <div className="box-border flex h-12 w-12 justify-center py-1">
              <div className="bg-primary h-full w-2 rounded-full" />
            </div>
          )}
          {header}
        </div>
        {children && (open ? <Minus /> : <Plus />)}
      </div>
      <div
        className="m-0 w-full overflow-hidden transition-all"
        style={{
          height: open ? height : 0
        }}
      >
        <div className="m-0 w-full" ref={panel}>
          {children}
        </div>
      </div>
    </label>
  );
}
