import { IconButton, IconButtonProps } from '..';
import { ChevronIcon } from '../../icons';

interface SearchButtonProps extends Omit<IconButtonProps, 'icon'> {}

export function ChevronUpButton(props: SearchButtonProps) {
  return (
    <IconButton
      {...props}
      icon={<ChevronIcon direction="up" width="2em" height="2em" />}
    />
  );
}
