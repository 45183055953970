import clsx from 'clsx';

export interface BaseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'x-small' | 'small' | 'medium' | 'large';
}

export function BaseButton(props: BaseButtonProps) {
  const {
    className,
    children,
    size = 'medium',
    type = 'button',
    ...rest
  } = props;

  const baseClassList = [
    'inline-flex',
    'shrink-0',
    'grow-0',
    'items-center',
    'align-middle',
    'justify-center',
    'gap-[16px]',
    'transition-all',
    'disabled:cursor-not-allowed'
  ];

  const sizeClasses = {
    'p-[0px]': size === 'x-small',
    'py-[0px]': size === 'small',
    'py-[5px]': size === 'medium',
    'py-[11px]': size === 'large'
  };

  return (
    <button
      className={clsx(baseClassList, sizeClasses, className)}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
}
