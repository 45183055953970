export function DropdownArrow() {
  return (
    <span
      style={{
        content: '',
        display: 'inline-block',
        marginLeft: '0.5em',
        verticalAlign: '0.09em',
        borderTop: '0.42em solid',
        borderRight: '0.32em solid transparent',
        borderLeft: '0.32em solid transparent'
      }}
    />
  );
}
