import { toast } from 'react-toastify';

import {
  ContainedButton,
  TextButton,
  IconButton,
  OutlinedButton,
  TabButton
} from '../../components/buttons';

import {
  AlertIcon,
  AngleIcon,
  CalendarIcon,
  CartIcon,
  ChecklistIcon,
  CheckmarkIcon,
  ChevronRightIcon,
  CloseIcon,
  CloseOutlineIcon,
  DeleteIcon,
  DragIcon,
  DuplicateIcon,
  MinusIcon,
  PackIcon,
  PlusIcon,
  PreviewIcon,
  PrintDisabledIcon,
  SearchIcon,
  TypeSizeIcon,
  UploadIcon,
  UserIcon,
  ZoomInIcon,
  ZoomOutIcon
} from '../../components/icons';

import { ColorSample } from '../../components/color-samples';

import {
  Checkbox,
  TextField,
  RadioButton,
  Toggle,
  Select
} from '../../components/inputs';

import { Chip } from '../../components/chips';

import { Tooltip } from '../../components/tooltips';

import { useAuth } from '@clerk/clerk-react';
import { SizeEnum } from '../../types';

// temporary element for displaying components
function DisplayWrapper(props: { children: React.ReactNode; gap?: string }) {
  const { children, gap = '100px' } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        flexWrap: 'wrap',
        gap,
        marginBottom: '50px'
      }}
    >
      {children}
    </div>
  );
}

function onClick() {
  alert('Clicked!');
}

export default function ComponentExamples() {
  const auth = useAuth();
  return (
    <>
      <h1 className="large-header">Component Examples</h1>
      <br />

      <ContainedButton
        onClick={async () => {
          try {
            const token = await auth.getToken();
            await navigator.clipboard.writeText(token as string);
            toast.success('Token copied to clipboard!');
          } catch (error) {
            console.error(error);
            toast.error('Error copying token to clipboard');
          }
        }}
      >
        Copy Token to Clipboard
      </ContainedButton>
      <br />
      <h2 className="large-header">Typography</h2>
      <DisplayWrapper>
        <div>
          <h1>Poppins Heading 1</h1>
          <h2>Poppins Heading 2</h2>
          <h3>Poppins Heading 3</h3>
          <h4>Poppins Heading 4</h4>
          <h5>Poppins Heading 5</h5>
        </div>
        <div>
          <p className="body-1">Poppins Body 1</p>
          <p className="body-2">Poppins Body 2</p>
          <p className="body-2 font-bold">Poppins Body 2 Bold</p>
          <p className="body-2 italic">Poppins Body 2 Italic</p>
          <p className="body-3">Poppins Body 3</p>
          <p className="body-4">Poppins Body 4</p>
          <p className="body-5">Poppins Body 5</p>
        </div>
      </DisplayWrapper>

      <h2 className="large-header">Colors</h2>
      <DisplayWrapper gap="25px">
        <ColorSample color="Navy" />
        <ColorSample color="Turquoise" />
        <ColorSample color="Aqua" />
        <ColorSample color="Lemon" />
        <ColorSample color="Coral" />
        <ColorSample color="Blush" />
        <ColorSample color="Light Gray" />
        <ColorSample color="Dark Gray" />
        <ColorSample color="Light Blue" />
        <ColorSample color="Cream" />
        <ColorSample color="Light Purple" />
      </DisplayWrapper>

      <h2 className="large-header">Icons</h2>
      <DisplayWrapper gap="20px">
        <AlertIcon />
        <AngleIcon />
        <CalendarIcon />
        <CartIcon />
        <ChecklistIcon />
        <CheckmarkIcon />
        <ChevronRightIcon />
        <CloseIcon />
        <CloseOutlineIcon />
        <DeleteIcon />
        <DragIcon />
        <DuplicateIcon />
        <MinusIcon />
        <PackIcon />
        <PlusIcon />
        <PreviewIcon />
        <PrintDisabledIcon />
        <SearchIcon />
        <TypeSizeIcon />
        <UploadIcon />
        <UserIcon />
        <ZoomInIcon />
        <ZoomOutIcon />
      </DisplayWrapper>

      <h2 className="large-header">Buttons</h2>
      <h3 className="large-header">Contained Buttons</h3>
      <DisplayWrapper>
        <ContainedButton onClick={onClick}>Primary</ContainedButton>
        <ContainedButton color="secondary" onClick={onClick}>
          Secondary
        </ContainedButton>
        <ContainedButton onClick={onClick} disabled>
          Disabled
        </ContainedButton>
        <ContainedButton onClick={onClick} size={SizeEnum.small}>
          Small
        </ContainedButton>
        <ContainedButton onClick={onClick} size={SizeEnum.large}>
          Large
        </ContainedButton>
        <ContainedButton onClick={onClick}>
          <CheckmarkIcon />
          Left Icon
        </ContainedButton>

        <ContainedButton onClick={onClick}>
          Right Icon
          <CheckmarkIcon />
        </ContainedButton>

        <ContainedButton onClick={onClick}>
          <CheckmarkIcon />
          Both Icon
          <CheckmarkIcon />
        </ContainedButton>
      </DisplayWrapper>
      <h3 className="large-header">Outlined Buttons</h3>
      <DisplayWrapper>
        <OutlinedButton onClick={onClick}>Primary</OutlinedButton>
        <OutlinedButton onClick={onClick} disabled>
          Disabled
        </OutlinedButton>
        <OutlinedButton onClick={onClick} size={SizeEnum.small}>
          Small
        </OutlinedButton>
        <OutlinedButton onClick={onClick} size={SizeEnum.large}>
          Large
        </OutlinedButton>
        <OutlinedButton onClick={onClick}>
          <CheckmarkIcon />
          Left Icon
        </OutlinedButton>

        <OutlinedButton onClick={onClick}>
          Right Icon
          <CheckmarkIcon />
        </OutlinedButton>

        <OutlinedButton onClick={onClick}>
          <CheckmarkIcon />
          Both Icon
          <CheckmarkIcon />
        </OutlinedButton>
      </DisplayWrapper>

      <h3 className="large-header">Text Buttons</h3>
      <DisplayWrapper>
        <TextButton onClick={onClick} text="Text Button" />
        <TextButton onClick={onClick} text="Disabled Text Button" disabled />
      </DisplayWrapper>

      <h3 className="large-header">Icon buttons</h3>
      <DisplayWrapper>
        <IconButton onClick={onClick} icon={<SearchIcon />} />
        <IconButton onClick={onClick} icon={<SearchIcon />} disabled />
      </DisplayWrapper>

      <h2 className="large-header">Inputs</h2>
      <h3 className="large-header">Text Inputs</h3>
      <DisplayWrapper gap="10px">
        <TextField placeholder="Text Field" />
        <TextField placeholder="Small Text Field" size="small" />
        <TextField placeholder="Disabled Text Field" disabled />
        <TextField
          placeholder="Disabled Small Text Field"
          disabled
          size="small"
        />
        <TextField
          placeholder="Text Field with Icon After"
          contentAfter={<SearchIcon />}
        />
        <TextField
          placeholder="Text Field with Icon Before"
          contentBefore={<SearchIcon />}
        />
        <TextField
          placeholder="Text Field with Button After"
          contentAfter={
            <ContainedButton size={SizeEnum.large}>Button</ContainedButton>
          }
        />
        <TextField
          placeholder="Text Field with Button Before"
          contentBefore={
            <ContainedButton size={SizeEnum.large}>Button</ContainedButton>
          }
        />
        <TextField
          placeholder="Text Field with Buttons Before and After"
          contentBefore={
            <ContainedButton size={SizeEnum.large}>Button</ContainedButton>
          }
          contentAfter={
            <ContainedButton size={SizeEnum.large}>Button</ContainedButton>
          }
        />
      </DisplayWrapper>

      <h3 className="large-header">Selects</h3>
      <DisplayWrapper gap="10px">
        <Select
          placeholder="Select an option..."
          options={[
            { value: '1', label: 'Option 1', key: '1' },
            { value: '2', label: 'Option 2', key: '2' },
            { value: '3', label: 'Option 3', key: '3' }
          ]}
        />
        <Select
          size={SizeEnum.small}
          placeholder="Small Select..."
          options={[
            { value: '1', label: 'Option 1', key: '1' },
            { value: '2', label: 'Option 2', key: '2' },
            { value: '3', label: 'Option 3', key: '3' }
          ]}
        />
        <Select
          placeholder="Disabled Select..."
          options={[
            { value: '1', label: 'Option 1', key: '1' },
            { value: '2', label: 'Option 2', key: '2' },
            { value: '3', label: 'Option 3', key: '3' }
          ]}
          disabled
        />
        <Select
          size={SizeEnum.small}
          placeholder="Disabled Small Select..."
          disabled
          options={[
            { value: '1', label: 'Option 1', key: '1' },
            { value: '2', label: 'Option 2', key: '2' },
            { value: '3', label: 'Option 3', key: '3' }
          ]}
        />
        <Select
          placeholder="Select with Content Before"
          options={[
            { value: '1', label: 'Option 1', key: '1' },
            { value: '2', label: 'Option 2', key: '2' },
            { value: '3', label: 'Option 3', key: '3' }
          ]}
          contentBefore={<SearchIcon />}
        />
        <Select
          placeholder="Select with Content After"
          options={[
            { value: '1', label: 'Option 1', key: '1' },
            { value: '2', label: 'Option 2', key: '2' },
            { value: '3', label: 'Option 3', key: '3' }
          ]}
          contentAfter={<SearchIcon />}
        />

        <h3>Preselected Option</h3>
        <Select
          defaultValue="2"
          options={[
            { value: '1', label: 'Option 1', key: '1' },
            { value: '2', label: 'Option 2', key: '2' },
            { value: '3', label: 'Option 3', key: '3' }
          ]}
        />
      </DisplayWrapper>

      <h3 className="large-header">Checkboxes</h3>
      <DisplayWrapper>
        <Checkbox />
        <Checkbox disabled />
        <Checkbox disabled checked />
      </DisplayWrapper>
      <DisplayWrapper>
        <Checkbox label="Label" />
        <Checkbox label="Label" disabled />
        <Checkbox label="Label" disabled checked />
      </DisplayWrapper>

      <h3 className="large-header">Toggles</h3>
      <DisplayWrapper>
        <Toggle id="small-toggle" />
        <Toggle id="small-toggle-disabled" disabled />
        <Toggle id="small-toggle-disabled" disabled checked />
        <Toggle size="large" id="large-toggle" />
        <Toggle size="large" id="large-toggle-disabled" disabled />
        <Toggle size="large" id="large-toggle-disabled" disabled checked />
      </DisplayWrapper>

      <h3 className="large-header">Radio Buttons</h3>
      <DisplayWrapper>
        <RadioButton name="radio" />
        <RadioButton name="radio" />
        <RadioButton name="radio" />
        <RadioButton name="radio-disabled" disabled />
        <RadioButton name="radio-disabled" disabled checked />
      </DisplayWrapper>

      <DisplayWrapper>
        <RadioButton name="radio-label" label="Option 1" />
        <RadioButton name="radio-label" label="Option 2" />
        <RadioButton name="radio-label" label="Option 3" />
        <RadioButton name="radio-disabled-label" disabled label="Label" />
        <RadioButton
          name="radio-disabled-label"
          disabled
          checked
          label="Label"
        />
      </DisplayWrapper>

      <h2 className="large-header">Chips</h2>
      <DisplayWrapper>
        <Chip size={SizeEnum.xSmall}>x-small chip</Chip>
        <Chip size={SizeEnum.small}>small chip</Chip>
        <Chip>Default</Chip>
      </DisplayWrapper>
      <DisplayWrapper>
        <Chip size={SizeEnum.xSmall}>
          <UserIcon />
          x-small chip
        </Chip>
        <Chip size={SizeEnum.small}>
          <UserIcon />
          small chip
        </Chip>
        <Chip>
          <UserIcon />
          Default
        </Chip>
      </DisplayWrapper>
      <DisplayWrapper>
        <Chip size={SizeEnum.xSmall}>
          x-small chip
          <IconButton onClick={onClick} icon={<CloseOutlineIcon />} />
        </Chip>
        <Chip size={SizeEnum.small}>
          small chip
          <IconButton onClick={onClick} icon={<CloseOutlineIcon />} />
        </Chip>
        <Chip>
          Default
          <IconButton onClick={onClick} icon={<CloseOutlineIcon />} />
        </Chip>
      </DisplayWrapper>
      <DisplayWrapper>
        <Chip size={SizeEnum.xSmall}>
          <UserIcon />
          x-small chip
          <IconButton onClick={onClick} icon={<CloseOutlineIcon />} />
        </Chip>
        <Chip size={SizeEnum.small}>
          <UserIcon />
          small chip
          <IconButton onClick={onClick} icon={<CloseOutlineIcon />} />
        </Chip>
        <Chip>
          <UserIcon />
          Default
          <IconButton onClick={onClick} icon={<CloseOutlineIcon />} />
        </Chip>
      </DisplayWrapper>

      <h2 className="large-header">Tooltips</h2>
      <h3 className="large-header">w/o arrow</h3>
      <DisplayWrapper>
        <Tooltip text="Tooltip Text">
          <ContainedButton onClick={onClick}>Tooltip</ContainedButton>
        </Tooltip>
        <Tooltip text="Tooltip Text">
          <TextButton onClick={onClick} text="Tooltip" />
        </Tooltip>
        <Tooltip text="Tooltip Text" disabled>
          <TextButton onClick={onClick} text="Disabled Tooltip" />
        </Tooltip>
        <Tooltip text="Tooltip Text">
          <IconButton onClick={onClick} icon={<SearchIcon />} />
        </Tooltip>
      </DisplayWrapper>

      <h3 className="large-header">w/ arrow</h3>
      <DisplayWrapper>
        <Tooltip text="What if I have a very very very long tool tip?" arrow>
          <ContainedButton onClick={onClick}>Long Tooltip</ContainedButton>
        </Tooltip>
        <Tooltip text="Tooltip Text" arrow>
          <TextButton onClick={onClick} text="Tooltip" />
        </Tooltip>
        <Tooltip text="Tooltip Text" arrow disabled>
          <TextButton onClick={onClick} text="Disabled Tooltip" />
        </Tooltip>
        <Tooltip text="Tooltip Text" arrow>
          <IconButton onClick={onClick} icon={<SearchIcon />} />
        </Tooltip>
      </DisplayWrapper>

      <h2 className="large-header">Tabs</h2>
      <DisplayWrapper gap="0px">
        <TabButton onClick={onClick} active>
          Active
        </TabButton>
        <TabButton onClick={onClick}>Inactive</TabButton>
        <TabButton onClick={onClick} disabled>
          Disabled
        </TabButton>
        <TabButton onClick={onClick} active disabled>
          Active Disabled
        </TabButton>
      </DisplayWrapper>

      <h2 className="large-header">Toast</h2>
      <DisplayWrapper>
        <ContainedButton onClick={() => toast('This is a toast message!')}>
          Show Toast
        </ContainedButton>
        <ContainedButton
          onClick={() => toast.success('This is a success message!')}
        >
          Success
        </ContainedButton>
        <ContainedButton
          onClick={() => toast.error('This is an error message!')}
        >
          Error
        </ContainedButton>

        <ContainedButton onClick={() => toast.info('This is an info message!')}>
          Info
        </ContainedButton>

        <ContainedButton
          onClick={() => toast.warning('This is a warning message!')}
        >
          Warning
        </ContainedButton>
      </DisplayWrapper>
    </>
  );
}
