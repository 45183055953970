import { createSlice } from '@reduxjs/toolkit';
import { UpsellsSliceState } from '../types';

const initialState: UpsellsSliceState = {
  availableUpsellItems: [],
  chosenUpsellItems: []
};

export const upsellsSlice = createSlice({
  name: 'upsells',
  initialState: initialState,
  reducers: {
    addUpsell: (state, action) => {
      state.chosenUpsellItems.push(action.payload);
    },
    removeUpsell: (state, action) => {
      state.chosenUpsellItems = state.chosenUpsellItems.filter(
        item => item.upsell.product_id !== action.payload.product_id
      );
    },
    setAvailableUpsellItems: (state, action) => {
      state.availableUpsellItems = action.payload;
      state.chosenUpsellItems = [];
    }
  }
});

export const { addUpsell, removeUpsell, setAvailableUpsellItems } =
  upsellsSlice.actions;

export const selectAvailableUpsellItems = (state: {
  upsells: UpsellsSliceState;
}) => state.upsells.availableUpsellItems;

export const selectChosenUpsellItems = (state: {
  upsells: UpsellsSliceState;
}) => state.upsells.chosenUpsellItems;

export const getChosenUpsellsPrice = (state: {
  upsells: UpsellsSliceState;
}) => {
  if (state.upsells) {
    return state.upsells.chosenUpsellItems.reduce((total, upsell) => {
      const priceCents = upsell.upsell.priceCents;
      return typeof priceCents === 'number' ? total + priceCents : total;
    }, 0);
  }
  return 0;
};

export const selectHasUpsells = (state: { upsells: UpsellsSliceState }) =>
  state.upsells.availableUpsellItems.length > 0;
