import { useMemo } from 'react';

import { useGetProductQuery } from '../api/store/apiSlice';
import { useRootSelector } from '../../store';
import { selectProductTag, selectStyle } from '../item/store/itemSlice';

export function useLabelShape() {
  const productTag = useRootSelector(selectProductTag);
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductQuery(productTag);

  const selectedStyle = useRootSelector(selectStyle);

  const showLabelShapes = useMemo(() => {
    const labels =
      data?.labels?.filter(l =>
        l.variants.some(v => v.style_id === selectedStyle?.style_id)
      ) ?? [];
    return (
      data?.label_slots?.length === 1 &&
      data?.label_slots?.[0]?.slot_options?.length > 1 &&
      labels.length > 1
    );
  }, [data?.labels, data?.label_slots, selectedStyle?.style_id]);

  return {
    showLabelShapes,
    isLoading,
    isSuccess,
    isError,
    error
  };
}
