import { useMemo } from 'react';
import { useGetProductQuery } from '../api/store/apiSlice';
import { useRootSelector } from '../../store';
import { selectProductTag, selectStyle } from '../item/store/itemSlice';

export function useStyleAndColor() {
  const productTag = useRootSelector(selectProductTag);
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductQuery(productTag);

  const selectedStyle = useRootSelector(selectStyle);

  const styles = useMemo(() => {
    return data?.styles ?? [];
  }, [data?.styles]);

  const palettes = useMemo(() => {
    return selectedStyle && data?.palettes
      ? data.palettes.filter(p =>
          selectedStyle.palette_ids.includes(p.palette_id)
        )
      : [];
  }, [data?.palettes, selectedStyle]);

  const showStyleAndColors = useMemo(
    () => styles.length > 1 || palettes.length > 1,
    [styles.length, palettes.length]
  );

  return {
    styles,
    palettes,
    showStyleAndColors,
    isLoading,
    isSuccess,
    isError,
    error
  };
}
