import {
  ThunkAction,
  UnknownAction,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { debounce } from 'lodash';

import { RootState } from '../../../store';
import { SHOPIFY_PRODUCT_TAG } from '../../../config';
import {
  ItemSlot,
  Label,
  Material,
  Style,
  SwatchSelections
} from '../../../types';

import { apiSlice } from '../../api/store/apiSlice';
import { resetPages } from '../../accordion/accordionSlice';

import { CartLineItemProperties, ItemSliceState } from '../types';

const initialState: ItemSliceState = {
  id: 0,
  quantity: 1,
  properties: {
    productId: null,
    productTag: SHOPIFY_PRODUCT_TAG,
    productVdpCode: null,
    style: null,
    palette: null,
    font: null,
    fields: {},
    icon: null,
    labelShape: null,
    slots: {},
    clothingType: null
  }
};

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setProductId: (state, action) => {
      state.properties.productId = action.payload;
    },
    setProductTag: (state, action) => {
      state.properties.productTag = action.payload;
    },
    setProductVdpCode: (state, action) => {
      state.properties.productVdpCode = action.payload;
    },
    setStyle: (state, action) => {
      state.properties.style = action.payload;
    },
    setPalette: (state, action) => {
      state.properties.palette = action.payload;
    },
    setFont: (state, action) => {
      state.properties.font = action.payload;
    },
    setTextFields: (state, action) => {
      state.properties.fields = action.payload;
    },
    setIcon: (state, action) => {
      state.properties.icon = action.payload;
    },
    setLabel: (state, action) => {
      state.properties.labelShape = action.payload;
    },
    clearSlots: state => {
      state.properties.slots = {};
    },
    setSlots: (state, action) => {
      state.properties.slots[action.payload.slotId] = action.payload.value;
    },
    setMaterial: (state, action) => {
      state.properties.clothingType = action.payload || null;
    },
    setSlotField: (state, action) => {
      const payload = action.payload as {
        slotId: string;
        field: 'labelId' | 'variantId' | 'materialId';
        value: string;
      };
      state.properties.slots[payload.slotId][payload.field] = payload.value;
    },
    resetItem: state => {
      state.properties = { ...initialState.properties };
    }
  }
});

export const updateStyle =
  (
    newStyle: Style,
    swatchSelections: SwatchSelections = {}
  ): ThunkAction<void, RootState, unknown, UnknownAction> =>
  async (dispatch, getState) => {
    const stateBefore = getState();

    const { properties: propertiesBefore } = stateBefore.item;

    const existingPaletteId = propertiesBefore.palette?.palette_id;
    const existingFontId = propertiesBefore.font?.font_id;
    const existingIconId = propertiesBefore.icon?.icon_id;
    const existingLabelId = propertiesBefore.labelShape?.label_id;

    // If the new style does not contain the selected palette, font, icon,
    // variant, or material:
    // default to the first new available one.
    const productTag = selectProductTag(stateBefore);

    const cachedProductData = apiSlice.endpoints.getProduct.select(productTag)(
      getState()
    );

    const needToChangePalette =
      !existingPaletteId || !newStyle.palette_ids.includes(existingPaletteId);

    if (needToChangePalette) {
      const cachedPalettes = cachedProductData?.data?.palettes;
      const permittedPalettes = cachedPalettes?.filter(palette =>
        newStyle.palette_ids.includes(palette.palette_id)
      );
      if (permittedPalettes?.length) {
        const firstValidPalette = permittedPalettes[0];
        dispatch(setPalette(firstValidPalette));
      }
    }

    // check if there was a selected color swatch. If so update the palette
    // to match and clear the swatch selection from the global state
    if (swatchSelections.color) {
      let colorSwatch = swatchSelections.color.toLowerCase();

      // attempt a case insensitive match first
      let matchingPalette =
        cachedProductData?.data?.palettes.find(
          palette => palette.system_name.toLowerCase() === colorSwatch
        ) ||
        cachedProductData?.data?.palettes?.find(
          palette => palette.display_name.toLowerCase() === colorSwatch
        );

      // if no match, try to match with underscores and hyphens removed
      if (!matchingPalette) {
        colorSwatch = colorSwatch.replace(/[-_]/g, ' ').trim();
        matchingPalette =
          cachedProductData?.data?.palettes.find(
            palette => palette.system_name.toLowerCase() === colorSwatch
          ) ||
          cachedProductData?.data?.palettes?.find(
            palette => palette.display_name.toLowerCase() === colorSwatch
          );
      }

      if (matchingPalette) dispatch(setPalette(matchingPalette));
    }

    const needToChangeFont =
      !existingFontId || !newStyle.font_ids.includes(existingFontId);

    if (needToChangeFont) {
      const fonts = cachedProductData?.data?.fonts;
      const permittedFonts = fonts
        ?.filter(font => newStyle.font_ids.includes(font.font_id))
        ?.sort((fontA, fontB) =>
          fontA.display_name.localeCompare(fontB.display_name)
        );
      if (permittedFonts?.length) {
        const firstValidFont = permittedFonts[0];
        dispatch(setFont(firstValidFont));
      }
    }

    const needToChangeIcon =
      !existingIconId || !newStyle.icon_ids.includes(existingIconId);

    if (needToChangeIcon) {
      const icons = cachedProductData?.data?.icons;
      const permittedIcons = icons
        ?.filter(icon => newStyle.icon_ids.includes(icon.icon_id))
        ?.sort((iconA, iconB) =>
          iconA.display_name.localeCompare(iconB.display_name)
        );
      if (permittedIcons?.length) {
        const firstValidIcon = permittedIcons[0];
        dispatch(setIcon(firstValidIcon));
      }
    }

    const labelsSupportingNewStyle = cachedProductData?.data?.labels.filter(
      label =>
        label.variants.some(variant => variant.style_id === newStyle?.style_id)
    );

    const needToChangeLabelShape =
      !existingLabelId ||
      !labelsSupportingNewStyle?.find(l => l.label_id === existingLabelId);

    if (needToChangeLabelShape) {
      if (labelsSupportingNewStyle?.length) {
        dispatch(setLabel(labelsSupportingNewStyle[0]));
      }
    }

    // check if there was a selected shape swatch. If so update the label
    // to match
    let preSelectedLabel: Label | undefined;
    if (swatchSelections.shape) {
      // search for a label that matches this shape swatch
      const shapeSwatch = swatchSelections.shape.toLowerCase();

      // check if the label display name matches the shapeSwatch. If no match
      // has been found, check if a label has a variant who's system name starts
      // with the shape swatch
      const labelMatch =
        labelsSupportingNewStyle?.find(
          label => label.display_name.toLowerCase() === shapeSwatch
        ) ||
        labelsSupportingNewStyle?.find(label =>
          label.variants.find(labelVariant =>
            labelVariant.system_name.toLowerCase().startsWith(shapeSwatch)
          )
        );

      if (labelMatch) {
        preSelectedLabel = labelMatch;
        dispatch(setLabel(labelMatch));
      }
    }

    const materialOptions = [
      ...(cachedProductData?.data?.materials ?? [])
    ].sort(
      (a, b) =>
        a.display_name.localeCompare(b.display_name) ||
        a.material_id.localeCompare(b.material_id)
    );

    const defaultMaterial = materialOptions[0];

    // check that the slots are filled
    cachedProductData?.data?.label_slots?.forEach(label_slot => {
      const oldLabelSlot = {
        ...(stateBefore?.item?.properties?.slots?.[
          label_slot.product_label_slot_id
        ] || ({} as ItemSlot))
      };

      // if the slot has no label id, or the label does not support this style,
      // set it to the first label id in the slot options
      if (
        !oldLabelSlot.labelId ||
        !labelsSupportingNewStyle?.some(
          label => label.label_id === oldLabelSlot.labelId
        )
      ) {
        if (preSelectedLabel) {
          oldLabelSlot.labelId = preSelectedLabel.label_id;
        } else {
          const firstLabel = labelsSupportingNewStyle?.find(label =>
            label_slot.slot_options.find(
              slotOption => slotOption.label_id == label.label_id
            )
          );

          oldLabelSlot.labelId = firstLabel?.label_id;
        }
      }

      // get the label for this slot
      const label = cachedProductData?.data?.labels?.find(
        label => label.label_id === oldLabelSlot.labelId
      );

      // get the variant ids that are valid for this label and style
      const validVariants = label?.variants
        ?.filter(v => v.style_id === newStyle.style_id)
        ?.sort((a, b) => a.system_name.localeCompare(b.system_name));

      if (swatchSelections.shape) {
        const shapeSwatch = swatchSelections.shape.toLowerCase();
        const variantMatch = validVariants?.find(variant => {
          return variant.system_name.toLowerCase().startsWith(shapeSwatch);
        });

        if (variantMatch) {
          oldLabelSlot.variantId = variantMatch.label_variant_id;
        }
      }

      // if the slot has no variant id, set it to the first variant id in the label options
      if (
        !oldLabelSlot.variantId ||
        !validVariants
          ?.map(v => v.label_variant_id)
          .includes(oldLabelSlot.variantId)
      ) {
        // default to the first valid variant - or selected iterative default.
        if (label?.iterative_style_ids?.includes(newStyle.style_id)) {
          oldLabelSlot.variantId =
            label.iterative_defaults?.[newStyle.style_id] ||
            validVariants?.[0].label_variant_id;
        } else {
          oldLabelSlot.variantId = validVariants?.[0].label_variant_id;
        }
      }

      // update the slot with the new values
      dispatch(
        setSlots({
          slotId: label_slot.product_label_slot_id,
          value: oldLabelSlot
        })
      );
    });

    // now that we have updated everything to be a valid selection, we can
    // finally set the style
    dispatch(setStyle(newStyle));
    dispatch(updateMaterial(defaultMaterial));

    // reset paginated selectors
    dispatch(resetPages());
  };

export const updateLabelShape =
  (labelId: string): ThunkAction<void, RootState, unknown, UnknownAction> =>
  async (dispatch, getState) => {
    const stateBefore = getState();

    const styleId = stateBefore.item.properties.style?.style_id;

    const productTag = selectProductTag(stateBefore);
    const cachedProductData = apiSlice.endpoints.getProduct.select(productTag)(
      getState()
    );
    const labels = cachedProductData?.data?.labels?.filter(l =>
      l.variants.some(v => v.style_id === styleId)
    );
    const newLabel = labels?.find(label => label.label_id === labelId);
    if (!newLabel) {
      return;
    }

    // check that the slots are filled
    cachedProductData?.data?.label_slots?.forEach(label_slot => {
      const oldLabelSlot = {
        ...(stateBefore?.item?.properties?.slots?.[
          label_slot.product_label_slot_id
        ] || ({} as ItemSlot))
      };

      // set slots to new labelId
      oldLabelSlot.labelId = newLabel.label_id;

      const slotOption = label_slot.slot_options.find(
        so => so.label_id === newLabel.label_id
      );

      // if the slot has no material id, or a now-invalid one,
      // set it to the first material id in the label options

      // get the material ids that are valid for this slot option
      const validMaterials = slotOption?.material_ids;
      if (
        !oldLabelSlot.materialId ||
        !validMaterials?.includes(oldLabelSlot.materialId)
      ) {
        // default to the first valid material
        oldLabelSlot.materialId = validMaterials?.[0];
      }

      // if the slot has no variant id, or a now-invalid one,
      // set it to the first variant id in the label options
      // get the variant ids that are valid for this label and style

      const validVariants = newLabel?.variants
        ?.filter(v => v.style_id === styleId)
        ?.map(v => v.label_variant_id)
        ?.sort();

      if (
        !oldLabelSlot.variantId ||
        !validVariants.includes(oldLabelSlot.variantId)
      ) {
        // default to the first valid variant, unless there is an iterative
        // default for this style
        oldLabelSlot.variantId =
          (styleId && newLabel.iterative_defaults?.[styleId]) ||
          validVariants?.[0];
      }

      // update the slot with the new values
      dispatch(
        setSlots({
          slotId: label_slot.product_label_slot_id,
          value: oldLabelSlot
        })
      );
    });

    // now that we have updated everything to be a valid selection, we can
    // update the UI label selection
    dispatch(setLabel(newLabel));
  };

export const updateMaterial =
  (
    material: Material | undefined
  ): ThunkAction<void, RootState, unknown, UnknownAction> =>
  (dispatch, getState) => {
    const stateBefore = getState();
    const productTag = selectProductTag(stateBefore);
    const cachedProductData = apiSlice.endpoints.getProduct.select(productTag)(
      getState()
    );

    const supportedMaterialsBySlotId = new Map(
      cachedProductData.data?.label_slots
        .flatMap(slot => slot.slot_options)
        .map(option => [option.product_label_slot_id, option.material_ids])
    );

    const { properties } = stateBefore.item;
    for (const slotId in properties.slots) {
      const supportedMaterials = supportedMaterialsBySlotId.get(slotId);

      const slotSupportsMaterial =
        material && supportedMaterials?.includes(material.material_id);

      // if the slot supports the material, set it to the material
      // otherwise, set it to the first supported material
      const value = slotSupportsMaterial
        ? material.material_id
        : supportedMaterials![0];

      dispatch(setSlotField({ slotId, field: 'materialId', value }));
    }

    if (material) dispatch(setMaterial(material));
  };

// according to something I found online, one needs the debounced thunk to be
// in two steps like this (otherwise you'll return fresh functions that overlap.)
const syncFieldsInner = debounce((dispatch, getState) => {
  const stateBefore = getState();
  const fields = stateBefore.accordion.fields;

  const productTag = selectProductTag(stateBefore);

  const cachedProductData = apiSlice.endpoints.getProduct.select(productTag)(
    getState()
  );

  const textFieldDefs = cachedProductData?.data?.labels?.flatMap(label =>
    label.variants.flatMap(variant => variant.text_fields)
  );

  const fieldItemState: {
    [key: string]: {
      field_id: string;
      display_name: string | undefined;
      display_name_2?: string | null;
      value: string;
    };
  } = {};

  for (const fieldId in fields) {
    const fieldDefinition = textFieldDefs?.find(
      fieldDef => fieldDef.text_field_id === fieldId
    );

    fieldItemState[fieldId] = {
      field_id: fieldId,
      display_name: fieldDefinition?.display_name,
      display_name_2: fieldDefinition?.display_name_2,
      value: fields[fieldId]
    };
  }

  dispatch(setTextFields(fieldItemState));
}, 1000);

export const syncFields = (): ThunkAction<
  void,
  RootState,
  unknown,
  UnknownAction
> => syncFieldsInner;

export const {
  setProductId,
  setProductTag,
  setProductVdpCode,
  setFont,
  setIcon,
  setLabel,
  setPalette,
  setSlotField,
  clearSlots,
  setSlots,
  setStyle,
  setTextFields,
  setMaterial,
  resetItem
} = itemSlice.actions;

export default itemSlice.reducer;

export const selectStyle = (state: RootState) => state.item.properties.style;

export const selectPalette = (state: RootState) =>
  state.item.properties.palette;

export const selectFont = (state: RootState) => state.item.properties.font;

export const selectIcon = (state: RootState) => state.item.properties.icon;

export const selectLabelShape = (state: RootState) =>
  state.item.properties.labelShape;
export const selectProductTag = (state: RootState) =>
  state.item.properties.productTag;
export const selectMaterial = (state: RootState) =>
  state.item.properties.clothingType;

export const selectItemProperties = (state: RootState) => state.item.properties;
export const selectSlots = (state: RootState) => state.item.properties.slots;

const selectStyleName = (state: RootState) =>
  state.item.properties.style?.display_name;
const selectPaletteName = (state: RootState) =>
  state.item.properties.palette?.display_name;
const selectFontName = (state: RootState) =>
  state.item.properties.font?.display_name;
const selectIconName = (state: RootState) =>
  state.item.properties.icon?.display_name;
const selectMaterialName = (state: RootState) =>
  state.item.properties.clothingType?.display_name;
const selectFields = (state: RootState) => state.item.properties.fields;

export const selectCartItem = createSelector(
  [
    selectStyleName,
    selectPaletteName,
    selectFontName,
    selectIconName,
    selectMaterialName,
    selectFields
  ],
  (
    styleName,
    paletteName,
    fontName,
    iconName,
    materialName,
    fields
  ): CartLineItemProperties => {
    const cartItem: CartLineItemProperties = {
      Style: styleName,
      Palette: paletteName,
      Font: fontName,
      Icon: iconName,
      'Clothing Type': materialName
    };

    for (const textId in fields) {
      const { display_name, display_name_2, value } = fields[textId];

      if (display_name_2) {
        const textLines = value.trimEnd().split('\n');
        cartItem[display_name!] = textLines[0];
        cartItem[display_name_2!] = textLines[1];
      } else {
        cartItem[display_name!] = value;
      }
    }

    return cartItem;
  }
);
