import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import Pager from '../../components/Pager';
import { Tooltip } from 'react-tooltip';
import { Variant } from '../../types';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import {
  selectProductTag,
  selectSlots,
  selectStyle,
  setSlotField
} from '../item/store/itemSlice';
import { useGetProductQuery } from '../api/store/apiSlice';
import { orderBy } from 'lodash';
import { openSeeAll } from '../accordion/accordionSlice';
import { tooltipClasses } from '../../components/grids/GridElement';

const DEFAULT_VARIANTS_TO_DISPLAY = 6;

export interface LabelVariantsProps {
  labelSlotId: string;
  showLabelName: boolean;
}

export default function LabelVariants({
  labelSlotId,
  showLabelName
}: LabelVariantsProps) {
  const dispatch = useConfiguratorDispatch();

  const [selected, setSelected] = useState<Variant>();

  const productId = useRootSelector(selectProductTag);
  const slots = useRootSelector(selectSlots);
  const thisSlot = slots[labelSlotId];
  const selectedStyle = useRootSelector(selectStyle);
  const { data: product } = useGetProductQuery(productId);

  const label = useMemo(() => {
    const labelId = thisSlot.labelId;
    return product?.labels?.find(l => l.label_id === labelId);
  }, [product?.labels, thisSlot.labelId]);

  const variants = useMemo(() => {
    const unsortedVariants =
      label?.variants?.filter(v => v.style_id === selectedStyle?.style_id) ??
      [];
    return orderBy(unsortedVariants, v => v.system_name.toLocaleLowerCase());
  }, [label?.variants, selectedStyle?.style_id]);

  const totalPages =
    Math.ceil(variants?.length / DEFAULT_VARIANTS_TO_DISPLAY) ?? 0;
  const [page, setPage] = useState(0);
  const [variantSlice, setVariantSlice] = useState<Variant[]>([]);

  useEffect(() => {
    const start = page * DEFAULT_VARIANTS_TO_DISPLAY;
    const end = start + DEFAULT_VARIANTS_TO_DISPLAY;
    setVariantSlice(variants?.slice(start, end) ?? []);
  }, [variants, page]);

  useEffect(() => {
    const selectedVariant = variants.find(
      v => v.label_variant_id === thisSlot.variantId
    );
    setSelected(selectedVariant);
  }, [thisSlot.variantId, variants]);

  const thumbnailClasses = clsx(
    'm-px box-border h-[60px] w-[60px] rounded object-scale-down object-center'
  );

  const handleClick = (variant: Variant) => {
    dispatch(
      setSlotField({
        slotId: labelSlotId,
        field: 'variantId',
        value: variant.label_variant_id
      })
    );
  };

  return (
    <>
      {showLabelName && label?.display_name ? (
        <p className="mt-2 font-semibold " style={{ color: '#555555' }}>
          {label?.display_name}
        </p>
      ) : null}
      <ol className="mt-2 grid grid-cols-6 items-center gap-2.5 pb-2.5">
        {variantSlice.map(variant => {
          return (
            <li
              key={variant.label_variant_id}
              className={clsx(
                'box-border h-[66px] w-[66px] rounded-md border-2 border-solid',
                variant == selected ? 'border-navy' : 'border-transparent'
              )}
            >
              <Tooltip id="variant-tooltip" className={tooltipClasses} />
              <img
                src={variant.thumbnail_path}
                className={thumbnailClasses}
                data-tooltip-id="variant-tooltip"
                data-tooltip-content={variant?.system_name || ''}
                data-tooltip-place="top"
                onClick={() => handleClick(variant)}
              />
            </li>
          );
        })}
      </ol>

      <div className="grid grid-cols-4 items-center pb-2.5">
        <span className="text-dark-gray truncate text-sm font-medium">
          {selected?.system_name}
        </span>
        <Pager
          size="small"
          totalPages={totalPages}
          currentPage={page}
          onBack={() => setPage(old => Math.max(0, old - 1))}
          onForward={() => setPage(old => Math.min(old + 1, totalPages - 1))}
        />
        <button
          onClick={() =>
            dispatch(openSeeAll({ entity: 'LabelVariants', labelSlotId }))
          }
          className="border-primary text-primary border-1 justify-self-end rounded-full border-solid px-2.5 py-1.5 font-semibold"
        >
          See all
        </button>
      </div>
    </>
  );
}
