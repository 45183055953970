import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import clsx from 'clsx';

import Pager from '../../components/Pager';
import { useConfiguratorDispatch, useRootSelector } from '../../store';

import {
  PAGE_TYPE,
  openSeeAll,
  selectFontsPage,
  setPage
} from '../accordion/accordionSlice';
import { selectFont, setFont } from '../item/store/itemSlice';

import { useFontsAndFields } from './TextAndFont.hooks';
import TextInputs from './TextInputs';
import { Font } from '.';
import { CartErrors } from '../../utils/getCartErrors';
import { tooltipClasses } from '../../components/grids/GridElement';

const DEFAULT_FONTS_TO_DISPLAY = 6;

const FontSkeleton = (
  <ol
    role="status"
    className="grid animate-pulse grid-cols-3 grid-rows-[66px_66px] items-stretch gap-2.5 pb-2.5"
  >
    {Array.from({ length: DEFAULT_FONTS_TO_DISPLAY }).map((_, i) => (
      <li
        key={i}
        className="rounded-md border-2 border-solid border-transparent"
      >
        <div className="m-px flex h-full w-full rounded-md border border-solid border-gray-100 bg-gray-100"></div>
      </li>
    ))}
  </ol>
);

type TextAndFontProps = {
  admin?: boolean;
  errors?: CartErrors;
};

export default function TextAndFont({
  admin = false,
  errors
}: TextAndFontProps) {
  const dispatch = useConfiguratorDispatch();

  const selectedFont = useRootSelector(selectFont);

  const { fonts, fields, isLoading, isSuccess, isError, error } =
    useFontsAndFields();

  const totalPages = Math.ceil(fonts?.length / DEFAULT_FONTS_TO_DISPLAY) ?? 0;
  const [fontSlice, setFontSlice] = useState<Font[]>([]);
  const fontsPage = Math.min(useRootSelector(selectFontsPage), totalPages - 1);

  useEffect(() => {
    const start = fontsPage * DEFAULT_FONTS_TO_DISPLAY;
    const end = start + DEFAULT_FONTS_TO_DISPLAY;
    setFontSlice(fonts?.slice(start, end) ?? []);
  }, [fonts, fontsPage]);

  return (
    <div className="flex flex-col gap-2.5 px-1 py-2.5">
      <TextInputs admin={admin} fields={fields} errors={errors?.fields} />
      {isLoading && FontSkeleton}
      {isError && (
        <>
          <h1>There was an error loading fonts</h1> {console.log(error)}
        </>
      )}
      {isSuccess && fontSlice.length > 1 && (
        <>
          <ol className="grid grid-cols-3 grid-rows-[56px_56px] gap-2.5">
            {fontSlice.map(font => (
              <li
                key={font.font_id}
                className={clsx(
                  'rounded-lg border-solid p-2.5 text-center ',
                  font == selectedFont
                    ? 'border-navy border-2 bg-gray-200'
                    : 'm-px border border-gray-400'
                )}
                onClick={() => dispatch(setFont(font))}
                data-tooltip-id="font-tooltip"
                data-tooltip-content={font.display_name}
                data-tooltip-place="top"
              >
                <Tooltip id="font-tooltip" className={tooltipClasses} />

                <div
                  className="truncate text-2xl"
                  style={{
                    fontFamily: font.google_family + font.font_id,
                    fontWeight: font.weight,
                    fontStyle: font.style || 'normal'
                  }}
                >
                  {font.display_name}
                </div>
              </li>
            ))}
          </ol>

          <div className="grid grid-cols-4 items-center pb-2.5">
            <span className="text-dark-gray truncate text-sm font-semibold">
              {selectedFont?.display_name ?? ''}
            </span>
            <Pager
              size="small"
              totalPages={totalPages}
              currentPage={fontsPage}
              onBack={() =>
                dispatch(
                  setPage({
                    page: PAGE_TYPE.font,
                    pageNumber: Math.max(0, fontsPage - 1)
                  })
                )
              }
              onForward={() =>
                dispatch(
                  setPage({
                    page: PAGE_TYPE.font,
                    pageNumber: Math.min(fontsPage + 1, totalPages - 1)
                  })
                )
              }
            />
            <button
              onClick={() => dispatch(openSeeAll('Fonts'))}
              className="border-primary text-primary border-1 justify-self-end rounded-full border-solid px-2.5 py-1.5 font-semibold"
            >
              See all
            </button>
          </div>
        </>
      )}
    </div>
  );
}
