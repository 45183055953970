import React from 'react';
import clsx from 'clsx';
import { BaseInput, BaseInputProps } from './BaseInput';

export interface TextFieldProps extends Omit<BaseInputProps, 'type' | 'size'> {
  error?: boolean;
  size?: 'small' | 'large';
  bold?: boolean;
  contentBefore?: React.ReactNode;
  contentAfter?: React.ReactNode;
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const {
      error,
      className,
      size = 'large',
      disabled,
      contentBefore,
      contentAfter,
      value,
      bold,
      ...rest
    } = props;

    const wrapperClassList = [
      'flex',
      'flex-shrink-0',
      'items-center',
      'gap-[10px]',
      'rounded-[4px]',
      'px-3',
      'py-2',
      'align-middle',
      'drop-shadow-textbox',
      'm-px'
    ];

    const errorClassList = ['outline-error outline outline-2'];

    const disabledClassList = {
      'cursor-not-allowed': disabled,
      'bg-white': !disabled,
      'bg-[#e0e0e0]': disabled,
      'text-[#a6a6a6]': disabled
    };

    const sizeClass = {
      'h-[40px]': size === 'small',
      'h-10': size === 'large'
    };

    const inputClasses = [
      'flex-[1_0_0]',
      'bg-transparent',
      'border-none',
      'outline-none'
    ];

    const dynamicInputClasses = {
      'font-bold': !!value || bold
    };

    return (
      <div
        className={clsx(
          wrapperClassList,
          disabledClassList,
          'transition-all',
          error && errorClassList,
          sizeClass,
          className
        )}
      >
        {contentBefore}
        <BaseInput
          ref={ref}
          disabled={disabled}
          value={value}
          className={clsx(
            inputClasses,
            disabledClassList,
            'w-full text-base',
            dynamicInputClasses
          )}
          type="text"
          {...rest}
        />
        {contentAfter}
      </div>
    );
  }
);
