import { SHOPIFY_UPSELL_VARIANTS } from '../../../config';
import { ItemProperties } from '../../../types';

import { UpsellProduct } from '../types';

export function processUpsells(
  apiUpsells: UpsellProduct[],
  item: ItemProperties
) {
  if (!apiUpsells.length || !SHOPIFY_UPSELL_VARIANTS.length) return [];

  return apiUpsells.reduce(
    (processedUpsells: UpsellProduct[], apiUpsell: UpsellProduct) => {
      const shopifyUpsellVariant = SHOPIFY_UPSELL_VARIANTS.find(
        ({ sku }) =>
          sku === apiUpsell.sku &&
          item.style?.style_id &&
          apiUpsell.style_ids.includes(item.style?.style_id)
      );

      if (shopifyUpsellVariant?.available) {
        const { id, price, title: shopifyProductTitle } = shopifyUpsellVariant;

        const parts = shopifyUpsellVariant.name.split(' - ');
        const quantityString = parts[parts.length - 1];
        let quantity = parseInt(quantityString) || undefined;

        const upsellHasOneLabel = apiUpsell.labels.length === 1;

        if (upsellHasOneLabel) {
          quantity = apiUpsell.label_slots[0].slot_options[0].quantity;
        }

        const hydratedUpsell = {
          ...apiUpsell,
          name: shopifyProductTitle,
          quantity,
          priceCents: price,
          shopify_id: id
        };

        processedUpsells.push(hydratedUpsell);
      }

      return processedUpsells;
    },
    []
  );
}
