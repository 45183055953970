import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';

import './index.css';

import { App } from './app';

const root = document.getElementById('root')!;

Modal.setAppElement(root);

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <React.Suspense fallback={<div>Loading...</div>}>
      <App />
    </React.Suspense>
  </React.StrictMode>
);
