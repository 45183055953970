import React, { CSSProperties, ReactNode, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

interface Draggable2DItemProps {
  xTrans: number;
  yTrans: number;
  x: number;
  y: number;
  id: string;
  coordsIndex: number;
  xTranslation: number;
  yTranslation: number;
  onMouseUp(): void;
  className?: string;
  style?: CSSProperties;
  scaleFactor: number;
  children: ReactNode;
}

//Library for scrolling while dragging - https://www.npmjs.com/package/react-dnd-scrolling
// Just didn't have time to implement :(
export const Draggable2DItem = React.forwardRef<
  HTMLDivElement,
  Draggable2DItemProps
>((props, ref) => {
  const {
    xTrans,
    yTrans,
    x,
    y,
    id,
    coordsIndex,
    xTranslation,
    yTranslation,
    onMouseUp,
    className,
    style,
    scaleFactor,
    children
  } = props;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'item',
      item: {
        x,
        y,
        id,
        coordsIndex,
        xTrans,
        yTrans
      },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    }),
    [x, y, scaleFactor, xTranslation, yTranslation]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        top: `${yTrans}px`,
        left: `${xTrans}px`,
        // Hide the real image when dragging
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : '',
        ...style
      }}
    >
      <div
        ref={drag}
        data-testid="box"
        className={className}
        onMouseUp={onMouseUp}
      >
        {children}
      </div>
    </div>
  );
});
