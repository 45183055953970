import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Coordinates, LabelSlotValues, LabelSlots } from '../types';
import { BoxWithDots } from './BoxWithDots';
import clsx from 'clsx';
import { Draggable2DItem } from './dragAndDrop/Draggable2DItem';
import { CustomDragLayer } from './dragAndDrop/CustomDragLayer';

interface EditableAreaProps {
  coord: Coordinates;
  index: number;
  coordsIndex: number;
  label: LabelSlotValues;
  labelSlotsList: LabelSlotValues[];
  isOption: boolean;
  labelSlots: LabelSlots;
  scaleFactor: number;
  xTranslation: number;
  yTranslation: number;
  bboxX: number;
  bboxY: number;
  setCurrentValues: Dispatch<
    SetStateAction<{
      id: string;
      coordsIndex: number;
      x: number | string;
      y: number | string;
      height: number | string;
      width: number | string;
    }>
  >;
  setLabelSlotsList: Dispatch<SetStateAction<LabelSlotValues[]>>;
  onUpdateLabelList(updatedList: LabelSlotValues[]): void;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
}

export function EditableArea({
  coord,
  index,
  coordsIndex,
  label,
  labelSlotsList,
  isOption,
  labelSlots,
  scaleFactor,
  xTranslation,
  yTranslation,
  bboxX,
  bboxY,
  setCurrentValues,
  setLabelSlotsList,
  onUpdateLabelList,
  setSelectedIndex
}: EditableAreaProps) {
  const newRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (e: MouseEvent | TouchEvent) => {
    const isNumberInputClick =
      e.target instanceof HTMLElement && e.target.tagName === 'INPUT';

    if (
      newRef.current &&
      !newRef.current.contains(e.target as Node) &&
      !isNumberInputClick
    ) {
      setLabelSlotsList(prevLabelSlotsList => {
        const updatedSlots = prevLabelSlotsList.map(label => {
          const updatedLabelSlot = { ...label };
          updatedLabelSlot.coordinates.map(coord => {
            coord.showBorder = false;
          });
          return updatedLabelSlot;
        });
        onUpdateLabelList(updatedSlots);
        return updatedSlots;
      });

      setCurrentValues({
        id: '',
        coordsIndex: 0,
        x: 0,
        y: 0,
        height: 0,
        width: 0
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelSlots]);

  const { x, y, height, width, showBorder } = coord;
  const { system_name, label_id } = label;

  //for each label, calculate the (x,y) position relative to the bounding box and scale it
  const boxRelX = (x - bboxX) * scaleFactor;
  const boxRelY = (y - bboxY) * scaleFactor;

  //get the final (x,y position of the label)
  //add the bbox coordinates back to get the label back in the space of the
  //view box and add the vector between centers of each box
  const xTrans = boxRelX + bboxX + xTranslation;
  const yTrans = boxRelY + bboxY + yTranslation;

  return (
    <>
      {showBorder ? (
        <>
          <Draggable2DItem
            ref={newRef}
            scaleFactor={scaleFactor}
            key={label.label_id}
            x={x}
            y={y}
            xTrans={xTrans}
            yTrans={yTrans}
            id={label.label_id}
            coordsIndex={coordsIndex}
            xTranslation={xTranslation}
            yTranslation={yTranslation}
            className="relative cursor-move"
            onMouseUp={() =>
              setCurrentValues({
                id: label.label_id,
                x,
                y,
                width,
                height,
                coordsIndex: coordsIndex
              })
            }
            style={{
              height: `${height * scaleFactor}px`,
              width: `${width * scaleFactor}px`
            }}
          >
            <BoxWithDots
              key={label.label_id}
              isOption={isOption}
              style={{
                height: `${height * scaleFactor}px`,
                width: `${width * scaleFactor}px`
              }}
              onMouseUp={() => {
                setCurrentValues({
                  id: label.label_id,
                  coordsIndex: coordsIndex,
                  x,
                  y,
                  width,
                  height
                });
              }}
              onRemoveLabelFromPreview={() => {
                setLabelSlotsList(prevLabelSlotsList => {
                  const updatedLabelSlotsList = [...prevLabelSlotsList];
                  const existingCoords = [
                    ...updatedLabelSlotsList[index].coordinates
                  ];
                  existingCoords.splice(coordsIndex, 1);
                  updatedLabelSlotsList[index] = {
                    ...prevLabelSlotsList[index],
                    coordinates: existingCoords
                  };
                  onUpdateLabelList(updatedLabelSlotsList);
                  return updatedLabelSlotsList;
                });
              }}
            >
              <span className={`max-h-[${height - 25}px] overflow-y-auto`}>
                {system_name}
                {label_id.includes('-') && ` (${label_id.split('-')[1]})`}
              </span>
              {isOption && (
                <div className="flex justify-center gap-1">
                  {labelSlotsList.map((label, labelIndex) => (
                    <div
                      onMouseUp={e => e.stopPropagation()}
                      key={label.label_id}
                      onClick={e => {
                        e.stopPropagation();
                        setLabelSlotsList(prevLabelSlotsList => {
                          const updatedSlots = prevLabelSlotsList.map(
                            (label, listIndex) => {
                              const updatedLabelSlot = { ...label };
                              updatedLabelSlot.coordinates.map(coord => {
                                if (listIndex == labelIndex) {
                                  coord.showBorder = true;
                                  setCurrentValues({
                                    id: label.label_id,
                                    coordsIndex: 0,
                                    x: coord.x,
                                    y: coord.y,
                                    height: coord.height,
                                    width: coord.width
                                  });
                                } else {
                                  coord.showBorder = false;
                                }
                              });
                              return updatedLabelSlot;
                            }
                          );
                          onUpdateLabelList(updatedSlots);
                          return updatedSlots;
                        });
                        setSelectedIndex(labelIndex);
                      }}
                      className={clsx(
                        'border-navy h-2 w-2 rounded-full border',
                        labelIndex == index && 'bg-navy'
                      )}
                    ></div>
                  ))}
                </div>
              )}
            </BoxWithDots>
          </Draggable2DItem>

          <CustomDragLayer>
            <BoxWithDots
              key={label.label_id}
              isOption={isOption}
              style={{
                height: `${height * scaleFactor}px`,
                width: `${width * scaleFactor}px`,
                top: `${yTrans}px`,
                left: `${xTrans}px`
              }}
            >
              <span className={`max-h-[${height - 25}px] overflow-y-auto`}>
                {system_name}
                {label_id.includes('-') && ` (${label_id.split('-')[1]})`}
              </span>
              {isOption && (
                <div className="flex justify-center gap-1">
                  {labelSlotsList.map((label, labelIndex) => (
                    <div
                      key={label.label_id}
                      className={clsx(
                        'border-navy h-2 w-2 rounded-full border',
                        labelIndex == index && 'bg-navy'
                      )}
                    ></div>
                  ))}
                </div>
              )}
            </BoxWithDots>
          </CustomDragLayer>
        </>
      ) : (
        <div
          onMouseUp={() =>
            setCurrentValues({
              id: label.label_id,
              coordsIndex: coordsIndex,
              x,
              y,
              width,
              height
            })
          }
          onClick={() => {
            setLabelSlotsList(prevLabelSlotsList => {
              const updatedLabelSlotsList = [...prevLabelSlotsList];
              const labelSlot = {
                ...updatedLabelSlotsList[index]
              };
              const coordinates = [...labelSlot.coordinates];
              const coordinate = { ...coordinates[coordsIndex] };
              coordinate.showBorder = true;
              coordinates[coordsIndex] = coordinate;
              labelSlot.coordinates = coordinates;
              updatedLabelSlotsList[index] = labelSlot;
              return updatedLabelSlotsList;
            });
          }}
          style={{
            height: `${height * scaleFactor}px`,
            width: `${width * scaleFactor}px`,
            top: `${yTrans}px`,
            left: `${xTrans}px`,
            borderWidth: '2.5px'
          }}
          className="border-navy bg-light-blue absolute flex cursor-pointer flex-col items-center justify-between border-dashed p-2 pt-4"
        >
          <span className={`max-h-[${height}px] w-full overflow-y-auto`}>
            {system_name}
            {label_id.includes('-') && ` (${label_id.split('-')[1]})`}
          </span>
          {isOption && (
            <div className="flex justify-center gap-1">
              {labelSlotsList.map((label, labelIndex) => (
                <div
                  onMouseUp={e => e.stopPropagation()}
                  key={label.label_id}
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedIndex(labelIndex);
                  }}
                  className={clsx(
                    'border-navy h-2 w-2 rounded-full border',
                    labelIndex == index && 'bg-navy'
                  )}
                ></div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}
