import { SHOPIFY_PRODUCT_VARIANTS } from '../config';
import { Label, Palette, ShopifyProductVariant, Style } from '../types';

import { findColorVariant } from './findColorVariant';
import { findShapeVariant } from './findShapeVariant';

export function findProductVariant(
  labelShape: Label | null,
  palette: Palette | null,
  style: Style | null
) {
  const shapeVariant: ShopifyProductVariant | undefined = findShapeVariant(
    labelShape,
    style
  );

  const colorVariant: ShopifyProductVariant | undefined =
    findColorVariant(palette);

  const shapeAndColorVariant = SHOPIFY_PRODUCT_VARIANTS.find(v => {
    const targetColor = colorVariant?.color?.toLowerCase();
    const targetShape = shapeVariant?.shape?.toLowerCase();
    const color = v.color?.toLowerCase();
    const shape = v.shape?.toLowerCase();
    return color === targetColor && shape === targetShape;
  });

  return (
    shapeAndColorVariant ||
    shapeVariant ||
    colorVariant ||
    SHOPIFY_PRODUCT_VARIANTS[0]
  );
}
