import { type Font, create } from 'fontkit';
export { type Font } from 'fontkit';

type LayoutOptions = {
  value: string | string[];
  font: Font;
  width?: number;
  height: number;
  x?: number;
  y?: number;
  align_x?: number;
  align_y?: number;
  wrap?: boolean;
  ascendersPush?: boolean;
  descendersPush?: boolean;
  space?: number;
  maxSize?: number;
  curve?: number;
};

type LineSizing = {
  value: string;
  width: number;
  height: number;
  bearing: number;
  middle: number;
  top: number;
  bottom: number;
  span: number;
};

export type PreparedText = {
  value: string;
  width: number;
  height: number;
  size: number;
  x: number;
  y: number;
  rotate: number;
};

export async function createFont(
  fontData: string | Buffer | Uint8Array
): Promise<Font> {
  console.log('createFont called with fontData type:', typeof fontData);

  if (typeof fontData === 'string') {
    console.log('Fetching font from URL:', fontData);
    const fontFile = await fetch(fontData);
    const fontArrayBuffer = await fontFile.arrayBuffer();
    console.log('Font data fetched and converted to ArrayBuffer.');
    return create(new Uint8Array(fontArrayBuffer) as Buffer) as Font;
  }

  if (Buffer.isBuffer(fontData)) {
    console.log('Font data is already a Buffer.');
    return create(fontData) as Font;
  }

  console.log('Font data is Uint8Array, converting to Buffer.');
  return create(new Uint8Array(fontData) as Buffer) as Font;
}

function wrapText(value: string | string[], wrap: boolean) {
  let textLines;
  if (Array.isArray(value)) {
    textLines = value;
  } else if (value.includes('\n')) {
    const lines = value.trim().split('\n');
    if (wrap) {
      textLines = lines;
    } else {
      textLines = [lines.join(' ')];
    }
  } else {
    value = value.trim();
    // We used to split on the first space, but this was requested to be removed.
    // the code is left here in case we want to add it back in the future.
    //
    // const firstSpace = value.indexOf(' ', 1);
    // if (wrap && firstSpace >= 0 && firstSpace < value.length - 1) {
    //   textLines = [value.slice(0, firstSpace), value.slice(firstSpace + 1)];
    // } else {
    //   textLines = [value];
    // }
    textLines = [value];
  }
  return textLines.map(line => line.trim());
}

function getBbox(font: Font, line: string) {
  const layout = font.layout(line);
  try {
    const bbox = layout.bbox;
    return {
      width: bbox.width,
      height: bbox.height,
      maxY: bbox.maxY,
      minY: bbox.minY,
      minX: bbox.minX,
      layout
    };
  } catch {
    // There is a bug in fontkit where it does not find the bbox of some fonts (particularly color fonts)
    // This just approximates the bbox
    const maxY = font.capHeight;
    let hasDescender = /[gjpqG]/g.test(line);
    const space = font.ascent - font.capHeight;
    hasDescender = hasDescender && font.descent + space < 0;
    // Guess the descent location, it is typically too negative, this seems to work well
    const minY = hasDescender ? font.descent * 0.6 : 0;
    return {
      width: layout.advanceWidth,
      height: maxY - minY,
      maxY,
      minY,
      minX: 0,
      layout
    };
  }
}

function replaceUnknownGlyphs(line: string, font: Font) {
  const layout = font.layout(line);
  let str = '';
  for (const glyph of layout.glyphs) {
    const codePoints = glyph.codePoints;
    if (codePoints.some(point => !font.hasGlyphForCodePoint(point))) {
      // Replacement character U+FFFD https://en.wikipedia.org/wiki/Specials_(Unicode_block)
      str += '?';
    } else {
      str += String.fromCodePoint(...glyph.codePoints);
    }
  }
  return str;
}

function curveText(
  font: Font,
  line: PreparedText,
  width: number,
  curve: number
): PreparedText[] {
  const value = line.value;
  const layout = font.layout(value);
  const ratio = line.size / font.unitsPerEm;
  // Pivot lower around the letters, so they overlap less
  const pivot = line.height * 0.15;
  let offset = 0;
  // calculate info about each glyph
  const glyphs = layout.glyphs
    .map(glyph => {
      const value = String.fromCodePoint(...glyph.codePoints);
      const width = glyph.bbox.width * ratio || line.size;
      const x1 = glyph.bbox.minX * ratio || 0;
      const x2 = glyph.bbox.maxX * ratio || line.size;
      const center = (x1 + x2) / 2;
      const res = {
        x: -center,
        y: line.y + pivot,
        w: width,
        offset: offset + center,
        value
      };
      offset += glyph.advanceWidth * ratio;
      return res;
    })
    .filter(glyph => glyph.value.trim());
  // angle the arc takes up
  const theta = (Math.max(-50, Math.min(curve, 50)) / 100) * Math.PI;
  // radius of the arc
  const radius = width / 2 / Math.sin(theta) - pivot;
  // how far to move everything so the ends of the arc are at y = 0
  const yOffset = -Math.cos(theta) * radius;
  return glyphs.map(({ x, y, w, offset, value }) => {
    const radians = (offset + line.x) / radius;
    const sin = Math.sin(radians);
    const cos = Math.cos(radians);
    // rotate the lower left corner of the letter
    const letter = {
      x: cos * x + sin * y,
      y: cos * y - sin * x
    };
    // where the letter lies on the arc
    const pos = {
      x: sin * radius,
      y: cos * radius
    };
    // final positioning info for this glyph
    return {
      value,
      x: pos.x + letter.x,
      y: pos.y + letter.y + yOffset,
      width: w,
      height: line.height,
      size: line.size,
      rotate: (radians * 180) / Math.PI
    };
  });
}

export function layoutText({
  value,
  font,
  width,
  height,
  align_x = 0,
  align_y = 0,
  wrap = true,
  ascendersPush = true,
  descendersPush = false,
  space = 0.2,
  maxSize = Infinity,
  curve = 0
}: LayoutOptions): PreparedText[] {
  if (curve) {
    wrap = false;
  }
  const euclidWidth = width ?? 0;
  if (curve !== 0 && width !== undefined) {
    const w2 = width / 2;
    const abscurve = Math.abs(curve) / 100;
    const theta = Math.PI * abscurve;
    const r = abscurve > 0.5 ? w2 : w2 / Math.sin(theta);
    width = r * Math.PI * 2 * abscurve;
  }
  let textLines = wrapText(value, wrap);
  textLines = textLines.map(line => replaceUnknownGlyphs(line, font));

  let maxTextWidth = 0;
  const lines: LineSizing[] = [];
  const unitsPerEm = font.unitsPerEm;
  let xHeight = font.xHeight;
  if (!xHeight) {
    const xGlyph = font.layout('x');
    xHeight = xGlyph.glyphs[0].cbox.height;
  }
  const fontMiddle = xHeight / 2 / unitsPerEm;
  textLines.forEach(line => {
    const bbox = getBbox(font, line);
    const textWidth = bbox.width / unitsPerEm;
    maxTextWidth = Math.max(textWidth, maxTextWidth);
    const top = bbox.maxY / unitsPerEm - fontMiddle;
    const bottom = bbox.minY / unitsPerEm - fontMiddle;
    const boxTop = descendersPush ? top : Math.max(top, -bottom);
    const boxBottom = ascendersPush ? bottom : Math.min(bottom, -top);
    const middle = (boxTop + boxBottom) / 2 + fontMiddle;
    const span = boxTop - boxBottom;

    lines.push({
      value: line,
      width: textWidth,
      height: bbox.height / unitsPerEm,
      bearing: bbox.minX / unitsPerEm,
      middle,
      span,
      top: top + fontMiddle,
      bottom: bottom + fontMiddle
    });
  });

  if (!width) {
    width = maxTextWidth * height;
  }
  const xSize = (maxTextWidth * height) / width;

  const length = lines.length;
  const last = length - 1;
  const firstLine = lines[0];
  const lastLine = lines[last];

  let step = 0;
  let span = firstLine.span;
  let size = Math.min(height / Math.max(span, xSize), maxSize);
  let yOffset = [
    height / 2 - firstLine.top * size,
    -firstLine.middle * size,
    -firstLine.bottom * size - height / 2
  ][align_y + 1];
  if (last) {
    const spacing = lines.slice(1).reduce((acc, line, i) => {
      return Math.max(acc, line.top - lines[i].bottom);
    }, 0);
    step = spacing * (1 + space);
    const top = firstLine.top;
    const bottom = lastLine.bottom;
    span = last * step + top - bottom;
    size = Math.min(height / Math.max(span, xSize), maxSize);
    yOffset = [
      height / 2 - firstLine.top * size,
      (span * size) / 2 - firstLine.top * size,
      span * size - firstLine.top - height / 2
    ][align_y + 1];
  }

  return lines.flatMap((line, i) => {
    const w = line.width * size;
    const xOffset = [-width! / 2, -w / 2, width! / 2 - w][align_x + 1];
    const prepared = {
      value: line.value,
      width: line.width * size,
      height: line.span * size,
      size,
      x: xOffset - line.bearing * size,
      y: yOffset - i * step * size,
      rotate: 0
    };
    return curve && width
      ? curveText(font, prepared, euclidWidth, curve)
      : [prepared];
  });
}
