import { ProductClassifications, SelectionVdpCodes } from '../types';

export function generateLegacyProductCode(
  vdpCodes: SelectionVdpCodes,
  classification: ProductClassifications
) {
  const isUpsell = classification === ProductClassifications.UPSELL;

  const { productTypeCode, categoryCode } = getTypeAndCategoryCodes(
    isUpsell,
    classification,
    vdpCodes
  );

  const styleCode = vdpCodes.style || '0000000';
  const palletCode = vdpCodes.palette || 'ZZ';
  const iconCode = vdpCodes.icon || 'ZZZZ';
  const fontCode = vdpCodes.font || 'ZZ';
  const shapeCode = isUpsell ? 'ZZZ' : vdpCodes.labelVariant || 'ZZZ';
  const shoeCode = getShoeCode(vdpCodes.shoe, isUpsell);
  const laminationCode = getLaminationCode(vdpCodes.material, isUpsell);

  return [
    productTypeCode,
    categoryCode,
    styleCode,
    palletCode,
    iconCode,
    fontCode,
    shapeCode,
    shoeCode,
    laminationCode
  ].join('');
}

function getTypeAndCategoryCodes(
  isUpsell: boolean,
  classification: ProductClassifications,
  vdpCodes: { product: string | null; label: string | null }
) {
  let productTypeCode = isUpsell ? 'NUPS' : 'ZZZZ';
  let categoryCode = 'ZZZ';

  switch (classification) {
    case ProductClassifications.PACK:
      if (!vdpCodes.product) return { productTypeCode, categoryCode };
      productTypeCode = vdpCodes.product.slice(0, 4);
      categoryCode = vdpCodes.product.slice(4, 7);
      break;

    case ProductClassifications.NON_PACK:
      productTypeCode = vdpCodes.product || 'ZZZZ';
      categoryCode = vdpCodes.label || 'ZZZ';
      break;

    case ProductClassifications.UPSELL:
      categoryCode = vdpCodes.label || 'ZZZ';
      break;
  }

  return { productTypeCode, categoryCode };
}

function getShoeCode(shoeCode: string | null, isUpsell: boolean) {
  if (!isUpsell && shoeCode) return shoeCode;
  return 'Z';
}

function getLaminationCode(materialCode: string | null, isUpsell: boolean) {
  if (!materialCode) return 'ZZZZ';

  const isLaminated = materialCode === 'LZZZ';
  if (isUpsell && isLaminated) return 'ZZZZ';

  const isUnlaminated = materialCode === 'UZZZ';
  const hadChoice = isLaminated || isUnlaminated;
  return hadChoice ? materialCode : 'ZZZZ';
}
