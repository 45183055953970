import clsx from 'clsx';
import { ChevronIcon } from './icons';

interface PagerProps {
  size?: 'large' | 'small';
  onBack: () => void;
  onForward: () => void;
  currentPage: number;
  totalPages: number;
}
export default function Pager({
  size = 'large',
  currentPage,
  onBack,
  onForward,
  totalPages
}: PagerProps) {
  return (
    <div className="align-center col-span-2 flex justify-around">
      <ArrowButton
        size={size}
        direction="left"
        hide={currentPage < 1}
        onClick={onBack}
      />{' '}
      <PagerIndicator currentPage={currentPage} totalPages={totalPages} />
      <ArrowButton
        size={size}
        direction="right"
        hide={isNaN(totalPages) || currentPage >= totalPages - 1}
        onClick={onForward}
      />
    </div>
  );
}

interface ArrowButtonProps {
  size: 'large' | 'small';
  direction: 'right' | 'left';
  hide: boolean;
  onClick: () => void;
}
function ArrowButton({ size, hide, direction, onClick }: ArrowButtonProps) {
  const chevronSize = size == 'large' ? '24px' : '20px';

  return (
    <button
      className={clsx(
        // static and mr-0 are to counteract page styles on invisible.
        `bg-navy align-center static mr-0 flex items-center justify-center rounded-full text-white`,
        {
          'h-11 w-11 min-w-11': size == 'large',
          'h-8 w-8 min-w-8': size == 'small',
          invisible: hide
        }
      )}
      onClick={onClick}
      disabled={hide}
    >
      <ChevronIcon
        width={chevronSize}
        height={chevronSize}
        direction={direction}
      />
    </button>
  );
}

interface PagerIndicatorProps {
  totalPages: number;
  currentPage: number;
}
export function PagerIndicator({
  totalPages,
  currentPage
}: PagerIndicatorProps) {
  if (totalPages < 2) return <div className="grow"></div>;
  const dots = Array.from({ length: totalPages }, (_, i) => i);

  return (
    <div className="mx-3 flex grow items-center justify-center overflow-hidden">
      {dots.map(i => {
        return (
          <span
            key={'pager' + i}
            className={clsx(
              'border-navy rounded-full border border-solid transition-all',
              {
                'bg-navy m-1 h-3 w-3 min-w-3': i == currentPage,
                'm-0.5 h-2.5 w-2.5 min-w-2.5 bg-white':
                  Math.abs(currentPage - i) == 1,
                'm-0.5 h-2 w-2 min-w-2 border-opacity-75':
                  Math.abs(currentPage - i) == 2,
                'm-0.5 h-1.5 w-1.5 min-w-1.5 border-opacity-50':
                  Math.abs(currentPage - i) == 3,
                'm-px h-0.5 w-0.5 min-w-0.5 border-opacity-10 ':
                  Math.abs(currentPage - i) == 4,
                'm-0 h-0 w-0 min-w-0 border-opacity-0':
                  Math.abs(currentPage - i) == 5,
                hidden: Math.abs(currentPage - i) > 5
              }
            )}
          ></span>
        );
      })}
    </div>
  );
}
