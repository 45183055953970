import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTRPCRequest } from '../../../hooks';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';

export function GeneratePdfsByCartItem() {
  const params = useParams();
  const { handleTRPCRequest } = useTRPCRequest();

  const generatePdf = useCallback(async () => {
    await handleTRPCRequest({
      method: TRPCMethodEnum.get,
      resourceType: TRPCResourceEnum.pdfs,
      requestBody: {
        cart_item_id: params.cart_item_id
      }
    });
  }, [params.cart_item_id, handleTRPCRequest]);

  useEffect(() => {
    generatePdf();
  }, [generatePdf]);

  return null;
}
