import Joi from 'joi';

const createFontSchema = Joi.object({
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  weight: Joi.number()
    .integer()
    .min(1)
    .max(1000)
    .cast('string')
    .label('Weight')
    .required(),
  google_family: Joi.string().label('Family').required(),
  google_version: Joi.string().label('Version').required(),
  google_variant: Joi.string()
    .label('Variant')
    .messages({ 'string.empty': `"Font variant" is required` })
    .required(),
  font_file_path: Joi.string().label('File Path').optional(),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code')
});

const updateFontSchema = Joi.object({
  font_id: Joi.string().label('Font Id').required(),
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  google_variant: Joi.string().label('Variant').required(),
  weight: Joi.number()
    .integer()
    .min(1)
    .max(1000)
    .cast('string')
    .label('Weight')
    .required(),
  font_file_path: Joi.string().label('File Path').optional(),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code')
});

export default {
  createFontSchema,
  updateFontSchema
};
