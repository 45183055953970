import { Link } from 'react-router-dom';
import logo from '../../assets/NameBubblesLogoSVG.svg';

export function Logo() {
  return (
    <Link className="inline" to="/">
      <img className="mr-[45px] w-[120px]" src={logo} alt="Name Bubbles Logo" />
    </Link>
  );
}
