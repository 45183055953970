import Joi from 'joi';

// Note: the order of fields in the `Joi` object is the order any errors
// will be displayed on the page.
const createStyleSchema = Joi.object({
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  thumbnail: Joi.string().label('Thumbnail').required(),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code'),
  registered_entities: Joi.object({
    icons: Joi.array().label('Icon Entities').min(1).required(),
    fonts: Joi.array().label('Font Entities').min(1).required(),
    palettes: Joi.array().label('Palettes Entities').min(1).required()
  }).required()
});

const updateStyleSchema = Joi.object({
  style_id: Joi.string().label('Style Id').required(),
  system_name: Joi.string().label('System Name').required(),
  display_name: Joi.string().label('Display Name').required(),
  thumbnail: Joi.string().allow(''),
  vdpcode: Joi.string().optional().allow(null).label('VDP Code'),
  registered_entities: Joi.object({
    icons: Joi.array().label('Icon Entities').min(1).required(),
    fonts: Joi.array().label('Font Entities').min(1).required(),
    palettes: Joi.array().label('Palettes Entities').min(1).required()
  }).required()
});

export default {
  createStyleSchema,
  updateStyleSchema
};
