import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import Pager from '../../components/Pager';
import { Tooltip } from 'react-tooltip';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import {
  selectLabelShape,
  selectProductTag,
  selectStyle,
  updateLabelShape
} from '../item/store/itemSlice';
import {
  PAGE_TYPE,
  selectLabelShapePage,
  setPage
} from '../accordion/accordionSlice';
import { useGetProductQuery } from '../api/store/apiSlice';
import { Label } from '../../types/';
import { tooltipClasses } from '../../components/grids/GridElement';

const DEFAULT_LABEL_SHAPES_TO_DISPLAY = 4;

export const labels = [
  {
    display_name: 'Circle',
    shape: (
      <div className="border-dark-gray h-14 w-14 rounded-full border-2 border-solid bg-gray-200" />
    )
  },
  {
    display_name: 'Square',
    shape: (
      <div className="border-dark-gray h-14 w-14 rounded border-2 border-solid bg-gray-200" />
    )
  },
  {
    display_name: 'Rectangle',
    shape: (
      <div className="border-dark-gray h-9 w-14 rounded border-2 border-solid bg-gray-200" />
    )
  },
  {
    display_name: 'Oblong',
    shape: (
      <div className="border-dark-gray h-5 w-14 rounded border-2 border-solid bg-gray-200" />
    )
  }
];
export default function Labels() {
  const dispatch = useConfiguratorDispatch();

  const productId = useRootSelector(selectProductTag);
  const labelShapePage = useRootSelector(selectLabelShapePage);

  const selectedStyle = useRootSelector(selectStyle);
  const { data } = useGetProductQuery(productId);

  const shapes = useMemo(() => {
    const labels = data?.labels?.filter(l =>
      l.variants.some(v => v.style_id === selectedStyle?.style_id)
    );
    return labels;
  }, [data?.labels, selectedStyle?.style_id]);

  const totalPages =
    Math.ceil((shapes?.length ?? 0) / DEFAULT_LABEL_SHAPES_TO_DISPLAY) ?? 0;

  const selectedShape = useRootSelector(selectLabelShape);

  const [shapeSlice, setShapeSlice] = useState<Label[]>([]);

  useEffect(() => {
    const start = labelShapePage * DEFAULT_LABEL_SHAPES_TO_DISPLAY;
    const end = start + DEFAULT_LABEL_SHAPES_TO_DISPLAY;
    setShapeSlice(shapes?.slice(start, end) ?? []);
  }, [shapes, labelShapePage]);

  return (
    <>
      <ol className="grid grid-cols-4 items-center gap-2.5 py-2.5">
        {shapeSlice.map(shape => (
          <li
            key={shape.label_id}
            className={clsx(
              'items-stretch rounded-md border-2 border-solid',
              shape == selectedShape ? 'border-navy' : 'border-transparent'
            )}
          >
            <div
              className={clsx(
                'align-center m-px flex justify-center rounded-md border border-solid border-gray-400',
                { 'bg-gray-200': shape == selectedShape }
              )}
              onClick={() => dispatch(updateLabelShape(shape.label_id))}
            >
              <Tooltip id="shape-tooltip" className={tooltipClasses} />
              <img
                src={shape.thumbnail_path}
                className="box-content h-[60px] w-[60px] rounded object-scale-down object-center"
                data-tooltip-id="style-tooltip"
                data-tooltip-content={shape.display_name}
                data-tooltip-place="top"
              />
            </div>
          </li>
        ))}
      </ol>
      <div className="grid grid-cols-3 items-center pb-2.5">
        <span className="text-dark-gray truncate text-sm font-semibold">
          {selectedShape?.display_name}
        </span>
        <Pager
          size="small"
          totalPages={totalPages}
          currentPage={labelShapePage}
          onBack={() =>
            dispatch(
              setPage({
                page: PAGE_TYPE.labelShape,
                pageNumber: Math.max(0, labelShapePage - 1)
              })
            )
          }
          onForward={() =>
            dispatch(
              setPage({
                page: PAGE_TYPE.labelShape,
                pageNumber: Math.min(labelShapePage + 1, totalPages - 1)
              })
            )
          }
        />
        <div />
      </div>
    </>
  );
}
