import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  submenus: {
    href: string;
    label: string;
  }[];
}

export function Dropdown({ open, setOpen, submenus }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const SubmenuLinks = submenus.map(({ href, label }) => {
    const active = href.toLocaleLowerCase() === pathname.toLocaleLowerCase();

    return (
      <li className="relative" key={href}>
        <a
          href={href}
          rel="noreferrer noopener"
          className={clsx(
            'block cursor-pointer px-[1rem] py-[0.7rem] text-left',
            { 'text-navy': active }
          )}
          key={href}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (
              href &&
              href.toLocaleLowerCase() !== pathname.toLocaleLowerCase()
            ) {
              window.localStorage.removeItem(href);
              event.preventDefault();
              navigate(href);
            } else if (active) {
              // if href matches the path of the current page, then we need to
              // force a refresh of the page.
              window.location.reload();
            }
          }}
        >
          <h4>{label}</h4>
        </a>
      </li>
    );
  });

  return (
    <ul
      className={clsx(
        'z-9999 card absolute left-0 right-auto top-full min-w-[10rem] list-none px-0 py-[0.5rem]',
        { hidden: !open }
      )}
      onClick={() => setOpen(false)}
    >
      {SubmenuLinks}
    </ul>
  );
}
