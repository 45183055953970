import { Font } from '..';

const loadedFonts = new Set();

export type LoadFont = {
  font_id: string;
  url: string;
  family: string;
  weight?: string;
  style?: string;
};

export function loadFont(font: LoadFont) {
  const id = `${font.family};${font.url}`;
  if (loadedFonts.has(id)) {
    return;
  }

  const newFont = new FontFace(font.family + font.font_id, `url(${font.url})`, {
    weight: font.weight ?? '400',
    style: font.style || 'normal'
  });
  document.fonts.add(newFont);
  loadedFonts.add(id);
}

export function setPageFonts(fonts: Font[]) {
  document.fonts.clear();
  loadedFonts.clear();
  fonts
    .map(({ font_id, google_family, font_file_path, weight, style }) => {
      loadFont({
        font_id,
        family: google_family,
        url: font_file_path,
        style,
        weight
      });
      return new FontFace(google_family + font_id, `url(${font_file_path})`, {
        weight
      });
    })
    .forEach(fontFace => {
      document.fonts.add(fontFace);
    });
}
