import { ConfiguratorConfig } from './types';

// These variables are defined in the shopify theme
declare global {
  // eslint-disable-next-line no-var
  var Configurator: ConfiguratorConfig | undefined;
}

const {
  VITE_STAGE,
  VITE_APP_API_URL,
  VITE_APP_CUSTOMER_API_URL,
  VITE_PRODUCT_TAG,
  VITE_PRODUCT_PRICE_CENTS,
  VITE_PRODUCT_NAME
} = import.meta.env;

export const STAGE = VITE_STAGE;

export const API_URL = window?.Configurator?.API_URL ?? VITE_APP_API_URL;
export const CUSTOMER_API_URL =
  window?.Configurator?.CUSTOMER_API_URL ??
  VITE_APP_CUSTOMER_API_URL ??
  API_URL;

export const SHOPIFY_PRODUCT_TAG =
  window?.Configurator?.PRODUCT_TAG ?? VITE_PRODUCT_TAG;

export const SHOPIFY_PRODUCT_PRICE_CENTS =
  window?.Configurator?.PRODUCT_PRICE_CENTS ?? VITE_PRODUCT_PRICE_CENTS;

export const SHOPIFY_PRODUCT_NAME =
  window?.Configurator?.PRODUCT_NAME ?? VITE_PRODUCT_NAME;

export const IN_PROD = !!window?.Configurator?.IN_PROD;

export const SHOPIFY_PRODUCT_VARIANTS =
  window?.Configurator?.PRODUCT_VARIANTS ?? [];

// if the store does not have an 'upsells' collection, PRODUCT_UPSELLS will be
// an empty string.
export const SHOPIFY_UPSELL_PRODUCTS = Array.isArray(
  window?.Configurator?.PRODUCT_UPSELLS
)
  ? window?.Configurator?.PRODUCT_UPSELLS
  : [];

export const SHOPIFY_UPSELL_VARIANTS = SHOPIFY_UPSELL_PRODUCTS.flatMap(
  shopifyUpsellProduct => {
    return shopifyUpsellProduct.variants.map(variant => {
      variant.title = shopifyUpsellProduct.title;
      return variant;
    });
  }
);
