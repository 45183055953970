import { useCallback } from 'react';

import { useConfiguratorDispatch } from '../../../store';
import { Material } from '../../../types';
import { ContainedButton } from '../../../components/buttons';

import { UpsellProduct } from '../types';
import { addUpsell } from '../store/upsellsSlice';

interface AddUpsellButtonProps {
  upsell: UpsellProduct;
  selectedMaterial: Material;
}

export function AddUpsellButton({
  upsell,
  selectedMaterial
}: AddUpsellButtonProps) {
  const dispatch = useConfiguratorDispatch();
  const handleClick = useCallback(() => {
    dispatch(
      addUpsell({
        upsell,
        material: selectedMaterial
      })
    );
  }, [dispatch, upsell, selectedMaterial]);

  return <ContainedButton onClick={handleClick}>Add</ContainedButton>;
}
