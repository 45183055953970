import clsx from 'clsx';
import { Tooltip } from 'react-tooltip';
import {
  PAGE_TYPE,
  openSeeAll,
  selectColorsPage,
  setPage
} from '../accordion/accordionSlice';
import { selectPalette, setPalette } from '../item/store/itemSlice';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import Pager from '../../components/Pager';
import { Palette } from '../../types';
import { useEffect, useState } from 'react';
import { useStyleAndColor } from './StyleAndColorHooks';
import { useMediaQuery } from 'react-responsive';
import { tooltipClasses } from '../../components/grids/GridElement';

const ColorSkeleton = (colorsToDisplay: number) => (
  <ol
    role="status"
    className="grid animate-pulse grid-cols-6 justify-items-center gap-4 pb-2.5"
  >
    {Array.from({ length: colorsToDisplay }).map((_, i) => (
      <li
        key={i}
        className={clsx('rounded-md border-2 border-solid border-transparent')}
      >
        <div className="m-px h-8 w-8 rounded bg-gray-300"></div>
      </li>
    ))}
  </ol>
);

export default function Colors() {
  const dispatch = useConfiguratorDispatch();
  const colorsPage = useRootSelector(selectColorsPage);

  const [colorsToDisplay, setColorsToDisplay] = useState(6);

  const isMobileView = useMediaQuery({ maxWidth: 639 });

  useEffect(() => {
    if (isMobileView) {
      setColorsToDisplay(6);
    } else {
      setColorsToDisplay(8);
    }
  }, [isMobileView]);

  const {
    palettes: colors,
    isLoading,
    isSuccess,
    isError,
    error
  } = useStyleAndColor();

  const selectedColor = useRootSelector(selectPalette);

  const totalPages = Math.ceil(colors?.length / colorsToDisplay) ?? 0;

  const [colorSlice, setColorSlice] = useState<Palette[]>([]);

  useEffect(() => {
    const start = colorsPage * colorsToDisplay;
    const end = start + colorsToDisplay;
    setColorSlice(colors?.slice(start, end) ?? []);
  }, [colors, colorsPage, colorsToDisplay]);

  return (
    <>
      <div className="flex items-center justify-between gap-2.5 py-2.5">
        {isLoading && ColorSkeleton(colorsToDisplay)}
        {isError && (
          <>
            <h1>There was an error loading colors</h1> {console.log(error)}
          </>
        )}
        {isSuccess && (
          <ol
            className={clsx(
              'grid grid-rows-[38px] gap-2.5',
              `grid-cols-6 sm:grid-cols-8`
            )}
          >
            {colorSlice?.map(color => (
              <li
                key={color.palette_id}
                className={clsx(
                  'box-border rounded-md border-2 border-solid bg-clip-border',
                  color == selectedColor ? 'border-navy ' : 'border-transparent'
                )}
              >
                <Tooltip id="color-tooltip" className={tooltipClasses} />
                <img
                  src={color.thumbnail_path}
                  className="m-px box-border h-8 w-8 rounded border border-solid border-gray-400 object-scale-down object-center"
                  style={{
                    background: color.color_slots[0] ?? 'white'
                  }}
                  onClick={() => dispatch(setPalette(color))}
                  data-tooltip-id="color-tooltip"
                  data-tooltip-content={color.display_name}
                  data-tooltip-place="top"
                />
              </li>
            ))}
          </ol>
        )}
        <button
          onClick={() => dispatch(openSeeAll('Colors'))}
          className="border-primary text-primary border-1 min-w-fit rounded-full border-solid px-2.5 py-1.5 font-semibold"
        >
          See all
        </button>
      </div>

      <div className="grid min-h-10 grid-cols-4 items-center pb-2.5">
        <span className="text-dark-gray truncate text-sm font-semibold">
          {selectedColor?.display_name ?? ''}
        </span>
        <Pager
          size="small"
          totalPages={totalPages}
          currentPage={colorsPage}
          onBack={() =>
            dispatch(
              setPage({
                page: PAGE_TYPE.color,
                pageNumber: Math.max(0, colorsPage - 1)
              })
            )
          }
          onForward={() =>
            dispatch(
              setPage({
                page: PAGE_TYPE.color,
                pageNumber: Math.min(colorsPage + 1, totalPages - 1)
              })
            )
          }
        />
      </div>
    </>
  );
}
