import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Product } from '../../../types';
import { API_URL, CUSTOMER_API_URL } from '../../../config';

import { UpsellProduct } from '../../upsells/types';

// Single API slice object.
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: CUSTOMER_API_URL || API_URL }),
  endpoints: builder => ({
    getProduct: builder.query<Product, string>({
      query: productTag => `/products/${productTag}`,
      transformResponse: async (response: { url: string }) => {
        // Return the data loaded from S3
        const res = await fetch(response.url);
        const data = await res.json();
        return data;
      }
    }),

    getUpsells: builder.query<UpsellProduct[], string>({
      query: productId => `/products/upsells/${productId}`,
      transformResponse: async (response: { url: string }) => {
        // Return the data loaded from S3
        const res = await fetch(response.url);
        const data = await res.json();
        return data;
      }
    })
  })
});

export const { useGetProductQuery, useGetUpsellsQuery } = apiSlice;
