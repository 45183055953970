import { PlusIcon } from '../../../components/icons';
import { useStyles } from '../../styles/routes/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LabelVariantEditor } from './settings/LabelVariantEditor';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { LabelVariant } from '../../../api/trpcApi/services/labels/types';
import { TRPCMethodEnum, TRPCResourceEnum } from '../../../api/trpcApi/types';
import { useTRPCRequest } from '../../../hooks';
import { ErrorValue } from '../../../utils/validationUtils';
import { Checkbox } from '../../../components/inputs';
import { LoadingDiv } from '../../../components/loading';
import { DropdownOption } from './settings/DropdownOption';
import { toast } from 'react-toastify';
import { cleanVariant } from './settings/variantUtils';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { ModalComponent } from '../../../components/modals';
import { ContainedButton } from '../../../components/buttons';
import { UpdateValue, clearUnused, useUpdater } from './settings/state/update';
import { variantToVariantState } from '../types/convert';
import {
  LabelUpdater,
  LabelUpdaterData,
  LabelVariantState
} from '../types/state';

type StateType = Record<string, LabelVariantState>;

interface LabelVariantsProps {
  labelId?: string;
  value?: StateType;
  errors?: ErrorValue<StateType>;
  duplicate?: boolean;
  iterative_style_ids?: string[];
  iterative_defaults?: Record<string, string> | undefined;
  onUpdate: LabelUpdater<StateType>;
  toggleIterative: (style_id: string) => () => void;
  setIterativeDefault: (style_id: string, variant_id: string) => void;
}

function newVariantId() {
  return uniqueId('label_variant');
}

export function LabelVariants({
  labelId,
  value = {},
  errors,
  duplicate,
  iterative_style_ids,
  iterative_defaults,
  onUpdate,
  toggleIterative,
  setIterativeDefault
}: LabelVariantsProps) {
  const [selectedVariantId, setSelectedVariantId] = useState('');
  const [variantToSelect, setVariantToSelect] = useState<string>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { update } = useUpdater(onUpdate, {
    before: (_value: UpdateValue<StateType>, data?: LabelUpdaterData) => {
      if (!data?.sideEffect) {
        setUnsavedChanges(!data?.save);
      }
    }
  });

  const { styles: allStyles } = useStyles();
  const styleNames = useMemo(
    () => new Map(allStyles?.map(s => [s.style_id, s.system_name])),
    [allStyles]
  );

  const orderedVariantMap = useMemo(() => {
    return new Map(
      Object.entries(value).sort(([, a], [, b]) => {
        const aStyleName = styleNames.get(a.style_id) ?? '';
        const bStyleName = styleNames.get(b.style_id) ?? '';
        return (
          aStyleName.localeCompare(bStyleName) ||
          a.system_name.localeCompare(b.system_name)
        );
      })
    );
  }, [styleNames, value]);

  const allVariants = useMemo(
    () => Array.from(orderedVariantMap.values()),
    [orderedVariantMap]
  );

  const currentOrder =
    allVariants[allVariants.length - 1]?.order ?? allVariants.length - 1;

  const { handleTRPCRequest } = useTRPCRequest();
  const fetchVariants = useCallback(
    async (clear = true) => {
      if (!labelId) return;
      setIsLoading(clear);
      const { res }: { res: LabelVariant[] } = await handleTRPCRequest({
        method: TRPCMethodEnum.list,
        resourceType: TRPCResourceEnum.labelVariants,
        requestBody: { label_id: labelId }
      });
      const newVariants = Object.fromEntries(
        res.map(variant => [
          variant.label_variant_id,
          cleanVariant(variantToVariantState(variant))
        ])
      );
      onUpdate(clearUnused(value, newVariants), { save: true });
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [labelId, duplicate]
  );

  useEffect(() => {
    fetchVariants();
  }, [fetchVariants]);

  useEffect(() => {
    const id = Object.keys(value)[0];
    if (id && !(selectedVariantId && selectedVariantId in value)) {
      setSelectedVariantId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariantId, selectedVariantId in value, Object.keys(value)[0]]);

  const groupedVariants = useMemo(() => {
    const variants = new Map<string, string[]>();
    orderedVariantMap.forEach((variant, key) => {
      const style = variant.style_id;
      variants.set(style, variants.get(style)?.concat([key]) ?? [key]);
    });

    return variants;
  }, [orderedVariantMap]);
  const styles = isLoading ? [] : Array.from(groupedVariants.keys());

  const newVariantName = useCallback(
    (style_id: string) => {
      const usedNames = new Set(
        allVariants.filter(v => v.style_id === style_id).map(v => v.system_name)
      );
      for (let i = 1; ; i++) {
        const name = `Variant ${i}`;
        if (!usedNames.has(name)) return name;
      }
    },
    [allVariants]
  );

  const selectedVariant = value[selectedVariantId ?? ''];

  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  return (
    <div className="flex">
      <div
        className="flex min-w-64 flex-col items-stretch gap-2 px-2 py-4"
        ref={ref}
      >
        <div>
          <button
            className={clsx(
              'text-input-shadow flex items-center justify-between gap-4 rounded-lg p-3',
              errors?.error &&
                errors?.value === value &&
                'border-error border-2'
            )}
            onClick={() => {
              if (!allStyles?.length) {
                toast.error('Please create a style');
                return;
              }
              setOpen(!open);
            }}
          >
            Add Label Variant
            <div className="text-aqua">
              <PlusIcon />
            </div>
          </button>
          {open && (
            <div className="relative w-full">
              <div className="text-input-shadow absolute top-2 z-10 flex max-h-[25rem] w-full flex-col overflow-y-auto rounded-lg bg-white">
                {allStyles.map(style => {
                  const styleName = styleNames.get(style.style_id) ?? 'Unknown';
                  const id = style.style_id ?? '';
                  const dummyId = newVariantId();
                  return (
                    <DropdownOption
                      key={id}
                      onClick={() => {
                        onUpdate({
                          [dummyId]: {
                            order: currentOrder + 1,
                            system_name: newVariantName(id),
                            style_id: id,
                            width: 1,
                            height: 1,
                            layers: {},
                            text_fields: {},
                            icons: {},
                            thumbnail: ''
                          }
                        });
                        setOpen(false);
                      }}
                    >
                      {styleName}
                    </DropdownOption>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {isLoading && <LoadingDiv />}
        <div className="flex max-h-[25rem] w-full flex-col overflow-y-auto">
          {styles.map(s => {
            return (
              <React.Fragment key={s}>
                <div className="flex justify-between">
                  {styleNames.get(s) ?? 'No Style'}
                  {(groupedVariants.get(s)?.length ?? 0) > 1 ? (
                    <div className="flex items-center text-xs">
                      <Checkbox
                        checked={Boolean(
                          iterative_style_ids?.find(id => s === id)
                        )}
                        onChange={toggleIterative(s)}
                      />
                      Iterative
                    </div>
                  ) : null}
                </div>
                {groupedVariants.get(s)?.map(key => (
                  <div className="flex items-center" key={key}>
                    <input
                      type="radio"
                      name={s}
                      className={clsx(
                        "text-lighter-gray checked:text-light-gray appearance-none outline-none before:content-['☆'] checked:before:content-['★']",
                        {
                          invisible: !iterative_style_ids?.find(id => s === id)
                        }
                      )}
                      checked={iterative_defaults?.[s] == key}
                      onChange={() => {
                        setIterativeDefault(s, key);
                      }}
                    />
                    <button
                      onClick={() => {
                        if (
                          unsavedChanges &&
                          selectedVariant?.label_variant_id
                        ) {
                          setVariantToSelect(key);
                        } else {
                          setSelectedVariantId(key);
                          setUnsavedChanges(false);
                        }
                      }}
                      className={clsx(
                        'hover:bg-light-blue ml-1 rounded py-1 text-left text-sm',
                        key === selectedVariantId && 'bg-light-blue'
                      )}
                    >
                      {value[key].system_name}
                    </button>
                  </div>
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="border-l border-[#D3D3D3]" />
      {selectedVariant && (
        <LabelVariantEditor
          styles={allStyles}
          disableDelete={
            (allVariants?.filter(variant => variant.label_variant_id).length ??
              0) <= 1 && !!selectedVariant.label_variant_id
          }
          labelId={duplicate ? undefined : labelId}
          labelVariantId={selectedVariant.label_variant_id}
          duplicate={duplicate}
          value={selectedVariant}
          errors={errors?.[selectedVariantId]}
          onUpdate={update(selectedVariantId)}
          onDuplicate={() => {
            const v = value[selectedVariantId];
            onUpdate({
              [newVariantId()]: cleanVariant(
                {
                  ...v,
                  system_name: newVariantName(v.style_id)
                },
                true
              )
            });
          }}
          onDelete={async () => {
            onUpdate({ [selectedVariantId]: undefined });
            setSelectedVariantId(Object.keys(value)[0]);
            if (selectedVariant.label_variant_id) {
              const saving = toast.loading('Deleting...');
              const { error } = await handleTRPCRequest({
                method: TRPCMethodEnum.delete,
                resourceType: TRPCResourceEnum.labelVariants,
                requestBody: {
                  label_variant_id: selectedVariant.label_variant_id
                }
              });
              if (error) {
                toast.update(saving, {
                  render:
                    'There was an error deleting the label variant, please try again later',
                  type: 'error',
                  isLoading: false,
                  autoClose: 3000
                });
              } else {
                toast.update(saving, {
                  render: 'Label variant deleted',
                  type: 'success',
                  isLoading: false,
                  autoClose: 3000
                });
              }
            }
          }}
        />
      )}

      <ModalComponent
        isOpen={variantToSelect !== undefined}
        onRequestClose={() => {
          setVariantToSelect(undefined);
        }}
        contentLabel="Unsaved Changes"
      >
        <h1 className="mb-3 block">Leave without saving?</h1>
        <p className="mb-3">There are unsaved changes in this variant.</p>
        <div className="flex gap-3 pt-5">
          <ContainedButton
            className="flex-1"
            color="secondary"
            onClick={() => {
              setVariantToSelect(undefined);
            }}
            data-testid="unsaved-changes-modal-back-button"
          >
            Back
          </ContainedButton>

          <ContainedButton
            className="flex-1"
            onClick={() => {
              setSelectedVariantId(variantToSelect!);
              setVariantToSelect(undefined);
              setUnsavedChanges(false);
            }}
            data-testid="unsaved-changes-modal-confirm-button"
          >
            Yes - Leave
          </ContainedButton>
        </div>
      </ModalComponent>
    </div>
  );
}
