import { useMemo } from 'react';

import { useGetProductQuery } from '../api/store/apiSlice';
import { useRootSelector } from '../../store';

import {
  selectLabelShape,
  selectProductTag,
  selectSlots
} from '../item/store/itemSlice';

export function useMaterials() {
  const productTag = useRootSelector(selectProductTag);
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductQuery(productTag);

  const selectedSlots = useRootSelector(selectSlots);
  const selectedShape = useRootSelector(selectLabelShape);

  const materials = useMemo(() => {
    const materials = new Map(
      data?.materials.map(material => [material.material_id, material])
    );

    const slotMaterialsByLabel =
      data?.label_slots?.map(slot => {
        const labelId =
          selectedSlots[slot.product_label_slot_id]?.labelId ||
          selectedShape?.label_id;
        return Array.from(
          new Set(
            slot.slot_options
              .filter(option => labelId === option.label_id)
              .flatMap(option => option.material_ids)
          )
        );
      }) ?? [];
    const slotMaterialsWithMultiple = new Set(
      slotMaterialsByLabel.filter(materials => materials.length > 1).flat(1)
    );
    return Array.from(slotMaterialsWithMultiple).map(materialId =>
      materials.get(materialId)
    );
  }, [
    data?.label_slots,
    data?.materials,
    selectedShape?.label_id,
    selectedSlots
  ]);

  return { materials, isLoading, isSuccess, isError, error };
}
