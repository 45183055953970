import { useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import clsx from 'clsx';

import Pager from '../../components/Pager';
import { useConfiguratorDispatch, useRootSelector } from '../../store';

import { closeSeeAll } from '../accordion/accordionSlice';
import {
  selectFont,
  selectProductTag,
  selectStyle,
  setFont
} from '../item/store/itemSlice';
import { useGetProductQuery } from '../api/store/apiSlice';

import { Font } from '.';
import { SearchIcon } from '../../components/icons';
import { TextField } from '../../components/inputs/TextField';
import { Group } from '../../types';
import { useFontsAndFields } from './TextAndFont.hooks';
import SeeAllGrid from '../../components/grids/SeeAllGrid';
import { tooltipClasses } from '../../components/grids/GridElement';

const DEFAULT_FONTS_TO_DISPLAY = 12;

export default function AllFonts() {
  const dispatch = useConfiguratorDispatch();

  const selectedFont = useRootSelector(selectFont);

  const [fontsPage, setFontsPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [totalPages, setTotalPages] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const outerSelectedFont = useMemo(() => selectedFont, []);

  const productTag = useRootSelector(selectProductTag);
  const { data } = useGetProductQuery(productTag);

  const selectedStyle = useRootSelector(selectStyle);
  const { fonts } = useFontsAndFields();

  const groups = useMemo(() => {
    let permittedGroups: Group[] = [];
    const fontIds = new Set(fonts.map(c => c.font_id));
    if (data && selectedStyle) {
      permittedGroups = data?.filters?.filter(f => f.type === 'fonts');
      permittedGroups = permittedGroups.filter(
        group => group.entity_ids.filter(p => fontIds.has(p)).length
      );
    }
    if (permittedGroups?.length) {
      return [
        { display_name: 'All', group_id: 'all' },
        ...permittedGroups
      ] as Group[];
    } else {
      return null;
    }
  }, [selectedStyle, data, fonts]);

  const [selectedGroup, setSelectedGroup] = useState<Group>();

  const [fontSlice, setFontSlice] = useState<Font[]>([]);

  useEffect(() => {
    let groupedFonts;
    if (selectedGroup?.entity_ids) {
      groupedFonts = fonts.filter(c =>
        selectedGroup.entity_ids.includes(c.font_id)
      );
    } else {
      groupedFonts = fonts;
    }
    const filteredFonts = groupedFonts.filter(s =>
      s.display_name.toLowerCase().includes(filter.toLowerCase())
    );

    const newTotalPages = Math.ceil(
      filteredFonts?.length / DEFAULT_FONTS_TO_DISPLAY
    );
    setTotalPages(newTotalPages);
    const newFontsPage =
      Math.min(Math.max(0, newTotalPages - 1), fontsPage) || 0;
    setFontsPage(newFontsPage);

    const start = newFontsPage * DEFAULT_FONTS_TO_DISPLAY;
    const end = start + DEFAULT_FONTS_TO_DISPLAY;
    setFontSlice(filteredFonts?.slice(start, end) ?? []);
  }, [fonts, fontsPage, filter, selectedGroup]);

  return (
    <>
      <div className="my-2.5 flex flex-col gap-2.5 overflow-y-auto">
        <div className="px-2.5 py-1 text-center text-sm font-semibold">
          Select a Font
        </div>
        <ul className="flex flex-wrap gap-1.5">
          {groups?.map(group => (
            <li
              key={group.group_id}
              onClick={() => setSelectedGroup(group)}
              className={clsx(
                'border-dark-gray rounded border border-solid px-2 py-1.5',
                {
                  'bg-navy text-white': selectedGroup == group
                }
              )}
            >
              {group.display_name}
            </li>
          ))}
        </ul>
        <TextField
          size="large"
          className="flex-1"
          placeholder="Search"
          contentAfter={<SearchIcon />}
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />

        <SeeAllGrid>
          {fontSlice.map(font => (
            <li
              key={font.font_id}
              className={clsx(
                'box-border h-full items-stretch rounded-md border-2 border-solid p-px',
                font == selectedFont ? 'border-navy' : ' border-transparent'
              )}
            >
              <Tooltip
                id={`font-tooltip-${font.font_id}`}
                className={tooltipClasses}
              />
              <div
                className={clsx(
                  'align-center box-border flex h-full flex-col gap-0.5 overflow-hidden rounded-md border border-solid border-gray-400 p-2',
                  { 'bg-gray-200': font == selectedFont }
                )}
                onClick={() => dispatch(setFont(font))}
                data-tooltip-id={`font-tooltip-${font.font_id}`}
                data-tooltip-content={font.display_name}
                data-tooltip-place="top"
              >
                <div
                  className="max-w-full truncate text-base font-bold"
                  style={{
                    fontFamily: font.google_family + font.font_id
                  }}
                >
                  Your Name
                </div>
                <span className="max-w-full shrink truncate text-xs text-black">
                  {font.display_name}
                </span>
              </div>
            </li>
          ))}
        </SeeAllGrid>

        <Pager
          size="large"
          totalPages={totalPages}
          currentPage={fontsPage}
          onBack={() => setFontsPage(old => Math.max(0, old - 1))}
          onForward={() =>
            setFontsPage(old => Math.min(old + 1, totalPages - 1))
          }
        />
        <div className="flex justify-stretch gap-2">
          <button
            className="border-primary text-primary border-1 grow rounded-full border-solid px-2.5 py-4 font-semibold"
            onClick={() => {
              dispatch(setFont(outerSelectedFont));
              dispatch(closeSeeAll());
            }}
          >
            Back
          </button>
          <button
            onClick={() => dispatch(closeSeeAll())}
            className="bg-primary border-1 grow rounded-full border-solid px-2.5 py-4 font-semibold text-white"
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
}
