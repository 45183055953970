import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRolesEnum } from '../../../../core/src/features/fonts/types';
import { UnderConstruction } from './UnderConstruction';

const roleToPathMap = {
  [UserRolesEnum.super_admin]: '/products',
  [UserRolesEnum.admin]: '/products',
  [UserRolesEnum.asset_manager]: '/products',
  [UserRolesEnum.customer_service]: '/reports',
  [UserRolesEnum.order_processor]: '/reports'
};

export function Redirect({ role }: { role: string }) {
  const navigate = useNavigate();

  useEffect(() => {
    const path = roleToPathMap[role as UserRolesEnum] || '/users';
    navigate(path);
  }, [role, navigate]);

  return <UnderConstruction />;
}
