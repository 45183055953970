import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';
import { ChevronUpButton } from '../../../components/buttons';
import { ProductPreviewComponent } from '.';

export function MobilePreviewWrapper() {
  const [isOnScreen, setIsOnScreen] = useState(true);
  const [top, setTop] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.getElementById('customer-ui-root')?.scrollIntoView();
    const element = wrapperRef.current;
    if (element) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsOnScreen(entry.isIntersecting);
          const otherHeader = document.getElementById('header__stick');
          if (otherHeader) {
            setTop(otherHeader.clientHeight);
          }
        },
        { threshold: 0.5 }
      );

      observer.observe(element);

      return () => observer.unobserve(element);
    }
  }, []);

  const scrollToOriginalDiv = () => {
    const element =
      document.getElementById('configurator-header') || wrapperRef.current;
    if (!element) {
      return;
    }

    const scrollTop = element.getBoundingClientRect().top;
    document.body.scrollBy({
      top: scrollTop - top,
      left: 0,
      behavior: 'smooth'
    });
  };

  const stickyHeaderClasses = [
    'fixed',
    'h-16',
    'p-1',
    'w-full',
    'bg-white',
    // review loading overlay uses a z-index of 100
    'z-[150]',
    'flex',
    'justify-between',
    'items-center',
    'shadow-md',
    'transition-transform',
    isOnScreen && '-translate-y-full'
  ];

  const childrenWrapperClasses = ['h-full', 'w-[100px]'];

  const wrapperClasses = [
    'flex',
    'justify-center',
    'items-start',
    'transition-all',
    'w-full',
    'p-4' // keep drop shadow from being clipped
  ];

  return (
    <>
      <div className={clsx(stickyHeaderClasses)} style={{ top }}>
        <div className={clsx(childrenWrapperClasses)}>
          <ProductPreviewComponent mobile={true} />
          <div className="grow" />
        </div>
        <ChevronUpButton
          className="text-navy mr-5"
          onClick={scrollToOriginalDiv}
        />
      </div>
      <div ref={wrapperRef} className={clsx(wrapperClasses)}>
        <ProductPreviewComponent />
      </div>
    </>
  );
}
