import { TextField } from '../../components/inputs/TextField';
import { useConfiguratorDispatch, useRootSelector } from '../../store';
import { setTextField } from '../accordion/accordionSlice';
import { VariantTextField } from '../../types';
import { syncFields } from '../item/store/itemSlice';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { removeHiddenChars } from '../../utils';

interface TextInputsProps {
  admin?: boolean;
  fields: VariantTextField[];
  errors?: Record<string, string>;
}
export default function TextInputs({
  admin = false,
  fields,
  errors
}: TextInputsProps) {
  const toastId = useRef<string | number | null>(null);

  const handleNewLines = (newTextLines: string[], maxCharacters: number) => {
    // If admin is true, character limit doesn't apply
    if (admin) return { canUpdate: true };

    const newLength = getFullLength(newTextLines);

    if (newLength > maxCharacters) {
      const errorMessage =
        'Character limit reached. Reach out to our customer support team if you’d like to provide a longer name.';

      if (!toastId.current) {
        toastId.current = toast.error(errorMessage, {
          position: 'bottom-right',
          onClose: () => {
            toastId.current = null;
          }
        });
      } else {
        toast.update(toastId.current, {
          render: errorMessage,
          type: 'error',
          position: 'bottom-right'
        });
      }

      return { canUpdate: false };
    }

    return { canUpdate: true };
  };

  return (
    <>
      {fields?.length ? (
        <div className="grid grid-cols-2 gap-2.5">
          {fields.map(f => (
            <TextFieldInput
              admin={admin}
              key={f.text_field_id}
              textField={f}
              error={errors?.[f.text_field_id]}
              onNewLinesChange={handleNewLines}
            />
          ))}
        </div>
      ) : null}
    </>
  );
}

interface TextFieldInputProps {
  admin?: boolean;
  textField: VariantTextField;
  error?: string;
  onNewLinesChange: (
    textLines: string[],
    maxCharacters: number
  ) => { canUpdate: boolean };
}

const getFullLength = (textLines: string[]) => {
  const firstLineLength = (textLines && textLines[0]?.length) || 0;
  const secondLineLength = (textLines && textLines[1]?.length) || 0;
  return firstLineLength + secondLineLength;
};

function TextFieldInput({
  admin = false,
  textField,
  error,
  onNewLinesChange
}: TextFieldInputProps) {
  const dispatch = useConfiguratorDispatch();
  const [charactersRemaining, setCharactersRemaining] = useState<number>(
    textField.max_characters
  );

  const textLines = useRootSelector(root => {
    const text = root.accordion.fields[textField.text_field_id];
    return text ? text.split('\n') : [];
  });

  const firstLine = textLines && textLines[0];
  const secondLine = textLines && textLines[1];

  useEffect(() => {
    // If admin is true, we don't want to show the character count
    if (admin) return;

    const currentLength = getFullLength([firstLine, secondLine]);
    setCharactersRemaining(textField.max_characters - currentLength);
  }, [admin, firstLine, secondLine, textField.max_characters]);

  const handleChange = (fieldId: string, text: string, lineIndex: number) => {
    const currentTextLines = textLines ?? [];
    const newTextLines = [...currentTextLines];
    newTextLines[lineIndex] = removeHiddenChars(text);

    const { canUpdate } = onNewLinesChange(
      newTextLines,
      textField.max_characters
    );

    if (!canUpdate) {
      return;
    }

    const notEmpty = newTextLines.find(line => line && line !== '');

    let updatedText = '';
    if (notEmpty) {
      if (newTextLines[1]?.trim() === '') {
        updatedText = newTextLines[0];
      } else {
        updatedText = newTextLines.join('\n');
      }
    }

    dispatch(setTextField({ fieldId, text: updatedText }));
    dispatch(syncFields());
  };

  return (
    <>
      <TextField
        size="small"
        error={!!error}
        value={firstLine ?? ''}
        placeholder={textField.display_name}
        required={textField.is_required}
        bold={textField.is_required}
        onChange={e =>
          handleChange(textField.text_field_id, e.target.value as string, 0)
        }
        contentAfter={
          charactersRemaining < 6 ? (
            <div className="text-red">{charactersRemaining}</div>
          ) : (
            <div />
          )
        }
      />
      {textField.display_name_2 && textField.display_name_2.length && (
        <TextField
          size="small"
          error={!!error}
          value={secondLine ?? ''}
          placeholder={textField.display_name_2}
          required={false}
          bold={textField.is_required}
          onChange={e =>
            handleChange(textField.text_field_id, e.target.value as string, 1)
          }
          contentAfter={
            charactersRemaining < 6 ? (
              <div className="text-red">{charactersRemaining}</div>
            ) : (
              <div />
            )
          }
        />
      )}
    </>
  );
}
