import { SHOPIFY_PRODUCT_VARIANTS } from '../config';
import { Label, ShopifyProductVariant, Style } from '../types';

export function findShapeVariant(
  labelShape: Label | null,
  style: Style | null
) {
  let shapeVariant: ShopifyProductVariant | undefined;

  // look for a matching variant based on the label display name
  const shapeName = labelShape?.display_name.toLowerCase() || '';
  shapeVariant = SHOPIFY_PRODUCT_VARIANTS.find(
    variant => variant.shape?.toLowerCase() === shapeName
  );

  if (shapeVariant) return shapeVariant;

  // collect all label variants of the selected label that match the style id
  // this is the list of variants that the customer could have selected. Since
  // the variant does not change the SKU, it is not necessary to know which
  // variant the customer selected
  const labelVariants =
    labelShape?.variants?.filter(labelVariant => {
      return labelVariant.style_id === style?.style_id;
    }) || [];

  // look for a product variant who's shape matches the start of one of the
  // valid label variant system names
  for (const labelVariant of labelVariants) {
    const labelVariantName = labelVariant.system_name.toLowerCase();

    shapeVariant = SHOPIFY_PRODUCT_VARIANTS.find(productVariant => {
      const productVariantShape = productVariant.shape?.toLowerCase();
      return (
        productVariantShape && labelVariantName.startsWith(productVariantShape)
      );
    });

    if (shapeVariant) return shapeVariant;
  }

  // look for a custom Shopify variant
  shapeVariant = SHOPIFY_PRODUCT_VARIANTS.find(
    variant => variant.shape?.toLowerCase() === 'custom'
  );

  if (shapeVariant) return shapeVariant;

  // look for a circle Shopify variant
  shapeVariant = SHOPIFY_PRODUCT_VARIANTS.find(
    variant => variant.shape?.toLowerCase() === 'circle'
  );

  return shapeVariant;
}
