import { UserButton } from '@clerk/clerk-react';

import { Logo } from './Logo';
import { Navbar } from './Navbar';

interface Props {
  menuItems?: {
    href?: string;
    label: string;
    right?: boolean;
    submenu?: { href: string; label: string }[];
  }[];
}

export default function Header({ menuItems }: Props) {
  return (
    <header className="z-[150] flex h-[60px] items-center border-b border-[#CBCBCB] px-[40px] py-0">
      <Logo />
      <Navbar menuItems={menuItems} />
      <UserButton />
    </header>
  );
}
