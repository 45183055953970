import { SHOPIFY_PRODUCT_VARIANTS } from '../config';
import { ShopifyProductVariant, Palette } from '../types';

export function findColorVariant(palette: Palette | null) {
  let colorVariant: ShopifyProductVariant | undefined;
  const colorName = palette?.system_name.toLowerCase();

  if (colorName) {
    colorVariant = SHOPIFY_PRODUCT_VARIANTS.find(
      variant => variant.color?.toLowerCase() === colorName
    );
  }
  return colorVariant;
}
