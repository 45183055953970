import { Outlet } from 'react-router-dom';

import Header from '../components/Header';

interface Props {
  mainMenuItems?: {
    href?: string;
    label: string;
    children?: { href: string; label: string }[];
  }[];
}

export default function Root({ mainMenuItems }: Props) {
  return (
    <>
      <Header menuItems={mainMenuItems} />
      <div className="grow overflow-scroll px-[60px] py-[40px]">
        <Outlet />
      </div>
    </>
  );
}
