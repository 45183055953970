import { MenuItems } from './MenuItems';

interface Props {
  menuItems?: {
    href?: string;
    label: string;
    right?: boolean;
    submenu?: { href: string; label: string }[];
  }[];
}

export function Navbar({ menuItems }: Props) {
  const leftMenuItems = menuItems?.filter(item => !item.right);
  const rightMenuItems = menuItems?.filter(item => item.right);
  return (
    <nav className="flex h-full w-full items-center pr-[10px]">
      <ul className="z-50 flex h-full list-none flex-wrap items-center">
        {leftMenuItems?.map((menu, index) => (
          <MenuItems items={menu} key={index} />
        ))}
      </ul>
      <ul className="ml-auto flex h-full list-none flex-wrap items-center">
        {rightMenuItems?.map((menu, index) => (
          <MenuItems items={menu} key={index} />
        ))}
      </ul>
    </nav>
  );
}
