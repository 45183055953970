import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import React from 'react';
import { DeleteIcon } from '../../../components/icons';

interface BoxWithDotsProps {
  children: ReactNode;
  style: CSSProperties;
  isOption: boolean;
  onMouseUp?: () => void;
  onRemoveLabelFromPreview?: () => void;
}
export const BoxWithDots = React.forwardRef<HTMLDivElement, BoxWithDotsProps>(
  (props, ref) => {
    const { children, style, isOption, onMouseUp, onRemoveLabelFromPreview } =
      props;

    return (
      <div
        ref={ref}
        className={
          'border-navy shadow-navy relative z-50 flex flex-col justify-between border border-solid bg-white p-2 pt-4 shadow-lg'
        }
        style={style}
        onMouseUp={onMouseUp}
      >
        {!isOption && (
          <div className="absolute right-1 top-1 flex w-full justify-end gap-2">
            <div
              className="text-blush cursor-pointer"
              onClick={onRemoveLabelFromPreview}
            >
              <DeleteIcon />
            </div>
          </div>
        )}

        {children}

        {/* Top left dot */}
        <div
          className={clsx(
            'bg-navy absolute h-2 w-2 rounded-lg',
            'left-[-5px] top-[-5px]'
          )}
        />

        {/* Top right dot */}
        <div
          className={clsx(
            'bg-navy absolute h-2 w-2 rounded-lg',
            'right-[-5px] top-[-5px]'
          )}
        />

        {/* Bottom left dot */}
        <div
          className={clsx(
            'bg-navy absolute h-2 w-2 rounded-lg',
            'bottom-[-5px] left-[-5px]'
          )}
        />

        {/* Bottom right dot */}
        <div
          className={clsx(
            'bg-navy absolute h-2 w-2 rounded-lg',
            'bottom-[-5px] right-[-5px]'
          )}
        />
      </div>
    );
  }
);
